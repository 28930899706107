import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';

export const getAppointment15MinuteTimeSlots = (startHour: number, endHour: number) => {
  const hours = [...Array(24).keys()];
  const workingHours = hours.filter((hour) => hour >= startHour && hour <= endHour);

  const timeSlots = workingHours.flatMap((hour, index) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    let hours = [`${h}:00`, `${h}:15`, `${h}:30`, `${h}:45`];
    if (index === workingHours.length - 1) {
      hours = [`${h}:00`, `${h}:15`, `${h}:30`];
    }
    return hours;
  });
  return timeSlots;
};

export const getAppointment60MinuteTimeSlots = (startHour: number, endHour: number) => {
  const hours = [...Array(24).keys()];
  const workingHours = hours.filter((hour) => hour >= startHour && hour <= endHour);

  const timeSlots = workingHours.map((hour) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    return `${h}:00`;
  });
  return timeSlots;
};

const getHourAndMinuteFromTimeString = (timeString: string) => {
  const [hour, minute] = timeString.split(':');
  return { hour: parseInt(hour), minute: parseInt(minute) };
};

export const getTimestampFromDateAndTimeString = (date: Dayjs, timeString: string) => {
  const { hour, minute } = getHourAndMinuteFromTimeString(timeString);
  const clonedDate = _.cloneDeep(date);
  return Timestamp.fromDate(clonedDate.hour(hour).minute(minute).second(0).toDate());
};

export const getDayJsFromDateAndTimeString = (date: Dayjs, timeString: string) => {
  const { hour, minute } = getHourAndMinuteFromTimeString(timeString);
  const clonedDate = _.cloneDeep(date);
  return clonedDate.hour(hour).minute(minute).second(0);
};

export const getTimeStringFromTimestamp = (timestamp: Timestamp) => {
  return dayjs(timestamp.toDate()).format('HH:mm');
};

export const getAppointmentStyle = (
  startHour: number,
  endHour: number,
  start: string,
  end: string,
  cancelled: boolean,
  widthPercent: number,
  leftPercent: number,
  timeSlotHeight: number,
  backgroundColour: string
) => {
  const timeSlots = getAppointment15MinuteTimeSlots(startHour, endHour);
  const startIndex = timeSlots.findIndex((slot) => start === slot);
  const endIndex = timeSlots.findIndex((slot) => end === slot);
  const fifteenMinuteSlotHeight = timeSlotHeight / 4;
  const slotHeight = endIndex - startIndex;
  const style = {
    top: `${startIndex * fifteenMinuteSlotHeight}px`,
    height: `${slotHeight * fifteenMinuteSlotHeight}px`,
    width: `${widthPercent * 100}%`,
    left: `${leftPercent * 100}%`,
    background: cancelled
      ? 'repeating-linear-gradient(45deg, #dc2626, #dc2626 10px, #b91c1c 10px, #b91c1c 20px)'
      : backgroundColour,
  };

  return style;
};
