import clsx from 'clsx';
import { IActionTimestamp } from 'core/api/types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
interface ICreatedBy {
  created: IActionTimestamp;
  outerClassName?: string;
}

const CreatedBy = ({ created, outerClassName }: ICreatedBy) => {
  const { t } = useTranslation();
  const outer = clsx(outerClassName, 'body-xs text-gray-400');
  return (
    <p className={outer}>
      {t('common.created_by', {
        at: dayjs(created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
        by: created.by.fullName,
      })}
    </p>
  );
};

export default CreatedBy;
