import { useTranslation } from 'react-i18next';
import { ISharedTableCustomTemplate, ISharedTableHeader } from './table.interface';

const SharedTableHeader = ({ columns, headerTemplates, backgroundColor = 'white' }: ISharedTableHeader) => {
  const { t } = useTranslation();
  const defaultTemplate = (label: string) => <p className='whitespace-pre-wrap p-3 label-sm text-left'>{label}</p>;

  const templates: ISharedTableCustomTemplate[] = [
    {
      template: defaultTemplate,
      id: 'default',
    },
    ...headerTemplates,
  ];

  const findTemplate = (label: string, templateId: string) => {
    const headerTemplate = templates.find((template) => template.id === templateId);
    return headerTemplate?.template(label);
  };

  return (
    <thead style={{ backgroundColor }}>
      <tr>
        {columns.map(({ width, key, labelKey, headerTemplateId = 'default' }) => (
          <th key={key} style={width ? { width } : {}}>
            {findTemplate(labelKey ? t(labelKey) : '', headerTemplateId)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default SharedTableHeader;
