import clsx from 'clsx';
import { INavbarOption } from 'core/config/navbar-options';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

interface ISharedSidebarItem extends INavbarOption {
  onClick: () => void;
}

const SharedSidebarItem = ({ NavIcon, labelKey, route, onClick }: ISharedSidebarItem) => {
  const location = useLocation();
  const active = location.pathname.startsWith(`/${route}`);
  const { t } = useTranslation();

  return (
    <Link
      to={route}
      onClick={() => {
        onClick();
      }}
    >
      <div
        className={clsx(
          'button-sm rounded-md text-white px-3 py-2 transition ease-in-out flex items-center space-x-3',
          active ? 'bg-black/10' : 'hover:bg-white/20'
        )}
      >
        {NavIcon && <NavIcon className='max-h-[20px]' />}
        <p>{t(labelKey)}</p>
      </div>
    </Link>
  );
};

export default SharedSidebarItem;
