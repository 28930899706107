import {
  AggregateSpec,
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  Query,
  QueryCompositeFilterConstraint,
  QueryConstraint,
  QueryDocumentSnapshot,
  QueryOrderByConstraint,
  QuerySnapshot,
  WithFieldValue,
  collection,
  deleteDoc,
  doc,
  getAggregateFromServer,
  getCountFromServer,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { firestore } from 'core/config/firebase';
import { CollectionID } from 'core/constants/collection-id';
import { IUpdateDataPayload } from './types';

const createService = <T>(collectionId: CollectionID) => {
  const collectionRef = collection(firestore, collectionId);

  const docRef = (uid: string) => doc(firestore, collectionId, uid).withConverter(converter<T>());

  const converter = <T>() => ({
    toFirestore: (dao: WithFieldValue<T>) => dao,
    fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => snapshot.data() as T,
  });

  const get = (uid: string) => {
    const docRef = doc(firestore, collectionId, uid).withConverter(converter<T>());
    return getDoc(docRef);
  };

  const getAll = (constraints: QueryConstraint[] = []) => {
    const q = query(collectionRef, ...constraints);
    return getDocs(q.withConverter(converter<T>()));
  };

  const set = (dao: T & { uid: string }) => {
    return setDoc(doc(firestore, collectionId, dao.uid).withConverter(converter<T>()), dao);
  };

  const update = async (uid: string, update: IUpdateDataPayload<T>) => {
    const docRef = doc(firestore, collectionId, uid).withConverter(converter<T>());
    return updateDoc(docRef, update);
  };

  const onDocSnapshot = (
    uid: string,
    resultHandler: (snap: DocumentSnapshot<T, DocumentData>) => void,
    errorHandler: (error: FirestoreError) => void
  ) => {
    return onSnapshot(
      docRef(uid),
      (snapShot: DocumentSnapshot<T>) => {
        resultHandler(snapShot);
      },
      (error: FirestoreError) => {
        errorHandler(error);
      }
    );
  };

  const onCollectionSnapshot = (
    resultHandler: (snap: QuerySnapshot<T, DocumentData>) => void,
    errorHandler: (error: FirestoreError) => void,
    constraints?: QueryConstraint[],
    compositeFilter?: {
      filter: QueryCompositeFilterConstraint;
      orderBy: QueryOrderByConstraint;
    }
  ) => {
    let q: Query<T, DocumentData> = query(collectionRef).withConverter(converter<T>());

    if (constraints && constraints.length > 0) {
      q = query(collectionRef, ...constraints).withConverter(converter<T>());
    } else if (compositeFilter) {
      q = query(collectionRef, compositeFilter.filter, compositeFilter.orderBy).withConverter(converter<T>());
    }

    return onSnapshot(
      q,
      (snapShot) => {
        resultHandler(snapShot);
      },
      (error) => {
        errorHandler(error);
      }
    );
  };

  const getCollectionCount = (constraints: QueryConstraint[] = []) => {
    const q = query(collectionRef, ...constraints).withConverter(converter<T>());
    return getCountFromServer(q);
  };

  const getCollectionAggregate = (constraints: QueryConstraint[] = [], aggregation: AggregateSpec) => {
    const q = query(collectionRef, ...constraints).withConverter(converter<T>());
    return getAggregateFromServer(q, aggregation);
  };

  const permDelete = (uid: string) => {
    return deleteDoc(docRef(uid));
  };

  return {
    get,
    getAll,
    set,
    update,
    onDocSnapshot,
    onCollectionSnapshot,
    converter,
    collectionRef,
    docRef,
    permDelete,
    getCollectionCount,
    getCollectionAggregate,
  };
};

export default createService;
