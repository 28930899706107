import { IAppointmentTypeDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditAppointmentTypeCommunicationDialog from './add-edit-appointment-type-communication-dialog';
import { ClockCircleTwoTone } from '@ant-design/icons';
import AppointmentTypeCommunicationsDetails from './appointment-type-communication-details';
import { AppointmentTypesApiService } from 'core/api';
import { arrayRemove } from 'firebase/firestore';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';

const AppointmentTypeReminders = (appointmentType: IAppointmentTypeDao) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { userData } = useUserState();

  return (
    <SharedCard
      title={t('appointment_types.appointment_type_detail.reminders')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='appointment_types.appointment_type_detail.reminders.add_reminder'
            onClick={() =>
              dialog?.openDialog(
                <AddEditAppointmentTypeCommunicationDialog type='reminder' appointmentType={appointmentType} />
              )
            }
          />
        </SharedElementPermissionGuard>
      }
    >
      {appointmentType.reminders.map((reminder) => {
        const { key, ...rest } = reminder;
        return (
          <div key={key} className='px-4 py-3 border-b last:border-0 flex items-center justify-between'>
            <div className='flex space-x-4'>
              <div className='flex space-x-1'>
                <ClockCircleTwoTone />
                <p>
                  {rest.hoursBefore}
                  {t('appointment_types.appointment_type_detail.reminder.hrs')}
                </p>
              </div>
              <AppointmentTypeCommunicationsDetails {...rest} />
            </div>
            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
            >
              <div className='flex space-x-4'>
                <SharedButton
                  appearance='link'
                  danger
                  labelKey='common.delete'
                  onClick={() =>
                    dialog?.openDialog(
                      <ConfirmActionDialog
                        action={() =>
                          AppointmentTypesApiService.update(appointmentType.uid, {
                            updated: getActionTimestampFromUser(userData),
                            reminders: arrayRemove(reminder),
                          })
                        }
                        actionButtonProps={{
                          labelKey: 'common.delete',
                          danger: true,
                        }}
                        title={t('appointment_types.appointment_type_detail.reminders.delete_reminder.title')}
                        textContent={t('appointment_types.appointment_type_detail.reminders.delete_reminder.content')}
                        successMessage={t(
                          'appointment_types.appointment_type_detail.reminders.delete_reminder.success'
                        )}
                        errorMessage={t('appointment_types.appointment_type_detail.reminders.delete_reminder.error')}
                      />
                    )
                  }
                />
                <SharedButton
                  appearance='link'
                  labelKey='common.edit'
                  primaryOverride
                  onClick={() =>
                    dialog?.openDialog(
                      <AddEditAppointmentTypeCommunicationDialog
                        reminder={reminder}
                        appointmentType={appointmentType}
                        type='reminder'
                      />
                    )
                  }
                />
              </div>
            </SharedElementPermissionGuard>
          </div>
        );
      })}
      {appointmentType?.reminders.length === 0 && (
        <p className='text-gray-400 p-4'>{t('appointment_types.appointment_type_detail.reminders.no_reminders')}</p>
      )}
    </SharedCard>
  );
};

export default AppointmentTypeReminders;
