import { Navigate, Route, Routes } from 'react-router-dom';
import HearingAidsList from './hearing-aids/hearing-aids-list';
import ProductsAndServicesMenu from './products-and-services-menu';
import AccessoriesList from './accessories/accessories-list';
import ServicesList from './services/services-list';

const ProductsAndServicesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<ProductsAndServicesMenu />} />
      <Route path='hearing-aids' element={<HearingAidsList />} />
      <Route path='accessories' element={<AccessoriesList />} />
      <Route path='services' element={<ServicesList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default ProductsAndServicesRouting;
