import { Navigate, Route, Routes } from 'react-router-dom';
import FormSettingsList from './form-settings-list';
import EditPatientFormSettings from './edit-patient-form-settings';
import EditOrderFormSettings from './edit-order-form-settings';

const FormSettingsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<FormSettingsList />} />
      <Route path='patient-form' element={<EditPatientFormSettings />} />
      <Route path='order-form' element={<EditOrderFormSettings />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default FormSettingsRouting;
