import { Tag } from 'antd';
import { OrderApiService } from 'core/api';
import { IOrderDao } from 'core/api/types/order.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import InfoRow from 'shared/data-display/info-row';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IOrderItem {
  key: string;
  label: string;
  price: number;
}

const PatientOrder = (order: IOrderDao) => {
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organisationData, userData } = useUserState();
  const { grossPrice, discount, vat } = order;
  const vatEnabled = vat?.enabled ?? false;
  const totalToPay = grossPrice + (vat?.amount ?? 0) - (discount ?? 0);
  const dialog = useDialog();

  const buildAidString = (values: (string | undefined)[]) => values.filter((value) => Boolean(value)).join(' / ');

  useEffect(() => {
    const items: IOrderItem[] = [
      ...order.accessoriesAndServices.map((item) => ({
        key: item.uid,
        label: item.type === 'accessory' ? `${item.manufacturer} ${item.name}` : item.name,
        price: item.price,
      })),
      ...Object.values(order.hearingAids).map((item, index) => {
        const { manufacturer, model, style, receiver, gain, earbudSize, earbudType, power, colour, price } = item;
        return {
          key: `hearingAid-${index}`,
          label: buildAidString([manufacturer, model, style, receiver, gain, earbudSize, earbudType, power, colour]),
          price: price,
        };
      }),
    ];

    setOrderItems(items);
  }, [order]);

  const pricingData = [
    {
      key: 'grossPrice',
      label: t(
        vatEnabled ? 'orders.add_edit_order.costing.gross_price_excl_vat' : 'orders.add_edit_order.costing.gross_price'
      ),
      value: formatToCurrency(grossPrice, organisationData?.finance.currency ?? 'GBP'),
    },
    ...(vatEnabled
      ? [
          {
            key: 'vat',
            label: t('orders.add_edit_order.costing.vat'),
            value: formatToCurrency(vat?.amount ?? 0, organisationData?.finance.currency ?? 'GBP'),
          },
        ]
      : []),
    ...(discount !== undefined
      ? [
          {
            key: 'discount',
            label: t('patients.patient.orders.discount'),
            value: formatToCurrency(discount, organisationData?.finance.currency ?? 'GBP'),
          },
        ]
      : []),
    {
      key: 'total',
      label: t(
        vatEnabled ? 'orders.add_edit_order.costing.final_price_incl_vat' : 'orders.add_edit_order.costing.final_price'
      ),
      value: formatToCurrency(totalToPay, organisationData?.finance.currency ?? 'GBP'),
    },
  ];

  return (
    <div className='border-t first:border-0 p-4'>
      <div className='flex items-center justify-between'>
        <div className='flex space-x-4 items-center'>
          <p className='text-sm text-gray-600'>#{order.orderNumber ?? order.uid}</p>
          {order.cancelled && (
            <Tag className='no-underline' color='error'>
              {t('common.cancelled')}
            </Tag>
          )}
        </div>

        <p className='text-sm text-right text-gray-400'>
          {t('common.created_by', {
            at: dayjs(order.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
            by: order.created.by.fullName,
          })}
        </p>
      </div>
      <div className='my-4 pb-4 border-b border-slate-100'>
        <p className='header-sm mb-2'>{t('form_settings.order_form.goods_services')}</p>
        <div>
          {orderItems.map((item) => (
            <div
              key={item.key}
              className='flex justify-between items-center text-gray-700 body-sm mb-2 last:mb-0 space-x-6'
            >
              <p>{item.label}</p>
              <p>{formatToCurrency(item.price, organisationData?.finance.currency ?? 'GBP')}</p>
            </div>
          ))}
        </div>
      </div>
      <div>
        {pricingData.map((data) => (
          <InfoRow key={data.key} label={data.label} value={data.value} />
        ))}
      </div>

      {!order.cancelled && (
        <div className='border-t mt-4 pt-4 flex space-x-2 justify-end border-slate-100'>
          <SharedButton labelKey='common.edit' onClick={() => navigate(`/orders/edit?order=${order.uid}`)} />
          <SharedButton
            labelKey='patients.patient.orders.cancel_order'
            danger
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() =>
                    OrderApiService.update(order.uid, {
                      cancelled: true,
                      updated: getActionTimestampFromUser(userData),
                    })
                  }
                  actionButtonProps={{
                    labelKey: 'patients.patient.orders.cancel_order',
                    danger: true,
                  }}
                  title={t('patients.patient.orders.cancel_order.title')}
                  textContent={t('patients.patient.orders.cancel_order.content')}
                  successMessage={t('patients.patient.orders.cancel_order.success')}
                  errorMessage={t('patients.patient.orders.cancel_order.error')}
                />
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default PatientOrder;
