import { FormInstance } from 'antd';
import { YesNo, YesNoData, YesNoOptions } from 'core/constants/yes-no';
import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
interface IHearingLossCausesForm {
  form: FormInstance;
}

export interface IHearingLossCausesFormOutput {
  noise?: YesNo;
  unilateral?: YesNo;
  asymmetrical?: YesNo;
  suddenOnset?: YesNo;
  suddenWorsening?: YesNo;
  fluctuating?: YesNo;
  vertigo?: YesNo;
  premature?: YesNo;
}

const HearingLossCausesForm = ({ form }: IHearingLossCausesForm) => {
  const { t } = useTranslation();

  const yesNoFieldOptions = YesNoOptions.map((option) => {
    const data = YesNoData[option];
    return {
      label: t(data.translationLabelKey),
      value: data.value,
    };
  });

  const fields: ISharedField[] = [
    {
      fieldKey: 'noise',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.noise'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'unilateral',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.unilateral'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'asymmetrical',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.asymmetrical'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'suddenOnset',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_onset'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'suddenWorsening',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_worsening'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'fluctuating',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.fluctuating'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'vertigo',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.vertigo'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'premature',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.premature'),
      options: yesNoFieldOptions,
      required: false,
    },
  ];

  return (
    <SharedForm
      formInstance={form}
      name='hearing_test.add_edit_hearing_test.hearing_loss_causes'
      fields={fields}
      buttonsOverride={[]}
    />
  );
};

export default HearingLossCausesForm;
