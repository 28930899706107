import { Navigate, Route, Routes } from 'react-router-dom';
import OrganisationSettingLayout from './organisation-settings-layout/organisation-settings-layout';
import PermissionGuard from 'core/routing/permission-guard';
import { Permission } from 'core/constants/permission';
import GroupsRouting from 'modules/organisation-settings/groups/groups-routing';
import { OrganisationSettingsNavbarOptions } from 'modules/organisation-settings/organisation-settings-navbar-options';
import ClinicsRouting from './clinics/clinics-routing';
import AppointmentTypesRouting from './appointment-types/appointment-types-routing';
import LeadTypesRouting from './lead-types/lead-types-routing';
import FormSettingsRouting from './form-settings/form-settings-routing';
import ProductsAndServicesRouting from './products-and-services/products-and-services-routing';
import UsersRouting from './users/users-routing';
import AudiometersRouting from './audiometers/audiometers-routing';
import AuditLogRouting from './audit-log/audit-log-routing';
import WorkflowRouting from './workflow/workflow-routing';
import FinanceRouting from './finance/finance-routing';
import DocumentsImagesRouting from './documents-images/documents-images-routing';
import CalendarRouting from './calendar/calendar-routing';

const OrganisationSettingsRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.USERS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'users/*',
      element: <UsersRouting />,
    },
    {
      requiredPermissions: [[Permission.GROUPS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'groups/*',
      element: <GroupsRouting />,
    },
    {
      requiredPermissions: [[Permission.CLINICS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'clinics/*',
      element: <ClinicsRouting />,
    },
    {
      requiredPermissions: [[Permission.APPOINTMENT_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'appointment-types/*',
      element: <AppointmentTypesRouting />,
    },
    {
      requiredPermissions: [[Permission.LEAD_TYPES_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'lead-types/*',
      element: <LeadTypesRouting />,
    },
    {
      requiredPermissions: [[Permission.FORM_SETTINGS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'form-settings/*',
      element: <FormSettingsRouting />,
    },
    {
      requiredPermissions: [[Permission.FINANCE_UPDATE], [Permission.ORGANISATION_OWNER]],
      path: 'finance/*',
      element: <FinanceRouting />,
    },
    {
      requiredPermissions: [[Permission.ORGANISATION_OWNER]],
      path: 'calendar/*',
      element: <CalendarRouting />,
    },
    {
      requiredPermissions: [[Permission.ORGANISATION_OWNER]],
      path: 'documents-and-images/*',
      element: <DocumentsImagesRouting />,
    },
    {
      requiredPermissions: [[Permission.PRODUCTS_AND_SERVICES_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'products-and-services/*',
      element: <ProductsAndServicesRouting />,
    },
    {
      requiredPermissions: [[Permission.AUDIOMETERS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'audiometers/*',
      element: <AudiometersRouting />,
    },
    {
      requiredPermissions: [[Permission.AUDIT_LOG_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'audit-log/*',
      element: <AuditLogRouting />,
    },
    {
      requiredPermissions: [[Permission.WORKFLOW_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'workflow/*',
      element: <WorkflowRouting />,
    },
  ];

  return (
    <Routes>
      <Route element={<OrganisationSettingLayout />}>
        {routes.map(({ requiredPermissions, path, element }) => (
          <Route
            key={path}
            element={
              <PermissionGuard requiredPermissions={requiredPermissions} navList={OrganisationSettingsNavbarOptions} />
            }
          >
            <Route path={path} element={element} />
          </Route>
        ))}
      </Route>
      <Route path='' element={<Navigate replace to='users' />} />
      <Route path='*' element={<Navigate replace to='users' />} />
    </Routes>
  );
};

export default OrganisationSettingsRouting;
