import { App, Form } from 'antd';
import { AppointmentTypesApiService } from 'core/api';
import {
  IAppointmentTypeCommunicationDao,
  IAppointmentTypeDao,
  IAppointmentTypeReminderDao,
} from 'core/api/types/appointment-types.interface';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { arrayUnion } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedDialogBase from 'shared/dialog/dialog-base';
import AppointmentConfirmationEmailPreview from 'shared/email-previews/appointment-confirmation';
import AppointmentReminderEmailPreview from 'shared/email-previews/appointment-reminder';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
interface IAddEditAppointmentTypeCommunicationDialog {
  appointmentType: IAppointmentTypeDao;
  type: 'reminder' | 'confirmation';
  reminder?: IAppointmentTypeReminderDao;
  confirmation?: IAppointmentTypeCommunicationDao;
}

interface AddEditAppointmentTypeCommunicationDialogFormOutput {
  smsEnabled: boolean;
  smsContent?: string;
  emailEnabled: boolean;
  emailContent?: string;
  hoursBefore?: number;
}

const AddEditAppointmentTypeCommunicationDialog = ({
  appointmentType,
  reminder,
  confirmation,
  type,
}: IAddEditAppointmentTypeCommunicationDialog) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { message } = App.useApp();
  const { userData } = useUserState();
  const creating = !reminder && !confirmation;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const isReminder = type === 'reminder';
  const smsEnabled = Form.useWatch('smsEnabled', form);
  const emailEnabled = Form.useWatch('emailEnabled', form);
  const smsContent = Form.useWatch('smsContent', form);
  const emailContent = Form.useWatch('emailContent', form);
  const [previewEmail, setPreviewEmail] = useState<'confirmation' | 'reminder'>();

  const confirmationSmsContent = `Dear Patient Name,\n\nWe are pleased to confirm your appointment has now been booked for: 01/01/2024 09:00\n\n${smsContent}\n\nKind regards, Your Name`;
  const reminderSmsContent = `Dear Patient Name\n\nHere is a reminder about your hearing appointment taking place on 01/01/2024 at 09:00\n\n${smsContent}\n\nKind regards, Your Name`;

  const fields: ISharedField[] = [
    {
      fieldKey: 'smsEnabled',
      control: ControlType.Switch,
      label: t('appointment_types.appointment_type_detail.add_edit_communication.sms_enabled'),
      required: true,
    },
    {
      fieldKey: 'smsContent',
      control: ControlType.TextArea,
      rows: 4,
      label: t('appointment_types.appointment_type_detail.communication.sms_content'),
      showCount: true,
      maxLength: 60,
      required: true,
      hidden: !smsEnabled,
      extra: smsEnabled && smsContent && (
        <FormItemMimic label={t('appointment_types.appointment_type_detail.communication.sms_preview')}>
          <p className='whitespace-pre-wrap p-2 border rounded-md'>
            {isReminder ? reminderSmsContent : confirmationSmsContent}
          </p>
        </FormItemMimic>
      ),
    },
    {
      fieldKey: 'emailEnabled',
      control: ControlType.Switch,
      label: t('appointment_types.appointment_type_detail.add_edit_communication.email_enabled'),
      required: true,
    },
    {
      fieldKey: 'emailContent',
      control: ControlType.TextArea,
      rows: 4,
      label: t('appointment_types.appointment_type_detail.communication.email_content'),
      required: true,
      hidden: !emailEnabled,
      extra: (
        <>
          <SharedButton
            onClick={() => {
              if (previewEmail) {
                setPreviewEmail(undefined);
              } else {
                setPreviewEmail(!isReminder ? 'confirmation' : 'reminder');
              }
            }}
            appearance='link'
            primaryOverride
            labelKey={
              previewEmail
                ? 'appointment_types.appointment_type_detail.communication.hide_preview'
                : 'appointment_types.appointment_type_detail.communication.show_preview'
            }
          />
          {previewEmail === 'confirmation' && (
            <AppointmentConfirmationEmailPreview customContent={emailContent ?? ''} />
          )}

          {previewEmail === 'reminder' && <AppointmentReminderEmailPreview customContent={emailContent ?? ''} />}
        </>
      ),
    },
    {
      fieldKey: 'hoursBefore',
      control: ControlType.NumberField,
      label: t('appointment_types.appointment_type_detail.add_edit_communication.hours_before'),
      required: true,
      hidden: !isReminder,
      min: 1,
    },
  ];

  const save = async (data: AddEditAppointmentTypeCommunicationDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (type === 'reminder' && !data.emailEnabled && !data.smsEnabled) {
        message.error(t('appointment_types.appointment_type_detail.add_edit_communication.create.must_enable_one'));
        setSubmitting(false);
        return;
      }
      await AppointmentTypesApiService.update(appointmentType.uid, {
        updated: getActionTimestampFromUser(userData),
        ...(isReminder
          ? {
              reminders: arrayUnion({
                ...(isReminder && { hoursBefore: data.hoursBefore, key: uuidv4() }),
                sms: {
                  enabled: data.smsEnabled,
                  ...(data.smsContent && { content: data.smsContent }),
                },
                email: {
                  enabled: data.emailEnabled,
                  ...(data.emailContent && { content: data.emailContent }),
                },
              }),
            }
          : {
              confirmation: {
                sms: {
                  enabled: data.smsEnabled,
                  ...(data.smsContent && { content: data.smsContent }),
                },
                email: {
                  enabled: data.emailEnabled,
                  ...(data.emailContent && { content: data.emailContent }),
                },
              },
            }),
      });
      dialog?.closeDialog();
      message.success(t('appointment_types.appointment_type_detail.add_edit_communication.create.success'));
    } catch (error) {
      setSubmitting(false);
      message.error(t('appointment_types.appointment_type_detail.add_edit_communication.create.error'));
      sentryCaptureException(error, 'Add appointment type communication', userData);
    }
  };

  const edit = async (data: AddEditAppointmentTypeCommunicationDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (type === 'reminder' && !data.emailEnabled && !data.smsEnabled) {
        message.error(t('appointment_types.appointment_type_detail.add_edit_communication.create.must_enable_one'));
        setSubmitting(false);
        return;
      }
      await AppointmentTypesApiService.update(appointmentType.uid, {
        updated: getActionTimestampFromUser(userData),
        ...(isReminder
          ? {
              reminders: appointmentType.reminders.map((r) =>
                r.key === reminder?.key
                  ? {
                      key: reminder?.key,
                      sms: {
                        enabled: data.smsEnabled,
                        ...(data.smsContent && { content: data.smsContent }),
                      },
                      email: {
                        enabled: data.emailEnabled,
                        ...(data.emailContent && { content: data.emailContent }),
                      },
                      hoursBefore: data.hoursBefore,
                    }
                  : r
              ),
            }
          : {
              confirmation: {
                sms: {
                  enabled: data.smsEnabled,
                  ...(data.smsContent && { content: data.smsContent }),
                },
                email: {
                  enabled: data.emailEnabled,
                  ...(data.emailContent && { content: data.emailContent }),
                },
              },
            }),
      });
      dialog?.closeDialog();
      message.success(t('appointment_types.appointment_type_detail.add_edit_communication.edit.success'));
    } catch (error) {
      setSubmitting(false);
      message.error(t('appointment_types.appointment_type_detail.add_edit_communication.edit.error'));
      sentryCaptureException(error, 'Edit appointment type communication', userData);
    }
  };

  const getExistingValue = () => {
    if (creating) {
      return { smsEnabled: false, emailEnabled: false };
    }

    if (isReminder) {
      return {
        smsEnabled: reminder?.sms.enabled,
        smsContent: reminder?.sms.content,
        emailEnabled: reminder?.email.enabled,
        emailContent: reminder?.email.content,
        hoursBefore: reminder?.hoursBefore,
      };
    }

    return {
      smsEnabled: confirmation?.sms.enabled,
      smsContent: confirmation?.sms.content,
      emailEnabled: confirmation?.email.enabled,
      emailContent: confirmation?.email.content,
    };
  };

  const customContent = () => {
    return (
      <SharedForm<AddEditAppointmentTypeCommunicationDialogFormOutput>
        className='overflow-y-auto p-4'
        formInstance={form}
        onFinish={creating ? save : edit}
        fields={fields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-appointment-type-communication-form'
        existingValue={getExistingValue()}
      />
    );
  };

  const getTitle = () => {
    if (type === 'reminder') {
      return t(
        creating
          ? 'appointment_types.appointment_type_detail.add_edit_communication.reminder.create.title'
          : 'appointment_types.appointment_type_detail.add_edit_communication.reminder.edit.title'
      );
    }

    return t(
      creating
        ? 'appointment_types.appointment_type_detail.add_edit_communication.confirmation.create.title'
        : 'appointment_types.appointment_type_detail.add_edit_communication.confirmation.edit.title'
    );
  };

  return <SharedDialogBase title={getTitle()} customContentTemplate={customContent()} showButtons={false} />;
};

export default AddEditAppointmentTypeCommunicationDialog;
