export enum PaymentMethod {
  CARD = 'card',
  CASH = 'cash',
  BANK_TRANSFER = 'bankTransfer',
  CHEQUE = 'cheque',
  FINANCE = 'finance',
  SPREAD = 'spread',
  OTHER = 'other',
}

export const PaymentMethodData = {
  [PaymentMethod.CARD]: {
    value: PaymentMethod.CARD,
    translationLabelKey: 'payment_method.card',
  },
  [PaymentMethod.CASH]: {
    value: PaymentMethod.CASH,
    translationLabelKey: 'payment_method.cash',
  },
  [PaymentMethod.BANK_TRANSFER]: {
    value: PaymentMethod.BANK_TRANSFER,
    translationLabelKey: 'payment_method.bank_transfer',
  },
  [PaymentMethod.CHEQUE]: {
    value: PaymentMethod.CHEQUE,
    translationLabelKey: 'payment_method.cheque',
  },
  [PaymentMethod.FINANCE]: {
    value: PaymentMethod.FINANCE,
    translationLabelKey: 'payment_method.finance',
  },
  [PaymentMethod.SPREAD]: {
    value: PaymentMethod.SPREAD,
    translationLabelKey: 'payment_method.spread',
  },
  [PaymentMethod.OTHER]: {
    value: PaymentMethod.OTHER,
    translationLabelKey: 'payment_method.other',
  },
};

export const PaymentMethodOptions = [
  PaymentMethod.CARD,
  PaymentMethod.CASH,
  PaymentMethod.BANK_TRANSFER,
  PaymentMethod.CHEQUE,
  PaymentMethod.FINANCE,
  PaymentMethod.SPREAD,
  PaymentMethod.OTHER,
];
