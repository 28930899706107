import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { App } from 'antd';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { TransactionType, TransactionTypeData, TransactionTypeOptions } from 'core/constants/transaction-type';
import dayjs, { Dayjs } from 'dayjs';
import { IPatientDao } from 'core/api/types';
import { PaymentMethod, PaymentMethodData, PaymentMethodOptions } from 'core/constants/payment-method';
import { TransactionApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { ITransactionDao } from 'core/api/types/transaction.interface';

interface IAddEditTransactionDialogFormOutput {
  transactionType: TransactionType;
  date: Dayjs;
  amount: number;
  method: PaymentMethod;
}

interface IAddEditTransactionDialog {
  transaction?: ITransactionDao;
  patient: IPatientDao;
}

const AddEditTransactionDialog = ({ transaction, patient }: IAddEditTransactionDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();

  const submit = async (data: IAddEditTransactionDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const { date, ...rest } = data;
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        ...rest,
        date: Timestamp.fromDate(date.toDate()),
        patient,
        updated: userTimestamp,
      };
      if (transaction) {
        await TransactionApiService.update(transaction.uid, { ...basePayload });
      } else {
        await TransactionApiService.set({
          ...basePayload,
          organisationUid: userData.organisationUid,
          uid: uuidv4(),
          created: userTimestamp,
        });
      }
      dialog?.closeDialog();
      message.success(
        t(
          transaction
            ? 'patients.patient.transactions.add_edit_transaction.edit.success'
            : 'patients.patient.transactions.add_edit_transaction.create.success'
        )
      );
    } catch (error) {
      message.error(
        t(
          transaction
            ? 'patients.patient.transactions.add_edit_transaction.edit.error'
            : 'patients.patient.transactions.add_edit_transaction.create.error'
        )
      );
      setSubmitting(false);
      sentryCaptureException(error, transaction ? 'Edit transaction' : 'Create transaction', userData);
    }
  };

  const fields: ISharedField[] = [
    {
      fieldKey: 'transactionType',
      control: ControlType.Select,
      options: TransactionTypeOptions.map((type) => {
        const data = TransactionTypeData[type];
        return { label: t(data.translationLabelKey), value: type };
      }),
      label: t('patients.patient.transactions.add_edit_transaction.form.type'),
      required: true,
    },
    {
      fieldKey: 'date',
      control: ControlType.DatePicker,
      maxDate: dayjs(),
      label: t('patients.patient.transactions.add_edit_transaction.form.date'),
      required: true,
      fullWidth: true,
    },
    {
      fieldKey: 'amount',
      control: ControlType.NumberField,
      min: 1,
      fullWidth: true,
      label: t('patients.patient.transactions.add_edit_transaction.form.amount'),
      required: true,
    },
    {
      fieldKey: 'method',
      control: ControlType.Select,
      options: PaymentMethodOptions.map((method) => {
        const data = PaymentMethodData[method];
        return { label: t(data.translationLabelKey), value: method };
      }),
      label: t('patients.patient.transactions.add_edit_transaction.form.method'),
      required: true,
    },
  ];

  const customContent = () => {
    return (
      <SharedForm<IAddEditTransactionDialogFormOutput>
        onFinish={submit}
        fields={fields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-user-form'
        existingValue={transaction ? { ...transaction, date: dayjs(transaction.date.toDate()) } : undefined}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        transaction
          ? 'patients.patient.transactions.add_edit_transaction.edit.title'
          : 'patients.patient.transactions.add_edit_transaction.create.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditTransactionDialog;
