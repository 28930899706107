import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IActor } from 'core/api/types';
import { IOrganisationThemeDataDao } from 'core/api/types';
import { DataStatus, IListDataSlice, StoreState } from 'store';

export interface IDomainOrganisation {
  uid: string;
  name: string;
  createdAtSeconds: number;
  themeData: IOrganisationThemeDataDao;
  features: string[];
  createdBy: IActor;
  updatedBy: IActor;
  updatedAtSeconds: number;
  owner?: {
    fullName: string;
    uid: string;
  };
}

const initialState: IListDataSlice<IDomainOrganisation> = {
  data: [],
  status: 'loading',
};

const slice = createSlice({
  name: 'organisations',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    updateData(state, action: PayloadAction<IDomainOrganisation[]>) {
      state.data.push(...action.payload);
    },
    updateStatus(state, action: PayloadAction<DataStatus>) {
      state.status = action.payload;
    },
    updateState(state, action: PayloadAction<IListDataSlice<IDomainOrganisation>>) {
      state = action.payload;
    },
    resetData(state) {
      state.data = [];
    },
  },
});

const { reset, resetData, updateData, updateStatus, updateState } = slice.actions;

const selectState = (state: StoreState) => state.organisations;

const selectByUid = (state: StoreState, uid: string) => state.organisations.data.find((doc) => doc.uid === uid);

export const OrganisationsSlice = {
  reset,
  updateData,
  resetData,
  updateStatus,
  updateState,
  selectState,
  selectByUid,
};

export default slice.reducer;
