import { App, Dropdown, Tag } from 'antd';
import { StockApiService } from 'core/api';
import { IStockDao } from 'core/api/types/stock.interface';
import { StockStatusData, StockStatusOptions, StockStatus } from 'core/constants/stock-status';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface ISharedStockStatusTableTemplate {
  stock: IStockDao;
  tableKey?: string;
}

const SharedStockStatusTableTemplate = ({ stock, tableKey }: ISharedStockStatusTableTemplate) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const table = useTable(tableKey ?? '');
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  const statusData = StockStatusData[stock.status];
  return (
    <Dropdown
      menu={{
        items: StockStatusOptions.filter(
          (status) => status !== stock.status && status !== StockStatus.ON_TRIAL && status !== StockStatus.SOLD
        ).map((status) => {
          const data = StockStatusData[status];
          return {
            key: status,
            label: t(data.translationLabelKey),
          };
        }),
        onClick: async ({ key }) => {
          setLoading(true);
          try {
            await StockApiService.update(stock.uid, {
              status: key as StockStatus,
              updated: getActionTimestampFromUser(userData),
            });
            table?.refreshTable();
            message.success(t('stock_management.table.status.update.success'));
          } catch (error) {
            message.error(t('stock_management.table.status.update.error'));
          } finally {
            setLoading(false);
          }
        },
      }}
      trigger={['click']}
    >
      <Tag className='cursor-pointer' icon={loading && <SyncOutlined spin />} color={statusData.color ?? 'default'}>
        {t(statusData ? statusData.translationLabelKey : 'common.unknown')}
      </Tag>
    </Dropdown>
  );
};
export default SharedStockStatusTableTemplate;
