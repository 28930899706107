import { Navigate, Route, Routes } from 'react-router-dom';
import AdminUsersList from './admin-users-list';

const AdminUsersRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AdminUsersList />} />
      <Route path='/create' element={<AdminUsersList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AdminUsersRouting;
