import * as Sentry from '@sentry/react';
import { IUserDao } from 'core/api/types';

export const sentryCaptureException = (error: unknown, actionDescription: string, user?: IUserDao) => {
  Sentry.setContext('action', {
    description: actionDescription,
    user: {
      fullName: user?.fullName,
      uid: user?.uid,
      organisationUid: user?.organisationUid,
      emailAddress: user?.emailAddress,
    },
  });
  Sentry.captureException(error);
};
