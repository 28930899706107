import { Tag } from 'antd';
import { IAuditLogDao } from 'core/api/types';
import { useTranslation } from 'react-i18next';

const SharedAuditLogActionTemplate = (log: IAuditLogDao) => {
  const { t } = useTranslation();
  let action = '';
  let color = '';

  switch (log.actionType) {
    case 'create':
      action = t('audit_log.action.create');
      color = 'green';
      break;
    case 'update':
      action = t('audit_log.action.update');
      color = 'blue';
      break;
    case 'delete':
      action = t('audit_log.action.delete');
      color = 'red';
      break;
  }

  return (
    <Tag className='uppercase font-semibold' color={color}>
      {action}
    </Tag>
  );
};

export default SharedAuditLogActionTemplate;
