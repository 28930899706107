import { Navigate, Route, Routes } from 'react-router-dom';
import PatientDashboard from './patient-dashboard';
import { Permission } from 'core/constants/permission';
import PermissionGuard from 'core/routing/permission-guard';
import PatientRouting from './patient/patient-routing';

const PatientsRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.PATIENTS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: '',
      element: <PatientDashboard />,
    },
    {
      requiredPermissions: [[Permission.PATIENTS_READ], [Permission.ORGANISATION_OWNER]],
      path: ':uid/*',
      element: <PatientRouting />,
    },
  ];

  return (
    <Routes>
      {routes.map(({ requiredPermissions, path, element }) => (
        <Route
          key={path}
          element={
            <PermissionGuard
              requiredPermissions={requiredPermissions}
              navList={routes.map((route) => ({
                labelKey: '',
                requiredPermissions: route.requiredPermissions,
                route: `hearing-tests/${route.path}`,
              }))}
            />
          }
        >
          <Route path={path} element={element} />
        </Route>
      ))}
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default PatientsRouting;
