import { App } from 'antd';
import { IAccessoryDao } from 'core/api/types';
import { AccessoriesApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IDeleteAccessoriesDialog {
  existing: IAccessoryDao;
  tableKey: string;
}

const DeleteAccessoriesDialog = ({ existing, tableKey }: IDeleteAccessoriesDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useTable(tableKey);
  const { userData } = useUserState();

  const deleteAccessories = async () => {
    setLoading(true);
    try {
      const userTimestamp = getActionTimestampFromUser(userData);
      await AccessoriesApiService.update(existing.uid, { deleted: true, updated: userTimestamp });
      dialog?.closeDialog();
      message.success(t('dialog.delete_accessories.success.description'));
      table?.refreshTable();
    } catch (error) {
      message.error(t('dialog.delete_accessories.error.description'));
      setLoading(false);
      sentryCaptureException(error, 'Delete accessories', userData);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteAccessories',
      props: {
        loading,
        onClick: () => deleteAccessories(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.delete_accessories.title')}
      textContent={t('dialog.delete_accessories.content')}
      customButtons={customButtons}
    />
  );
};

export default DeleteAccessoriesDialog;
