import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from 'core/config/firebase';
import SharedAuthPageWrapper from './auth-page-wrapper';
import { useTranslation } from 'react-i18next';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { Rule } from 'antd/es/form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IResetPasswordFormOutput {
  newPassword: string;
  confirmNewPassword: string;
}

const ResetPassword = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { message } = App.useApp();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('oobCode');

  useEffect(() => {
    if (!code) {
      navigate('/auth/login');
      message.error(t('auth.reset_password.invalid_link'));
    }
  }, [code, message, navigate, t]);

  const getMismatchRule = (dependency: string): Rule[] => [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value || getFieldValue(dependency) === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(t('auth.reset_password.password_mismatch')));
      },
    }),
  ];

  const fields: ISharedField[] = [
    {
      fieldKey: 'newPassword',
      control: ControlType.TextField,
      type: InputType.Password,
      label: t('auth.new_password'),
      required: true,
      dependencies: ['confirmNewPassword'],
      customRules: [...getMismatchRule('confirmNewPassword')],
    },
    {
      fieldKey: 'confirmNewPassword',
      control: ControlType.TextField,
      type: InputType.Password,
      label: t('auth.confirm_new_password'),
      required: true,
      dependencies: ['newPassword'],
      customRules: [...getMismatchRule('newPassword')],
    },
  ];

  const onFinish = async (data: IResetPasswordFormOutput) => {
    try {
      setFormSubmitting(true);
      await confirmPasswordReset(auth, code!, data.newPassword);
      navigate('/auth/login');
      message.success(t('auth.reset_password.success'));
    } catch (error) {
      setFormSubmitting(false);
      message.error(t('auth.reset_password.error'));
      sentryCaptureException(error, 'Reset password');
    }
  };

  return (
    <SharedAuthPageWrapper>
      <p className='header-lg mb-1'>{t('auth.reset_password.title')}</p>
      <p className='mb-6'>{t('auth.reset_password.instruction')}</p>
      <SharedForm<IResetPasswordFormOutput>
        onFinish={onFinish}
        fields={fields}
        submitting={formSubmitting}
        buttonLabelKey='common.submit'
        name='reset-password'
        className='p-0'
        cancelButton={{
          labelKey: 'auth.return_to_login',
          onClick: () => navigate('/auth/login'),
        }}
      />
    </SharedAuthPageWrapper>
  );
};

export default ResetPassword;
