import { FormInstance } from 'antd';
import clsx from 'clsx';
import { ControlType } from 'core/enums/control-type';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import FormItemMimic from 'shared/form/form-item-mimic';
import SignatureCanvas from 'react-signature-canvas';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { InputType } from 'core/enums/input-type';
import { IOrderDao } from 'core/api/types/order.interface';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from 'core/config/firebase';

interface IAddEditOrderCustomerDeclaration {
  order?: IOrderDao;
  form: FormInstance;
  setSignature: Dispatch<SetStateAction<SignatureCanvas | null>>;
  signatureError?: boolean;
}

export interface IAddEditOrderCustomerDeclarationFormOutput {
  customerVerification?: string;
}

const AddEditOrderCustomerDeclaration = ({
  order,
  form,
  setSignature,
  signatureError,
}: IAddEditOrderCustomerDeclaration) => {
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);
  const { disabledFields, defaultFields } = orderFormSettings?.data ?? {};
  const { customerDeclaration, customerVerification, cancelationPolicy } = defaultFields ?? {};
  const { t } = useTranslation();
  const [signatureImage, setSignatureImage] = useState<string>();

  const formFields: ISharedField[] = [
    {
      fieldKey: 'customerVerification',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('orders.add_edit_order.declaration.form.patient_signature'),
      required: customerVerification === 'manual',
    },
  ];

  useEffect(() => {
    if (order?.patientVerification?.method === 'signature' && order?.patientVerification?.value) {
      const getImage = async (imageUrl: string) => {
        const signature = await getDownloadURL(ref(storage, imageUrl));
        setSignatureImage(signature);
      };
      getImage(order.patientVerification.value);
    }
  }, [order]);

  const isFieldEnabled = (fieldKey: string) => !disabledFields?.includes(fieldKey);

  return (
    <SharedCard title={t('orders.add_edit_order.patient_declaration')} innerClassName='p-4'>
      {isFieldEnabled('customerDeclaration') && <p className='mb-4 whitespace-pre-wrap'>{customerDeclaration}</p>}
      {isFieldEnabled('customerVerification') && customerVerification === 'signature' && (
        <FormItemMimic label={t('orders.add_edit_order.declaration.form.patient_signature')}>
          {signatureImage ? (
            <img className={clsx('border w-full h-[200px]')} src={signatureImage} alt='signature' />
          ) : (
            <SignatureCanvas
              penColor='black'
              canvasProps={{
                className: clsx('border w-full h-[200px]', signatureError && 'border-red-500'),
              }}
              ref={(ref) => {
                setSignature(ref);
              }}
            />
          )}
          {signatureError && <p className='text-red-500'>{t('field.required')}</p>}
        </FormItemMimic>
      )}
      {isFieldEnabled('customerVerification') && customerVerification === 'manual' && (
        <SharedForm
          fields={formFields}
          formInstance={form}
          name='add_edit_order.patient_declaration'
          className=''
          buttonsOverride={[]}
          existingValue={{ customerVerification: order?.patientVerification?.value }}
        />
      )}
      {isFieldEnabled('cancelationPolicy') && (
        <FormItemMimic className='mt-4' label={t('orders.add_edit_order.declaration.form.cancelation_policy')}>
          <p className='whitespace-pre-wrap'>{cancelationPolicy}</p>
        </FormItemMimic>
      )}
    </SharedCard>
  );
};

export default AddEditOrderCustomerDeclaration;
