import { motion } from 'framer-motion';
import { AnimationState } from 'core/constants/animation-state';

interface IFadeWrapper {
  children: any;
  className: string;
  animationState: string;
}

const FadeWrapper = ({ children, className, animationState }: IFadeWrapper) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: animationState === AnimationState.EXITING ? 0 : 1 }}
      transition={{ duration: 0.2 }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

export default FadeWrapper;
