import { message } from 'antd';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { StockStatus } from 'core/constants/stock-status';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { where, orderBy } from 'firebase/firestore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import Skarkey from 'assets/images/starkey.svg';
import clsx from 'clsx';

const PatientOverviewManufacturerWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  return (
    <SharedCard
      outerClassName='flex flex-col row-span-2'
      innerClassName='grow flex flex-col relative min-h-[100px]'
      title={t('patients.patient.patient_overview.manufacturer_widget.title')}
    >
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.STOCK_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const [loading, setLoading] = useState(true);
  const { userData } = useUserState();
  const [manufacturerCounts, setManufacturerCounts] = useState<{ [key: string]: number }>({});
  const manufacturerCountsEntries = Object.entries(manufacturerCounts);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        const counts: { [key: string]: number } = {};
        snap.forEach((doc) => {
          const data = doc.data();
          counts[data.manufacturer] = (counts[data.manufacturer] ?? 0) + 1;
          setManufacturerCounts(counts);
        });
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_overview.manufacturer_widget.error'));
        sentryCaptureException(error, 'Patient file fetching last transactions', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('allocatedTo', '==', patient.uid),
        where('status', 'in', [StockStatus.SOLD, StockStatus.ON_TRIAL]),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [patient.uid, userData]);

  return (
    <div>
      {loading ? (
        <div
          className={clsx(
            'flex justify-between items-center p-4 border-b',
            manufacturerCountsEntries.length < 2 ? '' : 'last:border-b-0'
          )}
        >
          <SkeletonElement height='60px' width='100px' />
          <SkeletonElement height='22px' width='80px' />
        </div>
      ) : (
        <div>
          {manufacturerCountsEntries.length === 0 && (
            <p className='absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] text-gray-300'>
              {t('patients.patient.patient_overview.manufacturer_widget.no_products')}
            </p>
          )}
          {manufacturerCountsEntries
            .filter(([_, count]) => count > 0)
            .map(([manufacturer, count]) => (
              <div
                key={manufacturer}
                className={clsx(
                  'flex justify-between items-center p-4 border-b',
                  manufacturerCountsEntries.length < 2 ? '' : 'last:border-b-0'
                )}
              >
                {manufacturer.toLowerCase().trim() === 'starkey' ? (
                  <img src={Skarkey} alt='Starkey' className='max-h-[60px]' />
                ) : (
                  <p className='text-xl font-extralight'>{manufacturer}</p>
                )}
                <p>
                  {count} {count > 1 ? 'products' : 'product'}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default PatientOverviewManufacturerWidget;
