import { FormInstance } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import AddEditOrderProductEar from './add-edit-order-hearing-aids-ear';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { useWatch } from 'antd/es/form/Form';
import { useUserState } from 'core/providers/user-provider';
import { IOrderDao } from 'core/api/types/order.interface';

interface IAddEditOrderHearingAids {
  order?: IOrderDao;
  leftForm: FormInstance;
  rightForm: FormInstance;
}

const AddEditOrderHearingAids = ({ order, leftForm, rightForm }: IAddEditOrderHearingAids) => {
  const [enabled, setEnabled] = useState<{
    [key: string]: boolean;
  }>({
    left: false,
    right: false,
  });
  const { t } = useTranslation();
  const leftPrice = useWatch('price', leftForm);
  const rightPrice = useWatch('price', rightForm);
  const { organisationData } = useUserState();

  const ears: { key: 'left' | 'right'; label: string; enabled: boolean; form: FormInstance }[] = [
    {
      key: 'left',
      label: t('order.add_edit_order.hearing_aids.left'),
      enabled: enabled.left,
      form: leftForm,
    },
    {
      key: 'right',
      label: t('order.add_edit_order.hearing_aids.right'),
      enabled: enabled.right,
      form: rightForm,
    },
  ];

  const copyEarProduct = (key: 'left' | 'right') => {
    const formToCopy = key === 'left' ? ears[0].form : ears[1].form;
    const formToUpdate = key === 'left' ? ears[1].form : ears[0].form;
    const updateKey = key === 'left' ? 'right' : 'left';
    setEnabled((prev) => ({ ...prev, [updateKey]: true }));
    formToUpdate.setFieldsValue(formToCopy.getFieldsValue());
  };

  const toggleEar = (key: string) => {
    setEnabled((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  useEffect(() => {
    if (order?.hearingAids) {
      if (order.hearingAids.left) {
        setEnabled((prev) => ({ ...prev, left: true }));
      }
      if (order.hearingAids.right) {
        setEnabled((prev) => ({ ...prev, right: true }));
      }
    }
  }, [order]);

  return (
    <SharedCard title={t('order.add_edit_order.hearing_aids.title')}>
      <div className='grid grid-cols-1 md:grid-cols-2'>
        {ears.map((ear) => (
          <AddEditOrderProductEar
            order={order}
            toggleEnabled={toggleEar}
            key={ear.key}
            form={ear.form}
            earKey={ear.key}
            copyToOtherEar={copyEarProduct}
            enabled={ear.enabled}
            label={ear.label}
          />
        ))}
      </div>
      <div className='border-t p-4 flex items-center justify-between'>
        <p>{t(organisationData?.finance.vatEnabled ? 'common.total_excl_vat' : 'common.total')}</p>
        <p>{formatToCurrency((leftPrice ?? 0) + (rightPrice ?? 0), organisationData?.finance.currency)}</p>
      </div>
    </SharedCard>
  );
};
export default AddEditOrderHearingAids;
