export enum ControlType {
  TextField,
  NumberField,
  PhoneNumber,
  File,
  Select,
  SelectMultipleTags,
  Checkbox,
  Address,
  ColorPicker,
  DatePicker,
  CheckboxGroup,
  TextArea,
  Switch,
  RadioButton,
  Signature,
  DateRangePicker,
}
