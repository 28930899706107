import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { IHearingAidDao } from 'core/api/types';
import { HearingAidsApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';

interface IAddHearingAidsFormOutput extends IHearingAidDao {}

interface IAddEditHearingAidsDialog {
  existing?: IHearingAidDao;
  tableKey: string;
}
const AddEditHearingAidsDialog = ({ existing, tableKey }: IAddEditHearingAidsDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;
  const table = useTable(tableKey);

  const AddHearingAidsFormFields: ISharedField[] = [
    {
      fieldKey: 'manufacturer',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.manufacturer'),
      required: true,
    },
    {
      fieldKey: 'model',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.model'),
      required: true,
    },
    {
      fieldKey: 'style',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.style'),
      required: true,
    },
    {
      fieldKey: 'receivers',
      control: ControlType.SelectMultipleTags,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.receivers'),
      required: false,
    },
    {
      fieldKey: 'gainOptions',
      control: ControlType.SelectMultipleTags,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.gain_options'),
      required: true,
    },
    {
      fieldKey: 'earbudSize',
      control: ControlType.SelectMultipleTags,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.earbud_size'),
      required: false,
    },
    {
      fieldKey: 'earbudType',
      control: ControlType.SelectMultipleTags,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.earbud_type'),
      required: false,
    },
    {
      fieldKey: 'power',
      control: ControlType.SelectMultipleTags,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.power'),
      required: true,
    },
    {
      fieldKey: 'unitCost',
      control: ControlType.NumberField,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.unit_cost'),
      required: true,
    },
    {
      fieldKey: 'rrp',
      control: ControlType.NumberField,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.rrp'),
      required: true,
    },
    {
      fieldKey: 'warranty',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('products_and_services.hearing_aids.add_edit_hearing_aids.warranty'),
      required: true,
    },
  ];

  const submit = async (data: IAddHearingAidsFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const { receivers, earbudSize, earbudType, ...rest } = data;
      const basePayload = {
        ...rest,
        ...(receivers && { receivers }),
        ...(earbudSize && { earbudSize }),
        ...(earbudType && { earbudType }),
        updated: userTimestamp,
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await HearingAidsApiService.set({
          ...basePayload,
          uid: uuidv4(),
          created: userTimestamp,
          deleted: false,
        });
        message.success(t('dialog.add_edit_hearing_aids.create.success.description'));
      } else {
        await HearingAidsApiService.update(existing.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_hearing_aids.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.refreshTable();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'dialog.add_edit_hearing_aids.edit.error.description'
            : 'dialog.add_edit_hearing_aids.create.error.description'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddHearingAidsFormOutput>
        className='overflow-y-auto p-4'
        onFinish={submit}
        fields={AddHearingAidsFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-hearing-aids'
        existingValue={existing}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_hearing_aids.create.title' : 'dialog.add_edit_hearing_aids.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditHearingAidsDialog;
