import { Navigate, Route, Routes } from 'react-router-dom';
import EditVatSettings from './edit-finance-settings';

const FinanceRouting = () => {
  return (
    <Routes>
      <Route path='' element={<EditVatSettings />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default FinanceRouting;
