import { useTheme } from 'core/providers/theme-provider';

interface IProgressBar {
  color?: string;
}

const ProgressBar = ({ color }: IProgressBar) => {
  const { primary } = useTheme();
  return (
    <div className='w-full'>
      <div className='h-[4px] w-full overflow-hidden' style={{ background: `${color ?? primary.bg}19` }}>
        <div className='progress w-full h-full left-right' style={{ backgroundColor: color ?? primary.bg }} />
      </div>
    </div>
  );
};

export default ProgressBar;
