import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import SharedButton from 'shared/button/button';

interface ISharedCalendarHeader {
  currentDate: Dayjs;
  changeDate: (newDate: string) => void;
  minDate?: Dayjs;
  headerHeight: number;
  extra?: ReactNode;
}

const SharedCalendarHeader = ({ currentDate, changeDate, minDate, headerHeight, extra }: ISharedCalendarHeader) => {
  const handleArrowClick = (direction: 'left' | 'right') => {
    const movement = direction === 'left' ? -1 : 1;
    changeDate(dayjs(currentDate).add(movement, 'day').format('YYYY-MM-DD'));
  };

  return (
    <div
      className='grow-0 shrink-0 flex px-2 items-center border-b justify-between space-x-1'
      style={{ flexBasis: `${headerHeight}px` }}
    >
      <div className='grow flex items-center space-x-1'>
        <SharedButton
          disabled={minDate !== undefined && currentDate <= minDate}
          icon={<ChevronLeft size={20} />}
          onClick={() => handleArrowClick('left')}
        />
        <SharedButton icon={<ChevronRight size={20} />} onClick={() => handleArrowClick('right')} />
        <DatePicker
          className='md:min-w-[320px] grow md:grow-0'
          allowClear={false}
          format={'dddd, D MMMM'}
          value={currentDate}
          onChange={(date) => changeDate(date.format('YYYY-MM-DD'))}
        />
      </div>
      {extra}
    </div>
  );
};

export default SharedCalendarHeader;
