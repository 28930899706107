import { IUserDao } from 'core/api/types';
import { UsersApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import GroupDetailAddMemberDialog from './group-detail-add-member-dialog';
import { useUserState } from 'core/providers/user-provider';
import GroupDetailRemoveMemberDialog from './group-detail-remove-member-dialog';
import { Permission } from 'core/constants/permission';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { App } from 'antd';

interface IGroupDetailMembers {
  uid: string;
  currentMembers: string[];
}

const GroupDetailMembers = ({ uid, currentMembers }: IGroupDetailMembers) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [membersData, setMembersData] = useState<IUserDao[]>([]);
  const dialog = useDialog();
  const { userData } = useUserState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getMemberData = async () => {
      setLoading(true);
      const readSets = [];
      const membersToProcess = [...currentMembers];
      while (membersToProcess.length) {
        readSets.push(membersToProcess.splice(0, 29));
      }
      try {
        const promises = readSets.map((set) =>
          UsersApiService.getAll([where('uid', 'in', set), where('organisationUid', '==', userData?.organisationUid)])
        );
        const results = await Promise.all(promises);
        const docs = results.flatMap((snap) => snap.docs.map((doc) => doc.data()));
        setMembersData(docs);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(t('groups.group_detail.members.error.description'));
      }
    };

    getMemberData();
  }, [message, currentMembers, t, userData?.organisationUid]);

  return (
    <SharedCard
      outerClassName='h-fit'
      title={t('groups.group_detail.members.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.GROUPS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <div className='flex space-x-2 items-center'>
            <SharedButton
              onClick={() =>
                dialog?.openDialog(<GroupDetailAddMemberDialog uid={uid} currentMembers={currentMembers} />)
              }
              appearance='link'
              labelKey={'groups.group_detail.members.add_member'}
              primaryOverride
            />
          </div>
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <p className='p-4 body-sm opacity-40'>{t('common.loading_with_ellipsis')}</p>
      ) : (
        <>
          {membersData.length === 0 && (
            <p className='p-4 body-sm opacity-40'>{t('groups.group_detail.members.no_members')}</p>
          )}
          {membersData.map((member) => (
            <div key={member.uid} className='px-4 py-3 border-b last:border-0 flex items-center justify-between'>
              <div>
                <p className='body-sm'>{member.fullName}</p>
                <p className='body-sm opacity-30'>{member.emailAddress}</p>
              </div>
              <SharedElementPermissionGuard
                requiredPermissions={[[Permission.GROUPS_UPDATE], [Permission.ORGANISATION_OWNER]]}
              >
                <SharedButton
                  onClick={() => dialog?.openDialog(<GroupDetailRemoveMemberDialog user={member} groupUid={uid} />)}
                  appearance='link'
                  labelKey={'common.remove'}
                  danger
                />
              </SharedElementPermissionGuard>
            </div>
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default GroupDetailMembers;
