import { AdminFirestoreCacheProvider } from 'core/providers/admin-firestore-cache-provider';
import { OrganisationFirestoreCacheProvider } from 'core/providers/organisation-cache-provider';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface IAdminGuard {
  requiresAdmin: boolean;
}

const AdminGuard = ({ requiresAdmin }: IAdminGuard) => {
  const { isSuperAdmin } = usePermissionsState();
  const location = useLocation();

  if (requiresAdmin && !isSuperAdmin) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  if (!requiresAdmin && isSuperAdmin) {
    return <Navigate to='/admin' state={{ from: location }} replace />;
  }

  if (requiresAdmin && isSuperAdmin) {
    return (
      <AdminFirestoreCacheProvider>
        <Outlet />
      </AdminFirestoreCacheProvider>
    );
  }

  return (
    <OrganisationFirestoreCacheProvider>
      <Outlet />
    </OrganisationFirestoreCacheProvider>
  );
};
export default AdminGuard;
