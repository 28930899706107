import { Navigate, Route, Routes } from 'react-router-dom';
import EditCalendarSettings from './edit-calendar-settings';

const CalendarRouting = () => {
  return (
    <Routes>
      <Route path='' element={<EditCalendarSettings />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default CalendarRouting;
