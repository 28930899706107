import { App } from 'antd';
import { AppointmentsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { orderBy, Unsubscribe, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AppointmentListItem from 'shared/appointment/appointment-list-item';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientAppointmentsList = (patient: IPatientDao) => {
  const { userData } = useUserState();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const { message } = App.useApp();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = AppointmentsApiService.onCollectionSnapshot(
      (snap) => {
        setAppointments(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_appointments.error.description'));
        sentryCaptureException(error, 'Patient file fetching appointments', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        orderBy('startDateTime', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  return (
    <SharedCard
      title={t('patients.patient.patient_appointments.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENTS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <Link to={`/calendar/create?patient=${patient.uid}`}>
            <SharedButton labelKey='patients.patient.patient_appointments.create' appearance='link' primaryOverride />
          </Link>
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <div className='p-4 pl-6'>
          <SkeletonElement width='220px' height='30px' />
          <SkeletonElement width='210px' height='20px' className='mt-2' />
          <SkeletonElement width='200px' height='20px' className='mt-1' />
          <SkeletonElement width='210px' height='20px' className='mt-1' />
          <SkeletonElement width='250px' height='10px' className='mt-2' />
        </div>
      ) : (
        <>
          {appointments.length === 0 && <p className='text-gray-400 p-4'>{t('common.no_results')}</p>}

          {appointments.map((appointment) => (
            <AppointmentListItem key={appointment.uid} appointment={appointment} />
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default PatientAppointmentsList;
