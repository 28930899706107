import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { Col, Row, Select } from 'antd';
import { useSearchParams } from 'react-router-dom';

export interface IFilter {
  key: string;
  label: string;
  options: {
    value: string;
    label: string;
  }[];
  mode?: 'multiple' | 'tags';
}

interface ISharedFilter {
  filters: IFilter[];
  onFilterChange: (filters: { [key: string]: string[] }) => void;
}

const SharedFilter = ({ filters, onFilterChange }: ISharedFilter) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false);

  const updatedFilters = useMemo(() => {
    return Array.from(searchParams.entries()).reduce((acc, [k, v]) => {
      acc[k] = v.split(',');
      return acc;
    }, {} as { [key: string]: string[] });
  }, [searchParams]);

  useEffect(() => {
    onFilterChange(updatedFilters);
  }, [updatedFilters, onFilterChange]);

  const handleFilterChange = (key: string, value: string[]) => {
    setSearchParams((prev) => {
      if (value.length === 0) {
        prev.delete(key);
      } else {
        prev.set(key, value.join(','));
      }

      return prev;
    });
  };

  return (
    <SharedCard
      title={t('filters.title')}
      extra={
        <SharedButton
          labelKey={showFilters ? 'filters.hide_filters' : 'filters.show_filters'}
          onClick={() => {
            setShowFilters(!showFilters);
          }}
        />
      }
    >
      {showFilters && (
        <div className='p-4'>
          <Row gutter={16} wrap>
            {filters.map((filter) => (
              <Col key={filter.key} xs={{ span: 24 }} lg={{ span: 6 }}>
                <Select
                  className='mb-4 lg:mb-0'
                  placeholder={filter.label}
                  options={filter.options}
                  mode={filter.mode}
                  style={{ width: '100%' }}
                  defaultValue={searchParams.get(filter.key)?.split(',')}
                  onChange={(value) => handleFilterChange(filter.key, value)}
                />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </SharedCard>
  );
};

export default SharedFilter;
