import { App } from 'antd';
import clsx from 'clsx';
import { OrderApiService, TransactionApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { TransactionType } from 'core/constants/transaction-type';
import { useUserState } from 'core/providers/user-provider';
import { count, sum, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOverviewFinancialWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  return (
    <SharedCard
      outerClassName='flex flex-col row-span-2'
      innerClassName='grow flex flex-col'
      title={t('patients.patient.patient_overview.financials_widget.title')}
    >
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.ORDERS_READ, Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const [revenue, setRevenue] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [ordersCount, setOrdersCount] = useState<number>(0);
  const [paymentsCount, setPaymentsCount] = useState<number>(0);
  const { userData, organisationData } = useUserState();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const { t } = useTranslation();

  const getFinancials = useCallback(async () => {
    if (userData?.organisationUid) {
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
      ];

      const aggregation = {
        total: sum('amount'),
      };
      try {
        setLoading(true);
        const [orders, charges, refunds, payments] = await Promise.all([
          OrderApiService.getCollectionCount([...baseConstraints, where('cancelled', '==', false)]),
          TransactionApiService.getCollectionAggregate(
            [...baseConstraints, where('transactionType', '==', TransactionType.CHARGE)],
            aggregation
          ),
          TransactionApiService.getCollectionAggregate(
            [...baseConstraints, where('transactionType', '==', TransactionType.REFUND)],
            aggregation
          ),
          TransactionApiService.getCollectionAggregate(
            [...baseConstraints, where('transactionType', '==', TransactionType.PAYMENT)],
            {
              ...aggregation,
              countOfDocs: count(),
            }
          ),
        ]);

        const revenue = (charges.data().total ?? 0) - (refunds.data().total ?? 0);
        setRevenue(revenue);
        setBalance((payments.data().total ?? 0) - revenue);
        setOrdersCount(orders.data().count);
        setPaymentsCount(payments.data().countOfDocs ?? 0);
        setLoading(false);
      } catch (error) {
        message.error(t('patients.patient.patient_overview.financials_widget.error'));
        sentryCaptureException(error, 'fetching stock counts', userData);
      }
    }
  }, [message, patient.uid, t, userData]);

  useEffect(() => {
    getFinancials();
  }, [getFinancials]);

  const sections = [
    {
      key: 'orders',
      title: t('patients.patient.patient_overview.financials_widget.orders'),
      value: ordersCount,
    },
    {
      key: 'revenue',
      title: t('patients.patient.patient_overview.financials_widget.revenue'),
      value: formatToCurrency(revenue, organisationData?.finance.currency),
    },
    {
      key: 'payments',
      title: t('patients.patient.patient_overview.financials_widget.payments'),
      value: paymentsCount,
    },
    {
      key: 'balance',
      title: t('patients.patient.patient_overview.financials_widget.balance'),
      value: formatToCurrency(balance, organisationData?.finance.currency),
      color: balance < 0 ? 'text-red-500' : '',
    },
  ];

  return sections.map((section) => (
    <div key={section.key} className='grow flex flex-col justify-center border-b last:border-0 p-4'>
      <p className='font-semibold'>{section.title}</p>
      <div>
        {loading ? (
          <SkeletonElement width='300px' height='28px' />
        ) : (
          <p className={clsx('text-2xl font-extralight', section.color && section.color)}>{section.value}</p>
        )}
      </div>
    </div>
  ));
};

export default PatientOverviewFinancialWidget;
