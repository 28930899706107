import { Navigate, Route, Routes } from 'react-router-dom';
import ClinicsList from './clinics-list';

const ClinicsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<ClinicsList />} />

      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default ClinicsRouting;
