export enum Pinna {
  NORMAL = 'normal',
  ABNORMAL = 'abnormal',
}

export const PinnaData = {
  [Pinna.NORMAL]: {
    value: Pinna.NORMAL,
    translationLabelKey: 'pinna.normal',
  },
  [Pinna.ABNORMAL]: {
    value: Pinna.ABNORMAL,
    translationLabelKey: 'pinna.abnormal',
  },
};

export const PinnaOptions = [Pinna.NORMAL, Pinna.ABNORMAL];
