export enum StockStatus {
  NEEDS_ORDERING = 'needsOrdering',
  ORDERED = 'ordered',
  IN_STOCK = 'inStock',
  ON_TRIAL = 'onTrial',
  SOLD = 'sold',
}

export const StockStatusData = {
  [StockStatus.NEEDS_ORDERING]: {
    value: StockStatus.NEEDS_ORDERING,
    translationLabelKey: 'stock_management.stock_status.needs_ordering',
    color: 'red',
  },
  [StockStatus.ORDERED]: {
    value: StockStatus.ORDERED,
    translationLabelKey: 'stock_management.stock_status.ordered',
    color: 'orange',
  },
  [StockStatus.IN_STOCK]: {
    value: StockStatus.IN_STOCK,
    translationLabelKey: 'stock_management.stock_status.in_stock',
    color: 'green',
  },
  [StockStatus.ON_TRIAL]: {
    value: StockStatus.ON_TRIAL,
    translationLabelKey: 'stock_management.stock_status.on_trial',
    color: 'blue',
  },
  [StockStatus.SOLD]: {
    value: StockStatus.SOLD,
    translationLabelKey: 'stock_management.stock_status.sold',
    color: 'green',
  },
};

export const StockStatusOptions = [
  StockStatus.NEEDS_ORDERING,
  StockStatus.ORDERED,
  StockStatus.IN_STOCK,
  StockStatus.ON_TRIAL,
  StockStatus.SOLD,
];
