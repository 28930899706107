import { FormInstance } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { PaymentMethod, PaymentMethodData, PaymentMethodOptions } from 'core/constants/payment-method';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useUserState } from 'core/providers/user-provider';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import SignatureCanvas from 'react-signature-canvas';
import clsx from 'clsx';
import { IOrderDao } from 'core/api/types/order.interface';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from 'core/config/firebase';

interface IAddEditOrderCosting {
  order?: IOrderDao;
  grossPrice: number;
  vatAmount: number;
  form: FormInstance;
  signatureError?: boolean;
  setSignature: Dispatch<SetStateAction<SignatureCanvas | null>>;
}

export interface IAddEditOrderCostingFormOutput {
  discount?: number;
  depositAmount?: number;
  depositPaymentMethod?: PaymentMethod;
  depositVerification?: string;
  balancePaymentMethod?: PaymentMethod;
}

const AddEditOrderCosting = ({
  order,
  grossPrice,
  form,
  signatureError = false,
  setSignature,
  vatAmount,
}: IAddEditOrderCosting) => {
  const { organisationData } = useUserState();
  const { t } = useTranslation();
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);
  const disabledFields = orderFormSettings?.data?.disabledFields;
  const discount = useWatch('discount', form) ?? 0;
  const depositAmount = useWatch('depositAmount', form) ?? 0;
  const audiologistVerification = orderFormSettings?.data?.defaultFields?.audiologistVerification ?? 'manual';
  const [signatureImage, setSignatureImage] = useState<string>();
  const paymentMethodOptions = PaymentMethodOptions.map((option) => {
    const opData = PaymentMethodData[option];
    return {
      value: option,
      label: t(opData.translationLabelKey),
    };
  });

  useEffect(() => {
    if (order?.audiologistVerification?.method === 'signature' && order?.audiologistVerification?.value) {
      const getImage = async (imageUrl: string) => {
        const signature = await getDownloadURL(ref(storage, imageUrl));
        setSignatureImage(signature);
      };
      getImage(order.audiologistVerification.value);
    }
  }, [order]);

  const fields: ISharedField[] = [
    {
      fieldKey: 'discount',
      control: ControlType.NumberField,
      label: t('form_settings.order_form.discount_insurance'),
      required: true,
      hidden: disabledFields?.includes('discountInsurance'),
      fullWidth: true,
      min: 0,
      extra: (
        <FormItemMimic
          label={t(
            organisationData?.finance.vatEnabled
              ? 'orders.add_edit_order.costing.final_price_incl_vat'
              : 'orders.add_edit_order.costing.final_price'
          )}
          className=''
        >
          <p>{formatToCurrency(grossPrice + vatAmount - discount, organisationData?.finance.currency)}</p>
        </FormItemMimic>
      ),
    },
    {
      fieldKey: 'depositAmount',
      control: ControlType.NumberField,
      label: t('orders.add_edit_order.costing.form.deposit_amount'),
      required: true,
      hidden: disabledFields?.includes('deposit'),
      fullWidth: true,
      min: 0,
    },
    {
      fieldKey: 'depositPaymentMethod',
      control: ControlType.Select,
      options: paymentMethodOptions,
      label: t('orders.add_edit_order.costing.form.deposit_payment_method'),
      required: depositAmount > 0,
      hidden: disabledFields?.includes('deposit'),
    },
    {
      fieldKey: 'depositVerification',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('orders.add_edit_order.costing.form.deposit_signature', {
        depositAmount: formatToCurrency(depositAmount, organisationData?.finance.currency),
      }),
      required: audiologistVerification === 'manual',
      hidden:
        ['deposit', 'audiologistVerification'].some((key) => orderFormSettings?.data?.disabledFields?.includes(key)) ||
        audiologistVerification === 'signature',
    },
    {
      fieldKey: 'balancePaymentMethod',
      control: ControlType.Select,
      options: paymentMethodOptions,
      label: t('orders.add_edit_order.costing.form.balance_payment_method'),
      required: false,
      hidden: disabledFields?.includes('paymentMethod'),
    },
  ];
  return (
    <SharedCard title={t('orders.add_edit_order.costing.title')} innerClassName='p-4'>
      <FormItemMimic
        label={t(
          organisationData?.finance.vatEnabled
            ? 'orders.add_edit_order.costing.gross_price_excl_vat'
            : 'orders.add_edit_order.costing.gross_price'
        )}
      >
        {formatToCurrency(grossPrice, organisationData?.finance.currency)}
      </FormItemMimic>
      {organisationData?.finance.vatEnabled && (
        <FormItemMimic label={t('orders.add_edit_order.costing.vat')}>
          {formatToCurrency(
            grossPrice * (organisationData.finance.vatStandardRate / 100),
            organisationData?.finance.currency
          )}
        </FormItemMimic>
      )}

      <SharedForm
        formInstance={form}
        name='orders.add_edit_order.costing.form.1'
        fields={fields.slice(0, 3)}
        className=''
        buttonsOverride={[]}
        existingValue={{
          ...order,
          depositAmount: order?.deposit?.amount,
          depositPaymentMethod: order?.deposit?.method,
          depositVerification: order?.audiologistVerification?.value,
        }}
      />

      {!['deposit', 'audiologistVerification'].some((key) => orderFormSettings?.data?.disabledFields?.includes(key)) &&
        audiologistVerification === 'signature' && (
          <FormItemMimic
            label={t('orders.add_edit_order.costing.form.deposit_signature', {
              depositAmount: formatToCurrency(depositAmount, organisationData?.finance.currency),
            })}
          >
            {signatureImage ? (
              <img className={clsx('border w-full h-[200px]')} src={signatureImage} alt='signature' />
            ) : (
              <SignatureCanvas
                penColor='black'
                canvasProps={{
                  className: clsx('border w-full h-[200px]', signatureError && 'border-red-500'),
                }}
                ref={(ref) => {
                  setSignature(ref);
                }}
              />
            )}
            {signatureError && <p className='text-red-500'>{t('field.required')}</p>}
          </FormItemMimic>
        )}

      <FormItemMimic
        key='balance'
        label={t(
          organisationData?.finance.vatEnabled
            ? 'orders.add_edit_order.costing.balance_incl_vat'
            : 'orders.add_edit_order.costing.balance'
        )}
      >
        <p>{formatToCurrency(grossPrice + vatAmount - discount - depositAmount, organisationData?.finance.currency)}</p>
      </FormItemMimic>

      <SharedForm
        formInstance={form}
        name='orders.add_edit_order.costing.form.2'
        fields={fields.slice(3)}
        className=''
        buttonsOverride={[]}
        existingValue={{
          ...order,
          depositAmount: order?.deposit?.amount,
          depositPaymentMethod: order?.deposit?.method,
          depositVerification: order?.audiologistVerification?.value,
        }}
      />
    </SharedCard>
  );
};

export default AddEditOrderCosting;
