export enum Meatus {
  CLEAR = 'clear',
  SOME_WAX = 'someWax',
  BLOCKED = 'blocked',
}

export const MeatusData = {
  [Meatus.CLEAR]: {
    value: Meatus.CLEAR,
    translationLabelKey: 'meatus.clear',
  },
  [Meatus.SOME_WAX]: {
    value: Meatus.SOME_WAX,
    translationLabelKey: 'meatus.some_wax',
  },
  [Meatus.BLOCKED]: {
    value: Meatus.BLOCKED,
    translationLabelKey: 'meatus.blocked',
  },
};

export const MeatusOptions = [Meatus.CLEAR, Meatus.SOME_WAX, Meatus.BLOCKED];
