import clsx from 'clsx';
import { ReactNode } from 'react';

interface ISharedCard {
  children: any;
  title?: string;
  outerClassName?: string;
  innerClassName?: string;
  extra?: ReactNode;
}

const SharedCard = ({ title, children, outerClassName, innerClassName, extra }: ISharedCard) => {
  const outer = clsx('rounded-md bg-white shadow-md overflow-clip mb-4 shrink-0', outerClassName);

  return (
    <div className={outer}>
      {(title || extra) && (
        <div className='flex items-center justify-between px-4 min-h-[54px] border-b'>
          {title && <p className='header-sm text-gray-800'>{title}</p>}
          {extra}
        </div>
      )}
      <div className={innerClassName}>{children}</div>
    </div>
  );
};

export default SharedCard;
