import { ControlType } from 'core/enums/control-type';
import { Checkbox, ColorPicker, DatePicker, Input, InputNumber, Radio, Select, Switch } from 'antd';
import { ISharedField } from './shared-fields.interface';
import AddressSearch from './address-search';
import { InputType } from 'core/enums/input-type';
import SharedFileUpload from './file-upload';
import clsx from 'clsx';
const { RangePicker } = DatePicker;

export const getField = (field: ISharedField) => {
  switch (field.control) {
    case ControlType.TextField:
      return field.type === InputType.Password ? <Input.Password /> : <Input placeholder={field.placeholder} />;
    case ControlType.TextArea:
      return <Input.TextArea rows={field.rows} showCount={field.showCount ?? false} maxLength={field.maxLength} />;
    case ControlType.NumberField:
      return (
        <InputNumber<number>
          min={field.min}
          max={field.max}
          parser={field.parser}
          formatter={field.formatter}
          className={clsx(field.fullWidth && 'w-full')}
        />
      );
    case ControlType.ColorPicker:
      return <ColorPicker showText={field.showText} defaultValue={field.defaultValue} format={'hex'} />;
    case ControlType.Address:
      return <AddressSearch />;
    case ControlType.Select:
      return (
        <Select
          placeholder={field.placeholder}
          options={field.options}
          allowClear={field.allowClear}
          mode={field.mode}
          disabled={field.disabled}
        />
      );
    case ControlType.File:
      return (
        <SharedFileUpload
          acceptedFileTypes={field.acceptedFileTypes}
          maxCount={field.maxCount}
          maxFileSize={field.maxFileSize}
        />
      );
    case ControlType.DatePicker:
      return (
        <DatePicker
          minDate={field.minDate}
          maxDate={field.maxDate}
          className={clsx(field.fullWidth && 'w-full')}
          format={'DD/MM/YYYY'}
        />
      );
    case ControlType.CheckboxGroup:
      return <Checkbox.Group options={field.options} />;
    case ControlType.SelectMultipleTags:
      return <Select mode='tags' />;
    case ControlType.Switch:
      return <Switch />;
    case ControlType.RadioButton:
      return <Radio.Group options={field.options} optionType='button' buttonStyle='solid' />;
    case ControlType.DateRangePicker:
      return (
        <RangePicker
          minDate={field.minDate}
          maxDate={field.maxDate}
          className={clsx(field.fullWidth && 'w-full')}
          format={'DD/MM/YYYY'}
          allowClear={field.allowClear}
        />
      );
    default:
      throw new Error('Control type not found');
  }
};
