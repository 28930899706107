import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { IWorkflowStatusDao } from 'core/api/types/workflow.interface';

export interface IAddEditStatusFormOutput {
  name: string;
}

interface IAddEditWorkflowStatusDialog {
  existing?: IWorkflowStatusDao;
  onFinish: (data: IAddEditStatusFormOutput) => void;
}
const AddEditWorkflowStatusDialog = ({ existing, onFinish }: IAddEditWorkflowStatusDialog) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const creating = !existing;

  const AddLeadTypeFormFields: ISharedField[] = [
    {
      fieldKey: 'name',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('workflow.workflow_editor.add_edit_status.name'),
      required: true,
    },
  ];

  const customContent = () => {
    return (
      <SharedForm<IAddEditStatusFormOutput>
        onFinish={(data) => {
          onFinish(data);
          dialog?.closeDialog();
        }}
        fields={AddLeadTypeFormFields}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-workflow-status-form'
        existingValue={existing}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        creating
          ? 'workflow.workflow_editor.add_edit_status.create.title'
          : 'workflow.workflow_editor.add_edit_status.edit.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditWorkflowStatusDialog;
