import { App, Switch } from 'antd';
import { PatientApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

const VirtualServicingIndicator = (patient: IPatientDao) => {
  const [updatingVirtualServicing, setUpdatingVirtualServicing] = useState(false);
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { userData } = useUserState();

  return (
    <div className='flex border rounded-md shadow-sm mt-4 md:mt-0 body-sm w-fit items-center space-x-2 py-1.5 px-3'>
      <p>{t('patients.patient.information.virtual_servicing_status')}</p>
      <Switch
        loading={updatingVirtualServicing}
        size='small'
        value={patient.virtualServicingEnabled}
        onChange={async (checked) => {
          try {
            setUpdatingVirtualServicing(true);
            await PatientApiService.update(patient.uid, {
              virtualServicingEnabled: checked,
              updated: getActionTimestampFromUser(userData),
            });
            setUpdatingVirtualServicing(false);
            message.success(t('patients.patient.information.update_virtual_servicing_status.success'));
          } catch (error) {
            setUpdatingVirtualServicing(false);
            sentryCaptureException(error, 'Update patient virtual servicing status', userData);
            message.error(t('patients.patient.information.update_virtual_servicing_status.error'));
          }
        }}
      />
    </div>
  );
};

export default VirtualServicingIndicator;
