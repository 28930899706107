import { Navigate, Route, Routes } from 'react-router-dom';
import { Permission } from 'core/constants/permission';
import PermissionGuard from 'core/routing/permission-guard';
import ReportingHub from './reporting-hub';
import AppointmentTotalsReport from './appointment-reports/appointment-totals-report';

const ReportingRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.REPORTS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: '',
      element: <ReportingHub />,
    },
    {
      requiredPermissions: [[Permission.REPORTS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: 'appointments/total',
      element: <AppointmentTotalsReport />,
    },
  ];

  return (
    <Routes>
      {routes.map(({ requiredPermissions, path, element }) => (
        <Route
          key={path}
          element={
            <PermissionGuard
              requiredPermissions={requiredPermissions}
              navList={routes.map((route) => ({
                labelKey: '',
                requiredPermissions: route.requiredPermissions,
                route: `reporting/${route.path}`,
              }))}
            />
          }
        >
          <Route path={path} element={element} />
        </Route>
      ))}
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default ReportingRouting;
