import Denied from 'assets/images/denied.svg';
import { useTranslation } from 'react-i18next';

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <div className='grow flex flex-col items-center justify-center space-y-4 p-4'>
      <img src={Denied} alt='Access denied' className='max-h-[80px]' />
      <p className='body-xs text-gray-400'>{t('misc.permission_denied')}</p>
    </div>
  );
};

export default AccessDenied;
