import { App, Input } from 'antd';
import { IGetAddressSuggestion, IGetAddressSuggestions } from 'core/api/types/address.interface';
import AddressApiService from 'core/api/address.service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { ISharedButton } from 'shared/button/button.interface';
import { createAddressString } from 'shared/helpers/address-string-builder';
import { IAddressDao } from 'shared/interfaces/address.interface';

interface IAddressSearch {
  id?: string;
  value?: IAddressDao;
  onChange?: (value?: IAddressDao) => void;
  disabled?: boolean;
}

const AddressSearch = ({ id, value, onChange, disabled }: IAddressSearch) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [selectedAddress, setSelectedAddress] = useState<IAddressDao | undefined>();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState<IGetAddressSuggestions>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setSelectedAddress(value);
    }
  }, [value]);

  const queryChanged = (value: string) => {
    setQuery(value);
    setSuggestions(undefined);
  };

  const getSuggestions = async () => {
    if (query === '') {
      return;
    }
    setLoading(true);
    try {
      const result = await AddressApiService.autocomplete(query);
      setSuggestions(result);
      setLoading(false);
    } catch (error) {
      message.error(t('field.address_search.autocomplete_error_error.description'));
      setLoading(false);
    }
  };

  const handleSelect = async (suggestion: IGetAddressSuggestion) => {
    try {
      const { latitude, longitude, formatted_address, postcode } = await AddressApiService.getFullAddress(
        suggestion.id
      );
      setSuggestions(undefined);
      const newVal = {
        lat: latitude,
        lng: longitude,
        formattedAddress: createAddressString([...formatted_address, postcode]),
        postcode: postcode,
      };
      onChange?.(newVal);
    } catch (error) {
      message.error(t('field.address_search.search_full_error.description'));
    }
  };

  const reset = () => {
    setSelectedAddress(undefined);
    setSuggestions(undefined);
    if (onChange) {
      onChange(undefined);
    }
  };

  const getActionButton = (): ISharedButton => {
    let actionButton: ISharedButton = {
      type: 'button',
      appearance: 'primary',
      ghost: true,
      labelKey: 'field.address_search.search_button',
      onClick: getSuggestions,
      disabled: disabled,
      loading,
    };

    if (selectedAddress) {
      return { ...actionButton, onClick: reset, labelKey: 'common.reset' };
    }

    if (suggestions?.suggestions.length) {
      return { ...actionButton, onClick: () => setSuggestions(undefined), labelKey: 'common.cancel' };
    }

    return actionButton;
  };

  return (
    <div className='relative'>
      <div className='flex items-end space-x-2'>
        <div className='grow'>
          <Input
            onChange={(event) => queryChanged(event.currentTarget.value)}
            value={selectedAddress ? selectedAddress.formattedAddress : query}
            placeholder={t('field.address_search.placeholder')}
            disabled={disabled || loading || selectedAddress !== undefined}
          />
        </div>
        <SharedButton {...getActionButton()} />
      </div>
      {suggestions && (
        <div className='w-full rounded-md shadow-sm body-sm absolute mt-2 bg-white border-gray-300 border z-10 overflow-hidden'>
          {loading ? (
            <div className='px-1.5 py-2'>{t('field.address_search.searching_indicator')}</div>
          ) : (
            <div className='max-h-[200px] overflow-y-auto'>
              {suggestions.suggestions.map((suggestion) => (
                <div
                  key={suggestion.id}
                  className='px-1.5 py-2 hover:bg-gray-50 transition ease-in-out cursor-pointer border-b last:border-0'
                  onClick={() => handleSelect(suggestion)}
                >
                  {suggestion.address}
                </div>
              ))}
              {suggestions.suggestions.length === 0 && <p className='body-02 p-1.5'>{t('common.no_results')}</p>}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AddressSearch;
