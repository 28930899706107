import { useEffect, useState } from 'react';
import { useUserState } from 'core/providers/user-provider';
import { useDialog } from 'core/providers/dialog-provider';
import SetPasswordDialog from 'shared/dialog/set-password-dialog';
import SharedSidebar from 'shared/sidebar/sidebar';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import Header from './header';
import MobileHeader from './mobile-header';

const SharedLayout = ({ children }: any) => {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { userData } = useUserState();
  const dialog = useDialog();
  const location = useLocation();

  useEffect(() => {
    if (!dialog?.showDialog && userData?.resetPassword) {
      dialog?.openDialog(<SetPasswordDialog />);
    }
  }, [dialog, userData?.resetPassword]);

  return (
    <div className='flex flex-col md:flex-row'>
      <SharedSidebar mobileOpen={openMobileMenu} setOpenMobileMenu={setOpenMobileMenu} />
      <MobileHeader setOpenMobileMenu={setOpenMobileMenu} />

      <div className='grow flex flex-col pt-[60px] md:pt-0 overflow-x-hidden' style={{ height: screenHeight }}>
        <Header />
        <div
          className={clsx(
            'grow overflow-y-auto max-h-full flex flex-col relative',
            !['organisation-settings', 'patients'].some((path) => location.pathname.includes(path)) && 'px-4'
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SharedLayout;
