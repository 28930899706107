import { t } from 'i18next';

export const patientFormDefaultFields = [
  {
    key: 'referral',
    labelKey: 'form_settings.patient_form.referral',
    locked: true,
  },
  {
    key: 'title',
    labelKey: 'form_settings.patient_form.title',
    locked: true,
  },
  {
    key: 'fullName',
    labelKey: 'form_settings.patient_form.full_name',
    locked: true,
  },
  {
    key: 'address',
    labelKey: 'form_settings.patient_form.address',
    locked: false,
  },
  {
    key: 'phoneNumber',
    labelKey: 'form_settings.patient_form.primary_phone_number',
    locked: true,
  },
  {
    key: 'secondaryPhoneNumber',
    labelKey: 'form_settings.patient_form.secondary_phone_number',
    locked: false,
  },
  {
    key: 'emailAddress',
    labelKey: 'form_settings.patient_form.email_address',
    locked: false,
  },
  {
    key: 'dob',
    labelKey: 'form_settings.patient_form.dob',
    locked: false,
  },
  {
    key: 'gender',
    labelKey: 'form_settings.patient_form.gender',
    locked: false,
  },
  {
    key: 'maritalStatus',
    labelKey: 'form_settings.patient_form.marital_status',
    locked: false,
  },
  {
    key: 'emergencyContactName',
    labelKey: 'form_settings.patient_form.emergency_contact_name',
    locked: false,
  },
  { key: 'emergencyContactNumber', labelKey: 'form_settings.patient_form.emergency_contact_number', locked: false },
  { key: 'gpDetails', labelKey: 'form_settings.patient_form.gp_details', locked: false },
  { key: 'contactPreference', labelKey: 'form_settings.patient_form.contact_preference', locked: false },
  { key: 'contactPermissions', labelKey: 'form_settings.patient_form.contact_permissions', locked: false },
];

export const orderFormDefaultFields = [
  {
    key: 'audiologistName',
    labelKey: 'form_settings.order_form.audiologist_name',
    locked: true,
  },
  {
    key: 'patientName',
    labelKey: 'form_settings.order_form.patient_name',
    locked: true,
  },
  {
    key: 'address',
    labelKey: 'form_settings.order_form.address',
    locked: true,
  },
  {
    key: 'emailAddress',
    labelKey: 'form_settings.order_form.email_address',
    locked: true,
  },
  {
    key: 'phoneNumber',
    labelKey: 'form_settings.order_form.phone_number',
    locked: true,
  },
  {
    key: 'orderDate',
    labelKey: 'form_settings.order_form.order_date',
    locked: true,
  },
  {
    key: 'orderNumber',
    labelKey: 'form_settings.order_form.order_number',
    locked: false,
  },
  {
    key: 'goodsServices',
    labelKey: 'form_settings.order_form.goods_services',
    locked: true,
  },
  {
    key: 'discountInsurance',
    labelKey: 'form_settings.order_form.discount_insurance',
    locked: false,
  },
  {
    key: 'totalPrice',
    labelKey: 'form_settings.order_form.total_price',
    locked: true,
  },
  {
    key: 'deposit',
    labelKey: 'form_settings.order_form.deposit',
    locked: false,
  },
  {
    key: 'audiologistVerification',
    labelKey: 'form_settings.order_form.audiologist_verification',
    locked: false,
    options: [
      { value: 'signature', label: t('form_settings.order_form.signature') },
      { value: 'manual', label: t('form_settings.order_form.manual') },
    ],
  },
  {
    key: `paymentMethod`,
    labelKey: 'form_settings.order_form.payment_method',
    locked: false,
  },
  {
    key: 'customerDeclaration',
    labelKey: 'form_settings.order_form.customer_declaration',
    locked: false,
    textInput: true,
  },
  {
    key: 'customerVerification',
    labelKey: 'form_settings.order_form.customer_verification',
    locked: false,
    options: [
      { value: 'signature', label: t('form_settings.order_form.signature') },
      { value: 'manual', label: t('form_settings.order_form.manual') },
    ],
  },
  {
    key: 'additionalInformation',
    labelKey: 'form_settings.order_form.additional_information',
    locked: false,
  },
  {
    key: 'cancelationPolicy',
    labelKey: 'form_settings.order_form.cancelation_policy',
    locked: false,
    textInput: true,
  },
];
