import { Navigate, Route, Routes } from 'react-router-dom';
import { Permission } from 'core/constants/permission';
import PermissionGuard from 'core/routing/permission-guard';
import OrdersList from './orders-list';
import AddEditOrder from './add-edit-order/add-edit-order';

const OrdersRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.ORDERS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: '',
      element: <OrdersList />,
    },
    {
      requiredPermissions: [[Permission.ORDERS_CREATE], [Permission.ORGANISATION_OWNER]],
      path: 'create',
      element: <AddEditOrder />,
    },
    {
      requiredPermissions: [[Permission.ORDERS_CREATE], [Permission.ORGANISATION_OWNER]],
      path: 'edit',
      element: <AddEditOrder />,
    },
  ];

  return (
    <Routes>
      {routes.map(({ requiredPermissions, path, element }) => (
        <Route
          key={path}
          element={
            <PermissionGuard
              requiredPermissions={requiredPermissions}
              navList={routes.map((route) => ({
                labelKey: '',
                requiredPermissions: route.requiredPermissions,
                route: `orders/${route.path}`,
              }))}
            />
          }
        >
          <Route path={path} element={element} />
        </Route>
      ))}
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default OrdersRouting;
