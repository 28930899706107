import { Navigate, Route, Routes } from 'react-router-dom';
import GroupsList from './groups-list';
import GroupDetail from './group-detail/group-detail';

const GroupsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<GroupsList />} />
      <Route path=':uid' element={<GroupDetail />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default GroupsRouting;
