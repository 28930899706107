import { createContext, useCallback, useContext, useState } from 'react';

interface ITableDataContext {
  tables: ITableDataContextTablesState;
  register: (tableData: ITableDataContextTableState) => void;
  deregister: (tableId: string) => void;
}

interface ITableDataContextTableState {
  key: string;
  refreshTable: () => Promise<void>;
  deregister?: () => void;
}

interface ITableDataContextTablesState {
  [key: string]: ITableDataContextTableState;
}

export const TableDataContext = createContext<ITableDataContext>({
  tables: {},
  register: () => {},
  deregister: () => {},
});

export const useTablesState = () => {
  const state = useContext(TableDataContext);
  return state;
};

export const useTable = (tableId: string) => {
  const state = useContext(TableDataContext);
  return state.tables[tableId];
};

export const useMultipleTables = (tableId: string[]) => {
  const state = useContext(TableDataContext);
  return tableId.map((id) => state.tables[id]);
};

export const TableDataProvider = ({ children }: any) => {
  const [tables, setTables] = useState<ITableDataContextTablesState>({});

  const register = useCallback((tableData: ITableDataContextTableState) => {
    return setTables((prevState) => ({ ...prevState, [tableData.key]: tableData }));
  }, []);

  const deregister = useCallback((tableId: string) => {
    return setTables((prevState) => {
      const newState = { ...prevState };
      delete newState[tableId];
      return newState;
    });
  }, []);

  return <TableDataContext.Provider value={{ tables, register, deregister }}>{children}</TableDataContext.Provider>;
};
