import { App } from 'antd';
import { IHearingAidDao } from 'core/api/types';
import { HearingAidsApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { useTable } from 'core/providers/table-data-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IDeleteHearingAidsDialog {
  existing: IHearingAidDao;
  tableKey: string;
}

const DeleteHearingAidsDialog = ({ existing, tableKey }: IDeleteHearingAidsDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useTable(tableKey);
  const { userData } = useUserState();

  const deleteHearingAids = async () => {
    setLoading(true);
    try {
      const userTimestamp = getActionTimestampFromUser(userData);
      await HearingAidsApiService.update(existing.uid, { updated: userTimestamp, deleted: true });
      dialog?.closeDialog();
      message.success(t('dialog.delete_hearing_aids.success.description'));
      table?.refreshTable();
    } catch (error) {
      message.error(t('dialog.delete_hearing_aids.error.description'));
      setLoading(false);
      sentryCaptureException(error, 'Delete hearing aids', userData);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteHearingAids',
      props: {
        loading,
        onClick: () => deleteHearingAids(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.delete_hearing_aids.title')}
      textContent={t('dialog.delete_hearing_aids.content')}
      customButtons={customButtons}
    />
  );
};

export default DeleteHearingAidsDialog;
