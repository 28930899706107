import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import { useUserState } from 'core/providers/user-provider';
import FieldSetting from '../field-setting';
import { OrganisationsApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { App } from 'antd';
import SharedSpinner from 'shared/spinner/spinner';
import { useTheme } from 'core/providers/theme-provider';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';

const EditCalendarSettings = () => {
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const { primary } = useTheme();

  const { t } = useTranslation();

  const setValue = async (key: string, value: boolean | string | number | string[] | Timestamp | null) => {
    try {
      if (organisationData) {
        await OrganisationsApiService.update(organisationData?.uid, {
          [`calendar.${key}`]: value,
          updated: getActionTimestampFromUser(userData),
        });
        message.success(t('calendar.edit.success'));
      }
    } catch (error) {
      message.error(t('calendar.edit.error'));
      sentryCaptureException(error, 'Edit calendar settings', userData);
    }
  };

  return (
    <>
      <SharedPageHeader title={t('calendar.edit')} />

      {!organisationData?.calendar && (
        <SharedCard>
          <div className='h-[250px] flex items-center justify-center'>
            <SharedSpinner color={primary.bg} />
          </div>
        </SharedCard>
      )}

      {organisationData?.calendar && (
        <SharedCard>
          <FieldSetting
            key={'startTime'}
            setValue={setValue}
            fieldKey={'startTime'}
            labelKey={'calendar.startTime'}
            isLocked={true}
            fieldValue={organisationData?.calendar.startTime}
            setDisabledTime={(value) => {
              const hour = dayjs(organisationData?.calendar.endTime.toDate()).hour();
              const minute = dayjs(organisationData?.calendar.endTime.toDate()).minute();

              const activeHour = minute === 0 ? hour : hour + 1;

              return {
                disabledHours: () => Array.from({ length: 24 - activeHour }, (_, i) => activeHour + i),
                disabledMinutes: () =>
                  hour === value ? Array.from({ length: 60 - minute }, (_, i) => minute + i) : [],
              };
            }}
          />

          <FieldSetting
            key={'endTime'}
            setValue={setValue}
            fieldKey={'endTime'}
            labelKey={'calendar.endTime'}
            isLocked={true}
            fieldValue={organisationData?.calendar.endTime}
            setDisabledTime={(value) => {
              const hour = dayjs(organisationData?.calendar.startTime.toDate()).hour();
              const minute = dayjs(organisationData?.calendar.startTime.toDate()).minute();

              return {
                disabledHours: () => Array.from({ length: hour }, (_, i) => i),
                disabledMinutes: () => (hour === value ? Array.from({ length: minute }, (_, i) => i) : []),
              };
            }}
          />
        </SharedCard>
      )}
    </>
  );
};

export default EditCalendarSettings;
