import { Navigate, Route, Routes } from 'react-router-dom';
import AudiometersList from './audiometers-list';

const AudiometersRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AudiometersList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AudiometersRouting;
