import { FormInstance } from 'antd';
import { Meatus, MeatusData, MeatusOptions } from 'core/constants/meatus';
import { Pinna, PinnaData, PinnaOptions } from 'core/constants/pinna';
import { TM, TMData, TMOptions } from 'core/constants/tm';
import { YesNo, YesNoData, YesNoOptions } from 'core/constants/yes-no';
import { ControlType } from 'core/enums/control-type';

import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { ISharedField } from 'shared/fields/shared-fields.interface';

import SharedForm from 'shared/form/shared-form';

interface IEarHealthForm {
  leftEarForm: FormInstance;
  rightEarForm: FormInstance;
}

export interface IEarHealthFormOutput {
  pinna?: Pinna;
  meatus?: Meatus;
  tm?: TM;
  discharge?: YesNo;
  earache?: YesNo;
  tinnitus?: YesNo;
  conductive?: YesNo;
}

const EarHealthForm = ({ leftEarForm, rightEarForm }: IEarHealthForm) => {
  const { t } = useTranslation();

  const yesNoFieldOptions = YesNoOptions.map((option) => {
    const data = YesNoData[option];
    return {
      label: t(data.translationLabelKey),
      value: data.value,
    };
  });

  const fields: ISharedField[] = [
    {
      fieldKey: 'pinna',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.pinna'),
      options: PinnaOptions.map((option) => {
        const data = PinnaData[option];
        return {
          label: t(data.translationLabelKey),
          value: data.value,
        };
      }),
      required: false,
    },
    {
      fieldKey: 'meatus',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.meatus'),
      options: MeatusOptions.map((option) => {
        const data = MeatusData[option];
        return {
          label: t(data.translationLabelKey),
          value: data.value,
        };
      }),
      required: false,
    },
    {
      fieldKey: 'tm',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.tm'),
      options: TMOptions.map((option) => {
        const data = TMData[option];
        return {
          label: t(data.translationLabelKey),
          value: data.value,
        };
      }),
      required: false,
    },
    {
      fieldKey: 'discharge',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.discharge'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'earache',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.earache'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'tinnitus',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.tinnitus'),
      options: yesNoFieldOptions,
      required: false,
    },
    {
      fieldKey: 'conductive',
      control: ControlType.RadioButton,
      label: t('hearing_test.add_edit_hearing_test.ear_health.form.conductive'),
      options: yesNoFieldOptions,
      required: false,
    },
  ];
  const ears = [
    {
      key: 'leftEarHealth',
      label: t('hearing_test.add_edit_hearing_test.ear_health.left'),
      form: leftEarForm,
    },
    {
      key: 'rightEarHealth',
      label: t('hearing_test.add_edit_hearing_test.ear_health.right'),
      form: rightEarForm,
    },
  ];

  const copyEarHealthDetail = (key: string) => {
    const formToCopy = key === 'leftEarHealth' ? ears[0].form : ears[1].form;
    const formToUpdate = key === 'leftEarHealth' ? ears[1].form : ears[0].form;
    formToUpdate.setFieldsValue(formToCopy.getFieldsValue());
  };

  return (
    <>
      {ears.map((ear) => (
        <div key={ear.key} className='border-b md:border-r md:border-b-0 last:border-0'>
          <div className='flex items-center justify-between px-4 min-h-[54px] border-b'>
            <p className='header-sm text-gray-800'>{ear.label}</p>
            <SharedButton
              onClick={() => copyEarHealthDetail(ear.key)}
              appearance='link'
              primaryOverride
              labelKey={'hearing_test.add_edit_hearing_test.ear_health.copy_to_other_ear'}
            />
          </div>
          <SharedForm
            formInstance={ear.form}
            name={`hearing_test.add_edit_hearing_test.${ear.key}`}
            fields={fields}
            buttonsOverride={[]}
          />
        </div>
      ))}
    </>
  );
};

export default EarHealthForm;
