import { useUserState } from 'core/providers/user-provider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SharedLayout from 'shared/layout/layout';

interface IAuthGuard {
  requiresAuth: boolean;
}

const AuthGuard = ({ requiresAuth }: IAuthGuard) => {
  const { isAuthenticated, user } = useUserState();
  const location = useLocation();

  if (requiresAuth && (!isAuthenticated || !user)) {
    return <Navigate to='auth/login' state={{ from: location }} replace />;
  }

  if (!requiresAuth && isAuthenticated) {
    return <Navigate to='' state={{ from: location }} replace />;
  }

  if (!requiresAuth) {
    return <Outlet />;
  }

  return (
    <SharedLayout>
      <Outlet />
    </SharedLayout>
  );
};
export default AuthGuard;
