import { createContext, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OrganisationsSlice } from 'modules/admin/organisations/admin-organisations-slice';
import { FirestoreError, QuerySnapshot, Unsubscribe } from 'firebase/firestore';
import { IOrganisationDao } from 'core/api/types';
import { OrganisationsApiService } from 'core/api';

export const AdminFirestoreCacheContext = createContext({});

export const AdminFirestoreCacheProvider = ({ children }: any) => {
  const dispatch = useDispatch();

  const handleSubscriptionError = useCallback(
    (error: FirestoreError) => {
      dispatch(OrganisationsSlice.updateStatus('error'));
    },
    [dispatch]
  );

  const handleSnapshot = useCallback(
    (snap: QuerySnapshot<IOrganisationDao>) => {
      dispatch(OrganisationsSlice.resetData());
      const domainOrganisations =
        snap.docs.map((doc) => {
          const { created, updated, ...rest } = doc.data();
          return {
            ...rest,
            createdAtSeconds: created.at.seconds,
            createdBy: created.by,
            updatedBy: updated.by,
            updatedAtSeconds: updated.at.seconds,
          };
        }) ?? [];
      dispatch(OrganisationsSlice.updateStatus('success'));
      dispatch(OrganisationsSlice.updateData(domainOrganisations));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(OrganisationsSlice.updateStatus('loading'));
    let unsubscribe: Unsubscribe;
    unsubscribe = OrganisationsApiService.onCollectionSnapshot(handleSnapshot, handleSubscriptionError);
    return () => {
      unsubscribe();
      dispatch(OrganisationsSlice.reset());
    };
  }, [dispatch, handleSnapshot, handleSubscriptionError]);

  return <AdminFirestoreCacheContext.Provider value={{}}>{children}</AdminFirestoreCacheContext.Provider>;
};
