import { useDialog } from 'core/providers/dialog-provider';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import FormSettingsDeleteCustomField from './form-settings-delete-custom-field-dialog';
import FormSettingsAddEditCustomField from './form-settings-add-edit-custom-field-dialog';
import { ICustomFormField } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

interface ICustomFieldSetting {
  isDisabled: boolean;
  isLoading: boolean;
  toggleSetting: (key: string, isDisabled: boolean) => Promise<void>;
  statusIcon: ReactNode;
  customField: ICustomFormField;
  collectionID: string;
}

const CustomFieldSetting = ({
  collectionID,
  customField,
  isDisabled,
  isLoading,
  toggleSetting,
  statusIcon,
}: ICustomFieldSetting) => {
  const { t } = useTranslation();
  const dialog = useDialog();

  return (
    <div className='border-b p-4 flex justify-between' key={customField.key}>
      <div className='flex items-center space-x-2'>
        {statusIcon}
        <div>
          <p className='body-xs text-gray-400'>{t('form_settings.custom_field')}</p>
          <p>{customField.label}</p>
        </div>
      </div>
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.FORM_SETTINGS_UPDATE], [Permission.ORGANISATION_OWNER]]}
      >
        <div className='flex space-x-4'>
          <SharedButton
            appearance='link'
            danger
            labelKey='common.delete'
            onClick={() =>
              dialog?.openDialog(
                <FormSettingsDeleteCustomField collectionID={collectionID} fieldKey={customField.key} />
              )
            }
          />
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='common.edit'
            onClick={() =>
              dialog?.openDialog(
                <FormSettingsAddEditCustomField collectionID={collectionID} customField={customField} />
              )
            }
          />
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey={isDisabled ? 'common.enable' : 'common.disable'}
            onClick={() => toggleSetting(customField.key, isDisabled)}
            loading={isLoading}
          />
        </div>
      </SharedElementPermissionGuard>
    </div>
  );
};

export default CustomFieldSetting;
