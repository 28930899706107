import { auth } from 'core/config/firebase';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { useNavigate } from 'react-router-dom';
import SharedAuthPageWrapper from './auth-page-wrapper';

const emailFieldKey = 'emailAddress';
const passwordFieldKey = 'password';

interface ILoginFormOutput {
  [emailFieldKey]: string;
  [passwordFieldKey]: string;
}

const Login = () => {
  const { t } = useTranslation();
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState<string>();
  const navigate = useNavigate();

  const onFormSubmit = async (data: ILoginFormOutput) => {
    try {
      setFormError(undefined);
      setFormSubmitting(true);
      await signInWithEmailAndPassword(auth, data.emailAddress, data.password);
    } catch (error: any) {
      setFormSubmitting(false);
      setFormError(t('auth.login.incorrect_email_password'));
    }
  };

  const LoginFormFields: ISharedField[] = [
    {
      fieldKey: emailFieldKey,
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('common.email_address'),
      required: true,
    },
    {
      fieldKey: passwordFieldKey,
      control: ControlType.TextField,
      type: InputType.Password,
      label: t('common.password'),
      required: true,
    },
  ];

  return (
    <SharedAuthPageWrapper>
      <p className='header-lg mb-8'>{t('auth.login.instruction')}</p>
      <SharedForm<ILoginFormOutput>
        onFinish={onFormSubmit}
        fields={LoginFormFields}
        submitting={formSubmitting}
        formError={formError}
        buttonLabelKey='auth.login'
        onChange={() => setFormError(undefined)}
        name='login'
        className='p-0'
        buttonsOverride={[
          {
            key: 'forgotPassword',
            labelKey: 'auth.forgot_password',
            appearance: 'link',
            primaryOverride: true,
            onClick: () => navigate('/auth/forgot-password'),
          },
          {
            key: 'login',
            labelKey: 'auth.login',
            appearance: 'primary',
            loading: formSubmitting,
            disabled: formSubmitting,
            type: 'submit',
          },
        ]}
      />
    </SharedAuthPageWrapper>
  );
};

export default Login;
