import { App } from 'antd';
import { TransactionApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ITransactionDao } from 'core/api/types/transaction.interface';
import { PaymentMethodData } from 'core/constants/payment-method';
import { Permission } from 'core/constants/permission';
import { TransactionTypeData } from 'core/constants/transaction-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { where, limit, orderBy } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOverviewLastTransactionsWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  return (
    <SharedCard
      outerClassName='flex flex-col row-span-1'
      innerClassName='grow flex flex-col relative min-h-[100px]'
      title={t('patients.patient.patient_overview.last_transactions_widget.title')}
    >
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.TRANSACTIONS_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [transactions, setTransactions] = useState<ITransactionDao[]>([]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = TransactionApiService.onCollectionSnapshot(
      (snap) => {
        setTransactions(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_overview.last_transactions_widget.error'));
        sentryCaptureException(error, 'Patient file fetching last transactions', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        limit(3),
        orderBy('date', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  return (
    <div>
      {loading ? (
        <div className='flex justify-between space-x-4 p-4 items-center border-b'>
          <SkeletonElement width='75px' height='16px' />
          <SkeletonElement width='115px' height='22px' />
          <SkeletonElement width='80px' height='22px' />
        </div>
      ) : (
        <div>
          {transactions.map((transaction) => (
            <TransactionTemplate key={transaction.uid} {...transaction} />
          ))}
          {transactions.length === 0 && (
            <p className='absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] text-gray-300'>
              {t('patients.patient.patient_overview.last_transactions_widget.no_transactions')}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

const TransactionTemplate = (transaction: ITransactionDao) => {
  const { t } = useTranslation();
  const typeData = TransactionTypeData[transaction.transactionType];
  const Icon = typeData?.icon;
  const modifier = transaction.transactionType !== 'charge' ? '-' : '+';
  const methodData = PaymentMethodData[transaction.method];
  const { organisationData } = useUserState();

  return (
    <div className='border-b last:border-0 grid grid-cols-10 gap-4 p-4 items-center'>
      <p className='body-xs text-gray-400 col-span-3'>{dayjs(transaction.date.toDate()).format('DD/MM/YYYY')}</p>
      <div className='flex items-center space-x-2 col-span-4'>
        <div
          style={{ backgroundColor: `${typeData.color}19`, borderColor: typeData.color }}
          className='rounded-full p-1 border'
        >
          <Icon color={typeData.color} size={12} />
        </div>
        <p>
          {t(typeData.translationLabelKey)} {methodData && <span>{`(${t(methodData?.translationLabelKey)})`}</span>}
        </p>
      </div>
      <p className='body-sm col-span-3 text-right'>
        {modifier} {formatToCurrency(transaction.amount, organisationData?.finance.currency)}
      </p>
    </div>
  );
};

export default PatientOverviewLastTransactionsWidget;
