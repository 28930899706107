import { Navigate, Route, Routes } from 'react-router-dom';
import Workflow from './workflow';
import WorkflowEditor from './workflow-editor/workflow-editor';
import PermissionGuard from 'core/routing/permission-guard';
import { OrganisationSettingsNavbarOptions } from '../organisation-settings-navbar-options';
import { Permission } from 'core/constants/permission';

const WorkflowRouting = () => {
  return (
    <Routes>
      <Route path='' element={<Workflow />} />
      <Route
        element={
          <PermissionGuard
            requiredPermissions={[[Permission.WORKFLOW_UPDATE], [Permission.ORGANISATION_OWNER]]}
            navList={OrganisationSettingsNavbarOptions}
          />
        }
      >
        <Route path='edit' element={<WorkflowEditor />} />
      </Route>
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default WorkflowRouting;
