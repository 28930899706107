import { Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import SharedButton from 'shared/button/button';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { AppointmentsApiService } from 'core/api';
import { useTranslation } from 'react-i18next';
import SetAppointmentOutcomeDialog from 'modules/appointments-calendar/set-appointment-outcome-dialog';

interface IAppointmentMenu {
  appointment: IAppointmentDao;
  className?: string;
  mode?: 'calendar' | 'list';
}

const AppointmentMenu = ({ appointment, className, mode = 'calendar' }: IAppointmentMenu) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useUserState();
  const dialog = useDialog();
  const location = useLocation();

  const timestamp = getActionTimestampFromUser(userData);

  const items: MenuProps['items'] = [
    {
      disabled: appointment.cancelled,
      key: '2',
      label: t('calendar.dropdown.edit_appointment'),
    },
    {
      disabled: appointment.cancelled,
      key: '3',
      label: t('calendar.dropdown.set_appointment_outcome'),
    },
    {
      disabled: appointment.cancelled,
      key: '4',
      label: t('calendar.dropdown.cancel_appointment'),
    },
  ];

  if (!location.pathname.includes('patients')) {
    items.unshift({
      key: '1',
      label: t('calendar.dropdown.view_patient'),
    });
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case '1':
        navigate(`/patients/${appointment.patient.uid}/overview`);
        break;
      case '2':
        navigate(`/calendar/create?patient=${appointment.patient.uid}&appointment=${appointment.uid}`);
        break;
      case '3':
        dialog?.openDialog(<SetAppointmentOutcomeDialog appointment={appointment} />);
        break;
      case '4':
        dialog?.openDialog(
          <ConfirmActionDialog
            action={() => AppointmentsApiService.update(appointment.uid, { cancelled: true, updated: timestamp })}
            actionButtonProps={{
              labelKey: 'common.cancel',
              danger: true,
            }}
            title={t('dialog.cancel_appointment.title')}
            textContent={t('dialog.cancel_appointment.content')}
            successMessage={t('dialog.cancel_appointment.success.description')}
            errorMessage={t('dialog.cancel_appointment.error.description')}
          />
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={className}>
      <Dropdown menu={{ items, onClick }} placement='bottomRight' trigger={['click']}>
        <div>
          <SharedButton
            additionalStyle={mode === 'calendar' ? { color: 'white' } : {}}
            appearance={mode === 'calendar' ? 'link' : 'default'}
            icon={<EllipsisOutlined size={20} />}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default AppointmentMenu;
