import { Navigate, Route, Routes } from 'react-router-dom';
import AdminUsersRouting from './users/admin-users-routing';
import AdminOrganisationsRouting from './organisations/admin-organisations-routing';

const AdminRouting = () => {
  return (
    <Routes>
      <Route path='organisations/*' element={<AdminOrganisationsRouting />} />
      <Route path='users/*' element={<AdminUsersRouting />} />
      <Route path='*' element={<Navigate replace to='organisations' />} />
    </Routes>
  );
};

export default AdminRouting;
