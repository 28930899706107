import { IUserDao, IActionTimestamp } from 'core/api/types';
import { Timestamp } from 'firebase/firestore';
import i18next from 'i18n';

export const getActionTimestampFromUser = (user?: IUserDao): IActionTimestamp => {
  return user
    ? {
        at: Timestamp.fromDate(new Date()),
        by: {
          fullName: user.fullName,
          uid: user.uid,
        },
      }
    : {
        at: Timestamp.fromDate(new Date()),
        by: {
          fullName: i18next.t('common.unknown'),
          uid: i18next.t('common.unknown'),
        },
      };
};
