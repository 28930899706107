import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './login';
import ForgotPassword from './forgot-password';
import ResetPassword from './reset-password';

const AuthRouting = () => {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='reset-password' element={<ResetPassword />} />
      <Route path='*' element={<Navigate replace to='login' />} />
    </Routes>
  );
};

export default AuthRouting;
