import { useTranslation } from 'react-i18next';
import StockManagementStockTable from './stock-management-stock-table';
import SharedPageHeader from 'shared/page-header/page-header';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import { useDialog } from 'core/providers/dialog-provider';
import { where } from 'firebase/firestore';
import SharedFilter, { IFilter } from 'shared/filter/filter';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useState } from 'react';

const StockManagementAccessoriesList = () => {
  const tableKey = 'stock_management.accessories.table';
  const { t } = useTranslation();
  const dialog = useDialog();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [manufacturersList, setManufacturersList] = useState<{ value: string; label: string }[]>([]);
  const [accessoryList, setAccessoryList] = useState<{ value: string; label: string }[]>([]);

  const accessories = useSelector(OrganisationSettingsSlice.selectAccessories);

  useEffect(() => {
    const manufacturers = [
      ...new Set(
        accessories?.data
          .filter(
            ({ deleted, accessoryName }) =>
              !deleted && (!activeFilters.accessory || activeFilters.accessory.includes(accessoryName))
          )
          .map(({ manufacturer }) => ({ value: manufacturer, label: manufacturer }))
      ),
    ];

    setManufacturersList(manufacturers);
  }, [accessories?.data, activeFilters]);

  useEffect(() => {
    const accessoryNames = [
      ...new Set(
        accessories?.data
          .filter(
            ({ deleted, manufacturer }) =>
              !deleted && (!activeFilters.manufacturer || activeFilters.manufacturer.includes(manufacturer))
          )
          .map(({ accessoryName }) => ({ value: accessoryName, label: accessoryName }))
      ),
    ];

    setAccessoryList(accessoryNames);
  }, [accessories?.data, activeFilters]);

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='stock_management.accessories.header.add_accessory'
            onClick={() => dialog?.openDialog(<AddEditStockDialog tableKey={tableKey} selectedType='accessory' />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAccessory',
    },
  ];

  const filters: IFilter[] = [
    {
      key: 'manufacturer',
      label: t('stock_management.accessories.filters.manufacturer'),
      options: manufacturersList,
      mode: 'multiple',
    },
    {
      key: 'accessory',
      label: t('stock_management.accessories.filters.accessory'),
      options: accessoryList,
      mode: 'multiple',
    },
  ];

  return (
    <>
      <SharedPageHeader showBack title={t('stock_management.accessories.title')} actions={headerActions} />
      <SharedFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
      <StockManagementStockTable
        tableKey={tableKey}
        additionalConstraints={[
          where('type', '==', 'accessory'),
          ...(activeFilters?.manufacturer?.length ? [where('manufacturer', 'in', activeFilters.manufacturer)] : []),
          ...(activeFilters?.accessory?.length ? [where('accessoryName', 'in', activeFilters.accessory)] : []),
        ]}
        additionalColumns={[
          {
            labelKey: 'stock_management.add_edit_stock.form.manufacturer',
            key: 'manufacturer',
          },
          {
            labelKey: 'stock_management.add_edit_stock.form.accessory_name',
            key: 'accessoryName',
          },
        ]}
      />
    </>
  );
};

export default StockManagementAccessoriesList;
