import { App } from 'antd';
import { AppointmentsApiService } from 'core/api';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe, where } from 'firebase/firestore';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useRef, useState } from 'react';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import DrawerFilter from 'shared/filter/drawer-filter';
import { IFilter } from 'shared/filter/filter';
import { getAppointment60MinuteTimeSlots } from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

const AppointmentsCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const date = searchParams.get('date');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const dialog = useDialog();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [clinicList, setClinicList] = useState<{ value: string; label: string }[]>([]);
  const [assigneeList, setAssigneeList] = useState<{ value: string; label: string }[]>([]);
  const [filteredResource, setFilteredResource] = useState<IDomainCalendarResource[]>([]);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);

  const filters: IFilter[] = [
    {
      key: 'clinic',
      label: t('calendar.filters.clinic'),
      options: clinicList,
      mode: 'multiple',
    },
    {
      key: 'assignee',
      label: t('calendar.filters.assignee'),
      options: assigneeList,
      mode: 'multiple',
    },
  ];

  const startHour = dayjs(organisationData?.calendar.startTime.toDate()).hour();
  const endHour = dayjs(organisationData?.calendar.endTime.toDate()).hour();

  useEffect(() => {
    if (!date) {
      setSearchParams((prev) => {
        prev.set('date', dayjs().format('YYYY-MM-DD'));
        return prev;
      });
    }
  }, [date, setSearchParams]);

  useEffect(() => {
    const calendarResources = calendarResourcesState?.data || [];
    const clinicsData = clinicsState?.data || [];

    const filteredResources = calendarResources
      .filter(({ uid }) => !activeFilters.assignee || activeFilters.assignee.includes(uid))
      .filter(
        ({ clinics }) => !activeFilters.clinic || clinics.some((clinic) => activeFilters.clinic.includes(clinic))
      );

    const assignees = filteredResources.map(({ uid, fullName }) => ({ value: uid, label: fullName }));

    const assigneeClinics = filteredResources.map(({ clinics }) => clinics).flat();

    const clinics = clinicsData
      .filter(({ deleted }) => !deleted)
      .filter(({ uid }) => assigneeClinics.includes(uid))
      .map(({ uid, name }) => ({ value: uid, label: name }));

    setAssigneeList(assignees);
    setClinicList(clinics);
    setFilteredResource(filteredResources);
  }, [calendarResourcesState?.data, clinicsState?.data, activeFilters]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;

    if ((calendarResourcesState?.data?.length ?? 0) > 0 && date) {
      setLoading(true);
      unsubscribe = AppointmentsApiService.onCollectionSnapshot(
        (snap) => {
          setAppointments(snap.docs.map((doc) => doc.data()));
          setLoading(false);
        },
        (error) => {
          message.error(t('appointments.appointments_calendar.get_appointments_error'));
          sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
        },
        [
          where('organisationUid', '==', userData?.organisationUid),
          where(
            'assignee.uid',
            'in',
            calendarResourcesState?.data.map((resource) => resource.uid)
          ),
          where('startDateTime', '>=', dayjs(date).startOf('day').toDate()),
          where('startDateTime', '<=', dayjs(date).endOf('day').toDate()),
        ]
      );
    } else {
      setLoading(false);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [calendarResourcesState?.data, date, message, t, userData, activeFilters]);

  const paramChanged = (key: string, value: string) => {
    setSearchParams((prev) => {
      if (!value || value === '') {
        prev.delete(key);
      } else {
        prev.set(key, value);
      }

      return prev;
    });
  };

  return (
    <div className='my-4 grow flex flex-col overflow-hidden'>
      <div className='rounded-md bg-white shadow-md mb-4 grow flex overflow-hidden' ref={calendarWrapperRef}>
        <SharedCalendar
          calendarWrapperRef={calendarWrapperRef}
          loading={loading || !date || calendarResourcesState?.status !== 'success'}
          changeDate={(newDate) => paramChanged('date', newDate)}
          currentDate={dayjs(date)}
          timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
          appointments={appointments}
          resources={filteredResource}
          startHour={startHour}
          extra={
            <div className='space-x-2 flex items-center'>
              <DrawerFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
              <SharedElementPermissionGuard
                requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.APPOINTMENTS_CREATE]]}
              >
                <SharedButton
                  appearance='primary'
                  icon={<Plus size={22} />}
                  onClick={() =>
                    dialog?.openDialog(
                      <PatientSearchDialog onSelect={(patient) => navigate(`create?patient=${patient.objectID}`)} />
                    )
                  }
                />
              </SharedElementPermissionGuard>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default AppointmentsCalendar;
