import { App, Input } from 'antd';
import { IUserSearchResult } from 'core/api/types';
import { UsersApiService } from 'core/api';
import { useTheme } from 'core/providers/theme-provider';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedSpinner from 'shared/spinner/spinner';
import _ from 'lodash';

interface ISharedUserSearch {
  excludedUsers?: string[];
  additionalFilters?: ((userResult: IUserSearchResult) => boolean)[];
  onSelect: (selectedUser: IUserSearchResult) => void;
}

const SharedUserSearch = ({ excludedUsers = [], additionalFilters = [], onSelect }: ISharedUserSearch) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<IUserSearchResult[]>();
  const [searching, setSearching] = useState(false);
  const { message } = App.useApp();
  const { primary } = useTheme();

  const performSearch = useCallback(
    async (term: string) => {
      try {
        setResults(undefined);
        setSearching(true);
        const results = await UsersApiService.search(term);
        const filteredResults = results.filter(
          (userResult: IUserSearchResult) =>
            !excludedUsers.includes(userResult.objectID) && additionalFilters.every((truthy) => truthy(userResult))
        );
        setResults(filteredResults);
        setSearching(false);
      } catch (error) {
        message.error(t('field.user_search.error.description'));
        setSearching(false);
      }
    },
    [additionalFilters, excludedUsers, message, t]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedAutocomplete = useCallback(
    _.debounce((term: string) => performSearch(term), 500),
    []
  );

  const onTextChanged = (value: string) => {
    setQuery(value);

    if (value.trim() === '') {
      setResults(undefined);
      debouncedAutocomplete.cancel();
      return;
    }

    setSearching(true);
    debouncedAutocomplete(value);
  };

  const userSelected = (user: IUserSearchResult) => {
    setQuery('');
    setResults(undefined);
    onSelect(user);
  };

  return (
    <div className='relative'>
      <div>
        <Input
          onChange={(event) => onTextChanged(event.currentTarget.value)}
          value={query}
          placeholder={t('field.user_search.placeholder')}
        />
        {searching && (
          <div className='absolute top-[8px] right-[8px]'>
            <SharedSpinner size={16} color={primary.bg} />
          </div>
        )}
      </div>

      {results && (
        <div className='border-gray-300 bg-white border w-full rounded-md shadow-sm mt-1 overflow-y-auto absolute max-h-[360px] z-10'>
          {results.map((result) => (
            <div
              className='body-sm px-1.5 h-[36px] hover:bg-gray-50 transition ease-in-out flex items-center cursor-pointer border-b last:border-0'
              key={result.objectID}
              onClick={() => userSelected(result)}
            >
              <p>
                {result.fullName} <span className='opacity-30 ml-1'>{result.emailAddress}</span>
              </p>
            </div>
          ))}
          {results.length === 0 && (
            <p className='body-sm px-1.5 h-[36px] flex items-center opacity-40'>{t('field.user_search.no_results')}</p>
          )}
        </div>
      )}
    </div>
  );
};

export default SharedUserSearch;
