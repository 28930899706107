import { IUserDao } from 'core/api/types';
import { CollectionID } from 'core/constants/collection-id';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditUserDialog from './add-edit-user-dialog';
import { Permission } from 'core/constants/permission';
import { useTranslation } from 'react-i18next';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { UsersApiService } from 'core/api';
import { useNavigate } from 'react-router-dom';

const UsersTableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'users.users_list.table.header.user',
    key: 'user',
    contentTemplateId: 'user',
    width: 400,
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const UsersList = () => {
  const { userData } = useUserState();
  const { primary } = useTheme();
  const dialog = useDialog();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tableKey = 'users.users_list.table';

  const userTemplate = (user: IUserDao) => {
    return (
      <div className='flex items-center'>
        <div
          className='h-10 w-10 rounded-full mr-3 flex items-center justify-center text-white body-sm'
          style={{ backgroundColor: primary.bg }}
        >
          {user.fullName.charAt(0).toUpperCase()}
        </div>
        <div className='flex flex-col body-sm'>
          <div className='flex items-center'>
            <p>{user.fullName}</p>
            {user.isOrgOwner && (
              <p
                className='py-px px-2 rounded-full body-xs ml-2 border'
                style={{ borderColor: primary.bg, color: primary.bg }}
              >
                Owner
              </p>
            )}
          </div>
          <p className='text-gray-400'>{user.emailAddress}</p>
        </div>
      </div>
    );
  };

  const actionTemplate = (user: IUserDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.USERS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => {
              navigate(`manage-work-area/${user.uid}`);
            }}
            type='button'
            appearance='link'
            labelKey='users.users_list.manage_work_areas'
            primaryOverride
          />
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditUserDialog uid={user.uid} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        {!user.isOrgOwner && (
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.USERS_DELETE], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              onClick={() =>
                dialog?.openDialog(
                  <ConfirmActionDialog
                    action={() => UsersApiService.remove(user)}
                    tableRefreshKeys={[tableKey]}
                    actionButtonProps={{
                      labelKey: 'common.delete',
                      danger: true,
                    }}
                    title={t('dialog.delete_user.title', { userName: user.fullName })}
                    textContent={t('dialog.delete_user.content')}
                    successMessage={t('dialog.delete_user.success.description')}
                    errorMessage={t('dialog.delete_user.error.description')}
                  />
                )
              }
              type='button'
              appearance='link'
              labelKey='common.delete'
              danger
            />
          </SharedElementPermissionGuard>
        )}
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: userTemplate,
      id: 'user',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.USERS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='users.users_list.header.add_user'
            onClick={() => dialog?.openDialog(<AddEditUserDialog tableKey={tableKey} />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addUser',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('common.users')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.USERS}
            queryConstraints={[where('organisationUid', '==', userData?.organisationUid)]}
            queryOrder={['created.at', 'desc']}
            tableConfig={{
              columns: UsersTableColumns,
              contentTemplates,
            }}
            errorMessageKey='users.users_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default UsersList;
