import { UsersApiService } from 'core/api';
import { auth } from 'core/config/firebase';
import { useDialog } from 'core/providers/dialog-provider';
import { signInWithEmailAndPassword, updatePassword } from 'firebase/auth';
import { useState, useEffect } from 'react';
import SharedDialogBase from './dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useTranslation } from 'react-i18next';
import { assertIsError } from 'shared/helpers/asset-is-error';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';

const SetPasswordFormFields: ISharedField[] = [
  {
    fieldKey: 'currentPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Current Password',
    required: true,
  },
  {
    fieldKey: 'newPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'New Password',
    required: true,
  },
  {
    fieldKey: 'confirmNewPassword',
    control: ControlType.TextField,
    type: InputType.Password,
    label: 'Confirm New Password',
    required: true,
  },
];

const SetPasswordDialog = () => {
  const [formError, setFormError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { userData, user } = useUserState();
  const { t } = useTranslation();

  useEffect(() => {
    if (userData?.resetPassword === false) {
      dialog?.closeDialog();
    }
  }, [dialog, userData?.resetPassword]);

  const onFormSubmit = async (data: any) => {
    try {
      setLoading(true);

      if (!userData || !user) {
        throw new Error(t('dialog.set_password.error.generic'));
      }

      if (data.newPassword !== data.confirmNewPassword) {
        throw new Error(t('dialog.set_password.error.passwords_mismatch'));
      }

      try {
        await signInWithEmailAndPassword(auth, userData.emailAddress, data.currentPassword);
      } catch (error) {
        throw new Error(t('auth.login.incorrect_email_password'));
      }

      try {
        await updatePassword(user, data.newPassword);
        await UsersApiService.update(user.uid, {
          resetPassword: false,
          updated: getActionTimestampFromUser(userData),
        });
      } catch (error) {
        throw new Error(t('dialog.set_password.error.generic'));
      }
    } catch (error) {
      setLoading(false);
      const assertedError = assertIsError(error);
      setFormError(assertedError.message);
    }
  };

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p className='body-sm mb-4'>{t('dialog.set_password.instruction')}</p>
        <SharedForm
          fields={SetPasswordFormFields}
          formError={formError}
          onFinish={onFormSubmit}
          submitting={loading}
          name='set-password-form'
          className='p-0'
        />
      </div>
    );
  };

  return <SharedDialogBase title='Set your password' customContentTemplate={customContent()} showButtons={false} />;
};

export default SetPasswordDialog;
