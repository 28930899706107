import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';

const ProductsAndServicesMenu = () => {
  const { t } = useTranslation();

  const settingsOptions = [
    {
      key: 'hearingAids',
      label: t('products_and_services.hearing_aids'),
      route: 'hearing-aids',
    },
    {
      key: 'accessories',
      label: t('products_and_services.accessories'),
      route: 'accessories',
    },
    {
      key: 'services',
      label: t('products_and_services.services'),
      route: 'services',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.products_and_services')} />
      <SharedCard>
        {settingsOptions.map((option) => (
          <div className='flex justify-between items-center border-b p-3 body-sm' key={option.key}>
            <p>{option.label}</p>
            <Link to={option.route}>
              <SharedButton type='button' appearance='link' labelKey='common.view' primaryOverride />
            </Link>
          </div>
        ))}
      </SharedCard>
    </>
  );
};

export default ProductsAndServicesMenu;
