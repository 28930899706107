import clsx from 'clsx';
import { INavbarOption } from 'core/config/navbar-options';
import { useTheme } from 'core/providers/theme-provider';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SharedSpinner from 'shared/spinner/spinner';
import PatientInformationCard from './patient-information-card/patient-information-card';
import { IPatientDao } from 'core/api/types';
import { ArrowLeftCircle } from 'react-feather';
import { usePermissionsState } from 'core/providers/permissions-provider';

interface IPatientLayout {
  loading: boolean;
  navList: INavbarOption[];
  patient: IPatientDao;
}

const PatientLayout = ({ loading, navList, patient }: IPatientLayout) => {
  const { primary } = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const isNavItemActive = (route: string) => location.pathname.startsWith(`/${route}`);
  const navigate = useNavigate();
  const { userPermissions } = usePermissionsState();

  return (
    <div className='grow flex flex-col overflow-hidden'>
      <div className='basis-[50px] md:basis-[64px] grow-0 flex-shrink-0 border-b bg-white flex items-center overflow-x-auto space-x-4 px-4'>
        {navList
          .filter((item) =>
            item.requiredPermissions.some((permissions) =>
              permissions.every((permission) => userPermissions?.includes(permission))
            )
          )
          .map(({ route, labelKey }) => (
            <Link key={route} to={`/${route}`} replace>
              <div
                className={clsx(
                  'button-xs rounded-md px-3 py-2 transition ease-in-out whitespace-nowrap',
                  isNavItemActive(route) ? 'text-white' : `hover:bg-gray-100 hover:text-gray-800 text-gray-800`
                )}
                style={{
                  backgroundColor: isNavItemActive(route) ? primary.bg : '',
                }}
              >
                <p>{t(labelKey)}</p>
              </div>
            </Link>
          ))}
      </div>
      {loading ? (
        <div className='grow flex items-center justify-center'>
          <SharedSpinner size={24} color={primary.bg} />
        </div>
      ) : (
        <div className='grow overflow-y-auto p-4'>
          <div
            className='flex items-center text-white-600 mb-2 cursor-pointer text-gray-600 w-fit'
            onClick={() => navigate(-1)}
          >
            <ArrowLeftCircle size={16} />
            <p className='body-sm ml-1'>{t('common.back')}</p>
          </div>
          <PatientInformationCard {...patient} />
          <Outlet />
        </div>
      )}
    </div>
  );
};

export default PatientLayout;
