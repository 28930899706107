import { Empty } from 'antd';
import { Slash } from 'react-feather';
import { useTranslation } from 'react-i18next';

const NoPermissions = () => {
  const { t } = useTranslation();
  return (
    <div className='h-full flex flex-col items-center justify-center'>
      <Empty
        image={<Slash size={80} className='text-gray-400' />}
        description={
          <div>
            <h1>{t('no_permissions.title')}</h1>
            <p>{t('no_permissions.description')}</p>
          </div>
        }
      />
    </div>
  );
};

export default NoPermissions;
