import clsx from 'clsx';
import { IActionTimestamp } from 'core/api/types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

interface ILastUpdated {
  updated: IActionTimestamp;
  outerClassName?: string;
}

const LastUpdated = ({ updated, outerClassName }: ILastUpdated) => {
  const { t } = useTranslation();
  const outer = clsx(outerClassName, 'body-xs text-gray-400');

  return (
    <p className={outer}>
      {t('common.last_updated', {
        at: dayjs(updated.at.toDate()).format('DD/MM/YYYY, HH:mm'),
        by: updated.by.fullName,
      })}
    </p>
  );
};

export default LastUpdated;
