import clsx from 'clsx';
import { IPatientDao } from 'core/api/types';
import { ContactMethodData, ContactMethodOptions } from 'core/constants/contact-method';
import { GenderData } from 'core/constants/gender';
import { MaritalStatusData } from 'core/constants/marital-status';
import { Permission } from 'core/constants/permission';
import { TitleData } from 'core/constants/title';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { CheckCircleTwoTone, CloseCircleTwoTone, StarTwoTone, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps, message, Tooltip } from 'antd';
import { useTheme } from 'core/providers/theme-provider';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { useUserState } from 'core/providers/user-provider';
import { PatientApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useDialog } from 'core/providers/dialog-provider';
import InfoRow from 'shared/data-display/info-row';
import LastUpdated from 'shared/data-display/last-updated';
import AddEditPatientDialog from '../../add-edit-patient-dialog';
import VirtualServicingIndicator from './virtual-servicing-indicator';
import CreatedBy from 'shared/data-display/created-by';

interface IPatientInformationCardDataSectionFieldValue {
  key: string;
  label: string;
  value: any;
}

interface IPatientInformationCardDataSection {
  key: string;
  fields: IPatientInformationCardDataSectionFieldValue[];
}

const PatientInformationCard = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const patientTitle = TitleData[patient.title];
  const dialog = useDialog();
  const leadTypes = useSelector(OrganisationSettingsSlice.selectLeadTypes);
  const patientFormSettings = useSelector(OrganisationSettingsSlice.selectPatientFormSettings);
  const workflowStatuses = useSelector(OrganisationSettingsSlice.selectPatientWorkflowStatuses);
  const fieldSettings = patientFormSettings?.data;
  const [showDetail, setShowDetail] = useState(false);
  const isFieldDisabled = (fieldKey: string) => fieldSettings?.disabledFields.includes(fieldKey);
  const customFieldData =
    fieldSettings?.customFields
      .filter(({ key }) => !isFieldDisabled(key))
      .map(({ key, label }) => {
        const fieldData = patient.customFields?.find((f) => f.key === key);
        return {
          key,
          label,
          value: fieldData?.value,
        };
      }) ?? [];
  const { primary } = useTheme();
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const { userData } = useUserState();

  const dataSections: IPatientInformationCardDataSection[] = [
    {
      key: 'contact',
      fields: [
        {
          key: 'phone',
          label: t('form_settings.patient_form.primary_phone_number'),
          value: patient.phoneNumber,
        },
        {
          key: 'secondaryPhone',
          label: t('form_settings.patient_form.secondary_phone_number'),
          value: patient.secondaryPhoneNumber,
        },
        {
          key: 'emailAddress',
          label: t('form_settings.patient_form.email_address'),
          value: patient.emailAddress,
        },
        {
          key: 'emergencyContactName',
          label: t('form_settings.patient_form.emergency_contact_name'),
          value: patient.emergencyContactName,
        },
        {
          key: 'emergencyContactNumber',
          label: t('form_settings.patient_form.emergency_contact_number'),
          value: patient.emergencyContactNumber,
        },
      ],
    },
    {
      key: 'demographics',
      fields: [
        {
          key: 'referral',
          label: t('form_settings.patient_form.referral'),
          value: leadTypes?.data.find((type) => type.uid === patient.referral)?.name,
        },
        {
          key: 'dob',
          label: t('form_settings.patient_form.dob'),
          value: patient.dob ? dayjs(patient.dob.toDate()).format('DD/MM/YYYY') : undefined,
        },
        {
          key: 'gender',
          label: t('form_settings.patient_form.gender'),
          value: patient.gender && t(GenderData[patient.gender].translationLabelKey),
        },
        {
          key: 'maritalStatus',
          label: t('form_settings.patient_form.marital_status'),
          value: patient.maritalStatus && t(MaritalStatusData[patient.maritalStatus].translationLabelKey),
        },
        {
          key: 'gpDetails',
          label: t('form_settings.patient_form.gp_details'),
          value: patient.gpDetails,
        },
      ],
    },
    ...(customFieldData.length > 0
      ? [
          {
            key: 'custom',
            fields: customFieldData,
          },
        ]
      : []),
  ];

  const currentStatus = workflowStatuses?.find((status) => status.key === patient.status.status);
  const updateStatusItems =
    currentStatus?.canTransitionTo === 'all'
      ? workflowStatuses?.map((status) => ({ label: status.name, key: status.key }))
      : currentStatus?.canTransitionTo.map((key) => {
          const status = workflowStatuses?.find((status) => status.key === key);
          return {
            label: status?.name,
            key,
          };
        });

  const updateStatus: MenuProps['onClick'] = async ({ key }) => {
    try {
      setUpdatingStatus(true);
      const timestamp = getActionTimestampFromUser(userData);
      await PatientApiService.update(patient.uid, {
        updated: timestamp,
        status: { status: key, updated: timestamp },
      });
      setUpdatingStatus(false);
      message.success(t('patients.patient.information.update_status.success'));
    } catch (error) {
      setUpdatingStatus(false);
      sentryCaptureException(error, 'Update patient status', userData);
      message.error(t('patients.patient.information.update_status.error'));
    }
  };

  return (
    <SharedCard innerClassName='p-4'>
      <div className='flex flex-col md:flex-row md:justify-between md:items-start'>
        <div>
          <p className='text-2xl'>
            {patientTitle && `${t(patientTitle.translationLabelKey)} `}
            {patient.fullName}
          </p>
          <p className='text-gray-500'>
            {patient.address ? patient.address.formattedAddress : t('patients.patient.information.address_not_defined')}
          </p>
          <div className='flex space-x-2 mt-3'>
            {ContactMethodOptions.map((c) => {
              const contactMethodData = ContactMethodData[c];
              const permissionAllowed = patient.contactPermissions?.includes(c);

              return (
                <div
                  className={clsx(
                    'px-1.5 py-1 border rounded-md body-xs flex space-x-1 items-center',
                    permissionAllowed
                      ? 'bg-green-100 border-green-600 text-green-600'
                      : 'bg-red-50 border-red-300 text-red-500'
                  )}
                  key={contactMethodData.value}
                >
                  <p>{t(contactMethodData.translationLabelKey)}</p>

                  {permissionAllowed ? (
                    <CheckCircleTwoTone twoToneColor='#22c55e' />
                  ) : (
                    <CloseCircleTwoTone twoToneColor='#ef4444' />
                  )}

                  {patient.contactPreference === c && <StarTwoTone twoToneColor='#eab308' />}
                </div>
              );
            })}
          </div>
        </div>
        <div className='flex-col flex md:flex-row md:space-x-2'>
          <VirtualServicingIndicator {...patient} />
          <div className='flex border rounded-md shadow-sm w-fit mt-4 md:mt-0 body-sm items-center'>
            <Tooltip
              title={t('patients.patient.information.status_last_updated', {
                at: dayjs(patient.status.updated.at.toDate()).format('DD/MM/YYYY, HH:mm'),
                by: patient.status.updated.by.fullName,
              })}
            >
              <p className='py-1 px-4 border-r'>{currentStatus?.name}</p>
            </Tooltip>
            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_UPDATE]]}
            >
              <Dropdown menu={{ items: updateStatusItems, onClick: updateStatus }} trigger={['click']}>
                <Button
                  style={{ color: primary.bg }}
                  type='link'
                  icon={<DownOutlined />}
                  iconPosition='end'
                  loading={updatingStatus}
                >
                  {t('common.update')}
                </Button>
              </Dropdown>
            </SharedElementPermissionGuard>
          </div>
        </div>
      </div>
      <div className='mt-6'>
        <div className={clsx('flex justify-between items-center', showDetail && 'mb-4 pb-px border-b')}>
          <div className='flex items-center space-x-4'>
            <p className='text-gray-500'>{t('patients.patient.information.details_title')}</p>
            <SharedButton
              className='body-xs'
              labelKey={showDetail ? 'common.hide' : 'common.show'}
              appearance='link'
              primaryOverride
              onClick={() => setShowDetail(!showDetail)}
            />
          </div>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENTS_UPDATE]]}
          >
            <SharedButton
              labelKey='patients.patient.information.edit_details'
              appearance='link'
              primaryOverride
              onClick={() => dialog?.openDialog(<AddEditPatientDialog patient={patient} />)}
            />
          </SharedElementPermissionGuard>
        </div>
        {showDetail && (
          <>
            <div
              className={clsx('grid grid-cols-1 gap-6', dataSections.length > 2 ? 'md:grid-cols-3' : 'md:grid-cols-2')}
            >
              {dataSections.map((section) => (
                <div
                  key={section.key}
                  className='border-b pb-6 md:pb-0 last:pb-0 last:border-b-0 md:border-b-0 md:border-r md:pr-6 md:last:border-r-0 md:last:pr-0'
                >
                  {section.fields
                    .filter(({ key }) => !isFieldDisabled(key))
                    .map((field) => (
                      <InfoRow key={field.key} value={field.value} label={field.label} />
                    ))}
                </div>
              ))}
            </div>
            <div className='grid grid-cols-1 gap-2 md:grid-cols-2 mt-4'>
              <CreatedBy created={patient.created} />
              <LastUpdated updated={patient.updated} outerClassName='md:text-right' />
            </div>
          </>
        )}
      </div>
    </SharedCard>
  );
};

export default PatientInformationCard;
