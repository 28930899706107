import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { App } from 'antd';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { UploadTypesApiService } from 'core/api';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { v4 as uuidv4 } from 'uuid';
import { IUploadTypeDao } from 'core/api/types';

interface IAddEditUploadTypeOutput {
  name: string;
}
interface IAddEditUploadTypeDialog {
  uploadType?: IUploadTypeDao;
}

const AddEditUploadTypeDialog = ({ uploadType }: IAddEditUploadTypeDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();

  const FormFields: ISharedField[] = [
    {
      fieldKey: 'name',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
  ];

  const submit = async (data: IAddEditUploadTypeOutput) => {
    setSubmitting(true);

    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        updated: userTimestamp,
        ...data,
      };

      if (!uploadType) {
        await UploadTypesApiService.set({
          uid: uuidv4(),
          created: userTimestamp,
          organisationUid: userData.organisationUid,
          ...basePayload,
        });
        message.success(t('dialog.add_upload_type.create.success.description'));
      } else {
        await UploadTypesApiService.update(uploadType.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_upload_type.edit.success.description'));
      }
      dialog?.closeDialog();
    } catch (error) {
      message.error(
        t(
          !uploadType
            ? 'dialog.add_upload_type.create.error.description'
            : 'dialog.add_upload_type.edit.error.description'
        )
      );
      setSubmitting(false);
      sentryCaptureException(error, !uploadType ? 'Create upload type' : 'Edit upload type', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditUploadTypeOutput>
        onFinish={submit}
        fields={FormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        existingValue={uploadType}
        name='add-edit-upload-type-dialog'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(!uploadType ? 'dialog.add_upload_type.create.title' : 'dialog.add_upload_type.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditUploadTypeDialog;
