import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentTypesList from './appointment-types-list';
import AppointmentTypeDetail from './appointment-type-detail/appointment-type-detail';

const AppointmentTypesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AppointmentTypesList />} />
      <Route path=':uid' element={<AppointmentTypeDetail />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AppointmentTypesRouting;
