import { Permission } from 'core/constants/permission';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { Alert, Tag } from 'antd';
import SharedCard from 'shared/card/card';
import { ArrowRight } from 'react-feather';
import { useTheme } from 'core/providers/theme-provider';

const Workflow = () => {
  const { t } = useTranslation();
  const workflow = useSelector(OrganisationSettingsSlice.selectPatientWorkflow);
  const errorState = !workflow || !workflow.data || workflow.status !== 'success';
  const workflowStatuses = workflow?.data?.statuses ?? [];
  const { primary } = useTheme();

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.WORKFLOW_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <Link to='edit'>
            <SharedButton labelKey='workflow.header.edit_workflow' appearance='primary' disabled={errorState} />
          </Link>
        </SharedElementPermissionGuard>
      ),
      key: 'editWorkflow',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.workflow')} actions={headerActions} />
      <div>
        {errorState ? (
          <Alert message={t('workflow.error_loading_data')} type='error' showIcon />
        ) : (
          <SharedCard>
            <div className='p-4'>
              {workflowStatuses.length === 0 && <p className='text-gray-400'>{t('workflow.no_statuses')}</p>}

              {workflowStatuses.map((status) => (
                <div
                  key={status.key}
                  className='grid grid-cols-7 items-center gap-2 border-t py-6 first:border-0 first:pt-2 last:pb-2'
                >
                  <div className='col-span-3 group flex items-center justify-between'>
                    <Tag color={primary.bg}>{status.name}</Tag>
                  </div>

                  <ArrowRight className='col-span-1 text-gray-400' size={20} />
                  <div className='col-span-3 body-xs'>
                    {status.canTransitionTo === 'all' ? (
                      <Tag color='blue'>{t('workflow.all_statuses')}</Tag>
                    ) : (
                      <div className='flex flex-col space-y-2 items-start'>
                        {status.canTransitionTo.map((key) => {
                          const match = workflowStatuses.find((s) => s.key === key);
                          return <Tag key={key}>{match?.name}</Tag>;
                        })}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </SharedCard>
        )}
      </div>
    </>
  );
};

export default Workflow;
