import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { IOrderAccessoryOrService } from './add-edit-order';
import { useDialog } from 'core/providers/dialog-provider';
import { IAccessoryDao, IServiceDao } from 'core/api/types';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';

interface IAddEditOrderAddAccessoryServiceDialog {
  add: (data: IOrderAccessoryOrService) => void;
}

const AddEditOrderAddAccessoryServiceDialog = ({ add }: IAddEditOrderAddAccessoryServiceDialog) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const accessoryState = useSelector(OrganisationSettingsSlice.selectAccessories);
  const servicesState = useSelector(OrganisationSettingsSlice.selectServices);
  const [form] = Form.useForm();
  const type = useWatch('type', form);
  const selectedManufacturer = useWatch('manufacturer', form);
  const [availableAccessories, setAvailableAccessories] = useState<string[]>([]);
  const selectedAccessory = useWatch('accessoryName', form);
  const [matchedAccessory, setMatchedAccessory] = useState<IDomainOrganisationDataType<IAccessoryDao>>();
  const manufacturersList = [
    ...new Set(accessoryState?.data.filter((accessory) => !accessory.deleted).map((acc) => acc.manufacturer)),
  ];
  const selectedService = useWatch('serviceName', form);
  const [matchedService, setMatchedService] = useState<IDomainOrganisationDataType<IServiceDao>>();

  const fields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      options: [
        {
          value: 'accessory',
          label: t('orders.add_edit_order.add_accessory_service.form.accessory'),
        },
        {
          value: 'service',
          label: t('orders.add_edit_order.add_accessory_service.form.service'),
        },
      ],
      label: t('orders.add_edit_order.add_accessory_service.form.type'),
      required: true,
    },
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: manufacturersList.map((manufacturer) => ({ label: manufacturer, value: manufacturer })),
      label: t('orders.add_edit_order.add_accessory_service.form.manufacturer'),
      required: true,
      hidden: type !== 'accessory',
    },
    {
      fieldKey: 'accessoryName',
      control: ControlType.Select,
      options: availableAccessories.map((acc) => ({ label: acc, value: acc })),
      label: t('orders.add_edit_order.add_accessory_service.form.accessory'),
      required: true,
      hidden: !selectedManufacturer,
    },
    {
      fieldKey: 'serviceName',
      control: ControlType.Select,
      options: servicesState?.data.map((service) => ({ label: service.serviceName, value: service.serviceName })) ?? [],
      label: t('orders.add_edit_order.add_accessory_service.form.service'),
      required: true,
      hidden: type !== 'service',
    },
    {
      fieldKey: 'price',
      control: ControlType.NumberField,
      label: t('common.price'),
      required: true,
      hidden: !type || (type === 'accessory' && !matchedAccessory) || (type === 'service' && !matchedService),
      fullWidth: true,
      min: 0,
    },
  ];

  const resetFields = useCallback(
    (fields: string[]) => {
      fields.forEach((field) => form.setFieldsValue({ [field]: undefined }));
    },
    [form]
  );

  useEffect(() => {
    if (selectedManufacturer) {
      const accessories = [
        ...new Set(
          accessoryState?.data
            .filter((acc) => !acc.deleted && acc.manufacturer === selectedManufacturer)
            .map((acc) => acc.accessoryName)
        ),
      ];
      setAvailableAccessories(accessories);
      if (selectedAccessory && !accessories.includes(selectedAccessory)) {
        resetFields(['accessoryName']);
      }
    }
  }, [accessoryState?.data, form, resetFields, selectedAccessory, selectedManufacturer]);

  useEffect(() => {
    if (selectedAccessory) {
      const matchedAccessory = accessoryState?.data.find(
        (acc) => acc.manufacturer === selectedManufacturer && acc.accessoryName === selectedAccessory
      );
      setMatchedAccessory(matchedAccessory);
    }
  }, [accessoryState?.data, resetFields, selectedAccessory, selectedManufacturer]);

  useEffect(() => {
    if (selectedService) {
      const matchedService = servicesState?.data.find((service) => service.serviceName === selectedService);
      setMatchedService(matchedService);
    }
  }, [selectedService, servicesState?.data]);

  useEffect(() => {
    if (matchedAccessory) {
      form.setFieldsValue({
        price: matchedAccessory.rrp,
      });
    }
  }, [form, matchedAccessory]);

  useEffect(() => {
    if (matchedService) {
      form.setFieldsValue({
        price: matchedService.rrp,
      });
    }
  }, [form, matchedService]);

  const customContent = () => {
    return (
      <SharedForm<IOrderAccessoryOrService>
        formInstance={form}
        onFinish={(data) => {
          add({
            itemKey: data.type === 'accessory' ? matchedAccessory?.uid! : matchedService?.uid!,
            type: data.type,
            name: data.type === 'accessory' ? matchedAccessory?.accessoryName! : matchedService?.serviceName!,
            price: data.price,
            ...(selectedManufacturer && { manufacturer: selectedManufacturer }),
          });
          dialog?.closeDialog();
        }}
        fields={fields}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add_accessory_service-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('order.add_edit_order.add_accessory_service')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditOrderAddAccessoryServiceDialog;
