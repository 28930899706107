import { AppointmentLocation } from 'core/constants/appointment-location';
import { useCallback, useEffect, useState } from 'react';
import { IAddressDao } from 'shared/interfaces/address.interface';
import SharedDialogBase from './dialog-base';
import { useTranslation } from 'react-i18next';
import { IAppointmentTypeDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  IDomainCalendarResource,
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { App } from 'antd';
import { useUserState } from 'core/providers/user-provider';
import { useSelector } from 'react-redux';
import { Timestamp } from 'firebase/firestore';
import RouteMap from 'shared/route-map/route-map';
import { getTimeStringFromTimestamp } from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface ITimelinePreviewDialogAppointment {
  isNew: boolean;
  appointmentAddress: IAddressDao;
  patientName: string;
  uid: string;
  appointmentType?: IDomainOrganisationDataType<IAppointmentTypeDao>;
  startDateTime: Timestamp;
  endDateTime: Timestamp;
  clinic: string;
  location: AppointmentLocation;
}

interface ITimelinePreviewDialog {
  appointments: IAppointmentDao[];
  resource: IDomainCalendarResource;
  newAppointment?: ITimelinePreviewDialogAppointment;
}

interface ITimelineWaypoint {
  isHome: boolean;
  appointmentDetail?: ITimelinePreviewDialogAppointment;
  nextJourney?: google.maps.DirectionsLeg;
}

const TimelinePreviewDialog = ({ appointments, resource, newAppointment }: ITimelinePreviewDialog) => {
  const [loading, setLoading] = useState(true);
  const [timeline, setTimeline] = useState<ITimelineWaypoint[]>();
  const [directionResult, setDirectionResult] = useState<google.maps.DirectionsResult>();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const [timelineAppointments, setTimelineAppointments] = useState<ITimelinePreviewDialogAppointment[]>([]);
  const [totalMilage, setTotalMileage] = useState('0');
  const { userData } = useUserState();

  const generateGoogleDirections = useCallback(
    async (appointments: ITimelinePreviewDialogAppointment[], rootAddress: google.maps.LatLngLiteral) => {
      try {
        const directionsService = new google.maps.DirectionsService();
        const result = await directionsService.route({
          origin: rootAddress,
          destination: rootAddress,
          waypoints: appointments
            .sort(
              (a, b) =>
                a.startDateTime.seconds - b.startDateTime.seconds || a.endDateTime.seconds - b.endDateTime.seconds
            )
            .map((app) => ({
              location: {
                lat: app.appointmentAddress.lat,
                lng: app.appointmentAddress.lng,
              },
            })),
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.IMPERIAL,
        });
        setDirectionResult(result);
        const legs = result.routes[0].legs;
        let totalMeters = 0;
        legs.forEach((leg) => (totalMeters += leg.distance?.value ?? 0));
        setTotalMileage(`${(totalMeters * 0.000621371).toFixed(2)} mi`);

        setTimeline([
          { isHome: true, nextJourney: legs[0] },
          ...appointments.map((app, index) => ({
            isHome: false,
            appointmentDetail: app,
            nextJourney: legs[index + 1],
          })),
          { isHome: true },
        ]);
        setLoading(false);
      } catch (error) {
        message.error('Route generation failed, please try again');
        sentryCaptureException(error, 'Failed to generate route', userData);
      }
    },
    [message, userData]
  );

  useEffect(() => {
    if (resource.homeAddress) {
      const appointmentsToRender: ITimelinePreviewDialogAppointment[] = [
        ...appointments
          .filter((app) => {
            return (
              app.patient.address &&
              !app.cancelled &&
              app.assignee.uid === resource.uid &&
              app.uid !== newAppointment?.uid
            );
          })
          .map((app) => {
            const appointmentType = appointmentTypeState?.data.find((type) => type.uid === app.type);
            let address = app.patient.address!;
            if (app.location === AppointmentLocation.CLINIC) {
              const clinic = clinicsState?.data.find((clinic) => clinic.uid === app.clinic);
              address = clinic!.address;
            }

            return {
              isNew: false,
              appointmentAddress: address,
              patientName: app.patient.fullName,
              ...app,
              appointmentType: appointmentType,
            };
          }),
      ];
      if (newAppointment) {
        appointmentsToRender.push(newAppointment);
      }
      setTimelineAppointments(appointmentsToRender);
      generateGoogleDirections(appointmentsToRender, resource.homeAddress);
    } else {
      message.error('The selected resource does not have a home address, please set one in user settings');
    }
  }, [
    appointmentTypeState?.data,
    appointments,
    clinicsState?.data,
    generateGoogleDirections,
    message,
    newAppointment,
    resource.homeAddress,
    resource.uid,
  ]);

  const getBackgroundColour = (app?: ITimelinePreviewDialogAppointment) => {
    if (app?.isNew) {
      return '#1d4ed8';
    }

    if (!app || !app.appointmentType) {
      return '#374151';
    }

    return app.appointmentType.colour;
  };

  const customContent = () => {
    return loading ? (
      <p className='p-4'>Loading timeline...</p>
    ) : (
      <div className='overflow-y-auto'>
        <RouteMap
          directions={{
            result: directionResult,
            pins: timelineAppointments.map((appointment, index) => ({
              position: {
                lat: appointment.appointmentAddress.lat,
                lng: appointment.appointmentAddress.lng,
              },
              key: appointment.uid,
              labelText: (index + 1).toString(),
            })),
          }}
        />
        <div className='p-4'>
          <div className='pb-4 body-sm opacity-80'>{`Total travel distance is ${totalMilage}`}</div>
          <div className='relative'>
            <div className='absolute w-[6px] ml-[12px] h-full rounded-full bg-blue-50' />
            {timeline?.map((stop, index) => (
              <div key={index}>
                <div className='flex items-center'>
                  <div
                    className='h-[30px] grow-0 flex-shrink-0 basis-[30px] rounded-full z-20 flex items-center justify-center text-white font-semibold'
                    style={{ backgroundColor: getBackgroundColour(stop.appointmentDetail) }}
                  >
                    {!stop.isHome && <p>{index}</p>}
                  </div>
                  <div
                    className='ml-4 w-full rounded-md p-2 text-white'
                    style={{ backgroundColor: getBackgroundColour(stop.appointmentDetail) }}
                  >
                    {stop.isHome && <p>Home</p>}
                    {stop.appointmentDetail && (
                      <div className='body-sm'>
                        <div className='flex justify-between'>
                          <p className='font-semibold'>{stop.appointmentDetail.patientName}</p>
                          <p className='opacity-80'>
                            {stop.appointmentDetail.isNew ? 'New' : stop.appointmentDetail.appointmentType?.name}
                          </p>
                        </div>
                        <p className='body-03'>
                          {getTimeStringFromTimestamp(stop.appointmentDetail.startDateTime)} -{' '}
                          {getTimeStringFromTimestamp(stop.appointmentDetail.endDateTime)}
                        </p>
                        <p>{stop.appointmentDetail.appointmentAddress.formattedAddress}</p>
                      </div>
                    )}
                  </div>
                </div>
                {stop.nextJourney && (
                  <div className='ml-12 py-4 body-02 opacity-80'>
                    {`Travel is ${stop.nextJourney.distance?.text} and takes ${stop.nextJourney.duration?.text}`}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return <SharedDialogBase title={t('dialog.timeline_preview_dialog.title')} customContentTemplate={customContent()} />;
};

export default TimelinePreviewDialog;
