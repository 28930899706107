import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditAppointmentTypeDialog from './add-edit-appointment-type-dialog';
import { IAppointmentTypeDao } from 'core/api/types';
import { useNavigate } from 'react-router-dom';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.name',
    key: 'name',
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.colour',
    key: 'colour',
    contentTemplateId: 'colour',
    width: 150,
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.duration',
    key: 'duration',
    width: 120,
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.salesOpportunity',
    key: 'salesOpportunity',
    contentTemplateId: 'salesOpportunity',
    width: 120,
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.reminders',
    key: 'reminders',
    contentTemplateId: 'reminders',
    width: 120,
  },
  {
    labelKey: 'appointment_types.appointment_types_list.table.header.confirmation',
    key: 'confirmation',
    contentTemplateId: 'confirmation',
    width: 100,
  },
  { key: 'action', contentTemplateId: 'actions', width: 100 },
];

const AppointmentTypesList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'appointment_types.appointment_types_list.table';
  const navigate = useNavigate();

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='appointment_types.appointment_types_list.header.add_appointment_type'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditAppointmentTypeDialog />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAppointmentType',
    },
  ];

  const appTypeColourTemplate = (appointmentType: IAppointmentTypeDao) => {
    return (
      <div className='flex space-x-2 items-center'>
        <div className='h-4 w-4 rounded-full' style={{ backgroundColor: appointmentType.colour }} />
        <p>{appointmentType.colour}</p>
      </div>
    );
  };

  const salesOpportunityTemplate = (appointmentType: IAppointmentTypeDao) => {
    return <p>{t(appointmentType.salesOpportunity ? 'common.yes' : 'common.no')}</p>;
  };

  const remindersTemplate = (appointmentType: IAppointmentTypeDao) => {
    return <p>{appointmentType.reminders.length}</p>;
  };

  const confirmationTemplate = (appointmentType: IAppointmentTypeDao) => {
    return (
      <p>
        {t(
          appointmentType.confirmation.email.enabled || appointmentType.confirmation.sms.enabled
            ? 'common.yes'
            : 'common.no'
        )}
      </p>
    );
  };

  const actionTemplate = (appointmentType: IAppointmentTypeDao) => {
    return (
      <div className='w-full flex justify-end'>
        <SharedButton
          onClick={() => navigate(appointmentType.uid)}
          type='button'
          appearance='link'
          labelKey='common.view'
          primaryOverride
        />
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: appTypeColourTemplate,
      id: 'colour',
    },
    {
      template: actionTemplate,
      id: 'actions',
    },
    {
      template: salesOpportunityTemplate,
      id: 'salesOpportunity',
    },
    {
      template: remindersTemplate,
      id: 'reminders',
    },
    {
      template: confirmationTemplate,
      id: 'confirmation',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.appointment_types')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.APPOINTMENT_TYPES}
            queryConstraints={[
              where('organisationUid', '==', userData?.organisationUid),
              where('deleted', '==', false),
            ]}
            queryOrder={['updated.at', 'desc']}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='appointment_types.appointment_types_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default AppointmentTypesList;
