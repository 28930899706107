import { IPatientDao, IPatientDocumentDao } from 'core/api/types';
import SharedCard from 'shared/card/card';
import { useCallback, useEffect, useState } from 'react';
import SharedButton from 'shared/button/button';
import { App } from 'antd';
import { useDialog } from 'core/providers/dialog-provider';
import { FirestoreError, orderBy, QuerySnapshot, where } from 'firebase/firestore';
import { PatientDocumentsApiService } from 'core/api';
import { useTranslation } from 'react-i18next';
import { Unsubscribe } from 'firebase/auth';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import AddPatientDocumentDialog from './add-patient-document-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import CreatedBy from 'shared/data-display/created-by';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { storage } from 'core/config/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientDocuments = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const dialog = useDialog();
  const { userData } = useUserState();
  const [loading, setLoading] = useState(true);

  const uploadTypes = useSelector(OrganisationSettingsSlice.selectUploadTypes);

  const [patientDocuments, setPatientDocuments] = useState<IPatientDocumentDao[]>([]);

  const handleSnapshot = useCallback((snap: QuerySnapshot<IPatientDocumentDao>) => {
    const documents = snap.docs.map((doc) => doc.data()) ?? [];
    setPatientDocuments(documents);
    setLoading(false);
  }, []);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError) => {
      message.error(t('patients.patient.patient_documents.error.description'));
      sentryCaptureException(error, 'Fetching Patient Documents', userData);
      setLoading(false);
    },
    [message, t, userData]
  );

  useEffect(() => {
    if (!userData?.organisationUid) {
      throw new Error(t('auth.user.error'));
    }
    setLoading(true);
    let unsubscribe: Unsubscribe;
    unsubscribe = PatientDocumentsApiService.onCollectionSnapshot(handleSnapshot, handleSubscriptionError, [
      where('patientUid', '==', patient.uid),
      where('organisationUid', '==', userData?.organisationUid),
      orderBy('created.at', 'desc'),
    ]);
    return () => {
      unsubscribe();
    };
  }, [handleSnapshot, handleSubscriptionError, userData?.organisationUid, patient.uid, t]);

  const openDocument = async (selectedDoc: IPatientDocumentDao) => {
    const url = await getDownloadURL(ref(storage, selectedDoc.path));
    window.open(url);
  };

  const getUploadType = (doc: IPatientDocumentDao): string | undefined =>
    uploadTypes?.data?.find((type) => type.uid === doc.type)?.name;

  return (
    <SharedCard
      title={t('patients.patient.patient_documents')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_DOCUMENTS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddPatientDocumentDialog patient={patient} />)}
            type='button'
            appearance='link'
            labelKey='patients.patient.patient_documents.upload_document'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <div className='p-4'>
          <SkeletonElement width='400px' height='30px' />
          <SkeletonElement width='250px' height='10px' className='mt-1' />
        </div>
      ) : (
        <>
          {patientDocuments.length === 0 && <p className='text-gray-400 p-4'>{t('common.no_results')}</p>}
          {patientDocuments.map((doc) => (
            <div key={doc.uid} className='p-4 border-b last:border-0 flex justify-between items-center'>
              <div>
                <div>
                  <p>{getUploadType(doc)}</p>
                  <p className='text-gray-600 body-xs mb-3'>{doc.name}</p>
                </div>
                <CreatedBy created={doc.created} outerClassName='mt-2' />
              </div>
              <div className='flex justify-end space-x-4'>
                <SharedElementPermissionGuard
                  requiredPermissions={[[Permission.PATIENT_DOCUMENTS_READ], [Permission.ORGANISATION_OWNER]]}
                >
                  <SharedButton
                    labelKey='common.view'
                    appearance='link'
                    primaryOverride
                    onClick={() => openDocument(doc)}
                  />
                </SharedElementPermissionGuard>
                <SharedElementPermissionGuard
                  requiredPermissions={[[Permission.PATIENT_DOCUMENTS_DELETE], [Permission.ORGANISATION_OWNER]]}
                >
                  <SharedButton
                    labelKey='common.delete'
                    appearance='link'
                    danger
                    onClick={() =>
                      dialog?.openDialog(
                        <ConfirmActionDialog
                          action={() => PatientDocumentsApiService.permDelete(doc.uid)}
                          actionButtonProps={{
                            labelKey: 'common.delete',
                            danger: true,
                          }}
                          title={t('dialog.delete_patient_document.title')}
                          textContent={t('dialog.delete_patient_document.content')}
                          successMessage={t('dialog.delete_patient_document.success.description')}
                          errorMessage={t('dialog.delete_patient_document.error.description')}
                        />
                      )
                    }
                  />
                </SharedElementPermissionGuard>
              </div>
            </div>
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default PatientDocuments;
