import { Permission } from 'core/constants/permission';
import { Navigate, Route, Routes } from 'react-router-dom';
import AddEditHearingTest from './add-edit-hearing-test';
import PermissionGuard from 'core/routing/permission-guard';

const HearingTestRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_CREATE], [Permission.ORGANISATION_OWNER]],
      path: 'create',
      element: <AddEditHearingTest />,
    },
    {
      requiredPermissions: [[Permission.PATIENT_HEARING_TESTS_UPDATE], [Permission.ORGANISATION_OWNER]],
      path: 'edit/:testUid',
      element: <AddEditHearingTest />,
    },
  ];

  return (
    <Routes>
      {routes.map(({ requiredPermissions, path, element }) => (
        <Route
          key={path}
          element={
            <PermissionGuard
              requiredPermissions={requiredPermissions}
              navList={routes.map((route) => ({
                labelKey: '',
                requiredPermissions: route.requiredPermissions,
                route: `hearing-test/${route.path}`,
              }))}
            />
          }
        >
          <Route path={path} element={element} />
        </Route>
      ))}
      <Route path='*' element={<Navigate replace to='create' />} />
    </Routes>
  );
};

export default HearingTestRouting;
