import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { IOrderAccessoryOrService } from './add-edit-order';
import { Dispatch, SetStateAction } from 'react';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditOrderAddAccessoryServiceDialog from './add-edit-order-add-accessory-service-dialog';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { useUserState } from 'core/providers/user-provider';
import { Trash2 } from 'react-feather';

interface IAddEditOrderAccessoriesServices {
  current: IOrderAccessoryOrService[];
  set: Dispatch<SetStateAction<IOrderAccessoryOrService[]>>;
}

const AddEditOrderAccessoriesServices = ({ current, set }: IAddEditOrderAccessoriesServices) => {
  const dialog = useDialog();
  const { organisationData } = useUserState();

  const add = (data: IOrderAccessoryOrService) => {
    set((prev) => [...prev, data]);
  };

  const remove = (index: number) => {
    set((prev) => prev.filter((_, i) => i !== index));
  };

  const { t } = useTranslation();
  return (
    <SharedCard
      title={t('orders.add_edit_order.accessories_services_title')}
      extra={
        <SharedButton
          labelKey='order.add_edit_order.add_accessory_service'
          appearance='link'
          primaryOverride
          onClick={() => dialog?.openDialog(<AddEditOrderAddAccessoryServiceDialog add={add} />)}
        />
      }
    >
      {current.length === 0 && (
        <p className='p-4 text-gray-400'>{t('orders.add_edit_order.no_accessories_services')}</p>
      )}

      {current.map((item, index) => (
        <div
          key={index}
          className='flex justify-between items-center text-gray-700 border-t border-dashed first:border-t-0 p-4'
        >
          <p>{item.type === 'accessory' ? `${item.manufacturer} ${item.name}` : item.name}</p>
          <div className='flex items-center space-x-6'>
            <p>{formatToCurrency(item.price, organisationData?.finance.currency ?? 'GBP')}</p>
            <SharedButton danger appearance='primary' icon={<Trash2 size={16} onClick={() => remove(index)} />} />
          </div>
        </div>
      ))}
      <div className='border-t p-4 flex items-center justify-between'>
        <p>{t(organisationData?.finance.vatEnabled ? 'common.total_excl_vat' : 'common.total')}</p>
        <p>
          {formatToCurrency(
            current.reduce((acc, item) => acc + item.price, 0),
            organisationData?.finance.currency
          )}
        </p>
      </div>
    </SharedCard>
  );
};

export default AddEditOrderAccessoriesServices;
