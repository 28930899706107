import SharedDialogBase from './dialog-base';
import { ControlType } from 'core/enums/control-type';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useDialog } from 'core/providers/dialog-provider';

interface ITextInputDialog {
  title: string;
  fieldKey: string;
  defaultValue?: string;
  onFinish: (value: string) => void;
}

const SetTextInputDialog = ({ title, fieldKey, defaultValue, onFinish }: ITextInputDialog) => {
  const dialog = useDialog();
  const SetTextInputFormFields: ISharedField[] = [
    {
      fieldKey,
      control: ControlType.TextArea,
      label: title,
      required: true,
      defaultValue,
      rows: 6,
    },
  ];

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <SharedForm
          fields={SetTextInputFormFields}
          name='set-text-input-form'
          existingValue={{ [fieldKey]: defaultValue }}
          className='p-0'
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          onFinish={(value: Record<string, string>) => {
            onFinish(value[fieldKey]);
          }}
        />
      </div>
    );
  };

  return <SharedDialogBase title={title} customContentTemplate={customContent()} showButtons={false} />;
};

export default SetTextInputDialog;
