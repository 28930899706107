import { App, Form } from 'antd';
import { HearingTestsApiService, PatientApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import AudiogramDiagram from './audiogram-diagram';
import { IAudiogramDao, IAudiogramEarHealthDao } from 'core/api/types/hearing-test.interface';
import EarHealthForm, { IEarHealthFormOutput } from './ear-health-form';
import SharedButton from 'shared/button/button';
import HearingLossCausesForm, { IHearingLossCausesFormOutput } from './hearing-loss-causes-form';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { v4 as uuidv4 } from 'uuid';
import { Timestamp } from 'firebase/firestore';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import * as htmlToImage from 'html-to-image';
import { ref, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';

interface IAddEditHearingTestFormOutput {
  clinic: string;
  audiologist: string;
  testDate: Dayjs;
  durationOfLoss?: string;
  occupation?: string;
  familyDeafness?: string;
  audiometer: string;
  notes?: string;
}

const AddEditHearingTest = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const patientUid = searchParams.get('patient');
  const { testUid } = useParams();
  const [fetchingData, setFetchingData] = useState(true);
  const [patient, setPatient] = useState<IPatientDao>();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const { userData } = useUserState();
  const [testDetailsForm] = Form.useForm<IAddEditHearingTestFormOutput>();
  const [leftEarHealthForm] = Form.useForm<IEarHealthFormOutput>();
  const [rightEarHealthForm] = Form.useForm<IEarHealthFormOutput>();
  const [hearingLossCausesForm] = Form.useForm<IHearingLossCausesFormOutput>();
  const [audiogramValues, setAudiogramValues] = useState<IAudiogramDao>({
    left: {},
    right: {},
    bil: {},
  });
  const audiometersState = useSelector(OrganisationSettingsSlice.selectAudiometers);
  const navigate = useNavigate();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);

  const fetchPatientData = useCallback(
    async (patientUid: string) => {
      try {
        const snap = await PatientApiService.get(patientUid);
        if (!snap.exists()) {
          throw new Error('Patient not found');
        } else {
          setPatient(snap.data());
          setFetchingData(false);
        }
      } catch (error) {
        message.error(t('hearing_test.add_edit_hearing_test.error_fetching_patient'));
        sentryCaptureException(error, 'Add edit hearing test, fetching patient', userData);
      }
    },
    [message, t, userData]
  );

  useEffect(() => {
    if (patientUid) {
      fetchPatientData(patientUid);
    }
  }, [fetchPatientData, patientUid]);

  useEffect(() => {
    if (userData?.clinics?.length === 1) {
      testDetailsForm.setFieldsValue({ clinic: userData.clinics[0] });
    }
  }, [testDetailsForm, userData?.clinics]);

  const fetchHearingTestData = useCallback(
    async (testUid: string) => {
      try {
        const snap = await HearingTestsApiService.get(testUid);
        if (!snap.exists()) {
          throw new Error('Hearing test not found');
        } else {
          const {
            clinic,
            patient,
            audiogram,
            audiologist,
            testDate,
            durationOfLoss,
            occupation,
            familyDeafness,
            audiometer,
            notes,
            leftEarHealth,
            rightEarHealth,
            hearingLossCauses,
          } = snap.data();
          setPatient(patient);
          setAudiogramValues(audiogram);
          testDetailsForm.setFieldsValue({
            clinic,
            audiologist,
            testDate: dayjs(testDate.toDate()),
            durationOfLoss,
            occupation,
            familyDeafness,
            audiometer,
            notes,
          });
          leftEarHealthForm.setFieldsValue(leftEarHealth);
          rightEarHealthForm.setFieldsValue(rightEarHealth);
          hearingLossCausesForm.setFieldsValue(hearingLossCauses);
          setFetchingData(false);
        }
      } catch (error) {
        message.error(t('hearing_test.add_edit_hearing_test.error_fetching_test'));
        sentryCaptureException(error, 'Add edit hearing test, fetching test', userData);
      }
    },
    [hearingLossCausesForm, leftEarHealthForm, message, rightEarHealthForm, t, testDetailsForm, userData]
  );

  useEffect(() => {
    if (testUid) {
      fetchHearingTestData(testUid);
    }
  }, [fetchHearingTestData, testUid]);

  const patientDisplayOnlyFields = [
    {
      key: 'name',
      labelKey: 'hearing_test.add_edit_hearing_test.details.patient_name',
      value: patient?.fullName,
    },
    {
      key: 'address',
      labelKey: 'hearing_test.add_edit_hearing_test.details.patient_address',
      value: patient?.address?.formattedAddress ?? t('common.not_provided'),
    },
    {
      key: 'phoneNumber',
      labelKey: 'hearing_test.add_edit_hearing_test.details.patient_phone_number',
      value: patient?.phoneNumber,
    },
    {
      key: 'dob',
      labelKey: 'hearing_test.add_edit_hearing_test.details.patient_dob',
      value: patient?.dob ? dayjs(patient.dob.toDate()).format('DD/MM/YYYY') : t('common.not_provided'),
    },
  ];

  const testDetailFormFields: ISharedField[] = [
    {
      fieldKey: 'clinic',
      control: ControlType.Select,
      label: t('hearing_test.add_edit_hearing_test.form.clinic'),
      options: clinicsState?.data.map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? [],
      required: true,
    },
    {
      fieldKey: 'audiologist',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('hearing_test.add_edit_hearing_test.form.audiologist'),
      required: true,
    },
    {
      fieldKey: 'testDate',
      control: ControlType.DatePicker,
      label: t('hearing_test.add_edit_hearing_test.form.test_date'),
      required: true,
      fullWidth: true,
    },
    {
      fieldKey: 'durationOfLoss',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('hearing_test.add_edit_hearing_test.form.duration_of_loss'),
      required: false,
    },
    {
      fieldKey: 'occupation',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('hearing_test.add_edit_hearing_test.form.occupation'),
      required: false,
    },
    {
      fieldKey: 'familyDeafness',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('hearing_test.add_edit_hearing_test.form.family_deafness'),
      required: false,
    },
    {
      fieldKey: 'audiometer',
      control: ControlType.Select,
      options:
        audiometersState?.data.map((audiometer) => ({
          label: `${audiometer.make} ${audiometer.model}`,
          value: audiometer.uid,
        })) ?? [],
      label: t('hearing_test.add_edit_hearing_test.form.audiometer'),
      required: true,
    },
    {
      fieldKey: 'notes',
      control: ControlType.TextArea,
      rows: 4,
      label: t('hearing_test.add_edit_hearing_test.form.notes'),
      required: false,
    },
  ];

  const onAudiogramValueChange = (currentEar: string, currentMarker: string, x: number, y: number) => {
    const newSectionValue = { ...audiogramValues[currentEar][currentMarker] };
    const current = newSectionValue[x];
    if (current === y) {
      delete newSectionValue[x];
    } else {
      newSectionValue[x] = y;
    }
    setAudiogramValues((prevState) => ({
      ...prevState,
      [currentEar]: { ...prevState[currentEar], [currentMarker]: newSectionValue },
    }));
  };

  const submitButton = (
    <SharedButton labelKey='common.submit' onClick={() => submit()} appearance='primary' loading={submitting} />
  );

  const headerActions = [
    {
      element: submitButton,
      key: 'submitHearingTest',
    },
  ];

  const submit = async () => {
    try {
      const forms = [testDetailsForm, leftEarHealthForm, rightEarHealthForm, hearingLossCausesForm];
      const validationPromises = forms.map((form) => {
        return form.validateFields();
      });
      await Promise.all(validationPromises);
      try {
        setSubmitting(true);
        if (!patient || !userData?.organisationUid) {
          throw new Error('Patient or organisation not found');
        }
        const { clinic, audiologist, testDate, durationOfLoss, occupation, familyDeafness, audiometer, notes } =
          testDetailsForm.getFieldsValue();
        const earHealth: Partial<IAudiogramEarHealthDao>[] = ['left', 'right'].map((side) => {
          const values = side === 'left' ? leftEarHealthForm.getFieldsValue() : rightEarHealthForm.getFieldsValue();
          const { pinna, meatus, tm, discharge, earache, tinnitus, conductive } = values;
          return {
            ...(pinna && { pinna }),
            ...(meatus && { meatus }),
            ...(tm && { tm }),
            ...(discharge && { discharge }),
            ...(earache && { earache }),
            ...(tinnitus && { tinnitus }),
            ...(conductive && { conductive }),
          };
        });
        const { noise, unilateral, asymmetrical, suddenOnset, suddenWorsening, fluctuating, vertigo, premature } =
          hearingLossCausesForm.getFieldsValue();
        const hearingLossCausesValues = {
          ...(noise && { noise }),
          ...(unilateral && { unilateral }),
          ...(asymmetrical && { asymmetrical }),
          ...(suddenOnset && { suddenOnset }),
          ...(suddenWorsening && { suddenWorsening }),
          ...(fluctuating && { fluctuating }),
          ...(vertigo && { vertigo }),
          ...(premature && { premature }),
        };
        const userTimestamp = getActionTimestampFromUser(userData);
        const createPayload = {
          uid: uuidv4(),
          organisationUid: userData.organisationUid,
          patient: patient,
          created: userTimestamp,
        };
        const payload = {
          testDate: Timestamp.fromDate(testDate.toDate()),
          clinic,
          audiologist,
          audiometer,
          audiogram: audiogramValues,
          leftEarHealth: earHealth[0],
          rightEarHealth: earHealth[1],
          hearingLossCauses: hearingLossCausesValues,
          ...(durationOfLoss && { durationOfLoss }),
          ...(occupation && { occupation }),
          ...(familyDeafness && { familyDeafness }),
          ...(notes && { notes }),
          updated: userTimestamp,
        };
        const audiogram = document.getElementById('audiogram-editor');
        if (!audiogram) {
          throw new Error('Audiogram image not found');
        }
        const dataUrl = await htmlToImage.toPng(audiogram, { canvasHeight: 834, canvasWidth: 631 });
        const audiogramPath = `audiogramDiagrams/${userData.organisationUid}/${testUid ? testUid : createPayload.uid}`;
        await uploadString(ref(storage, audiogramPath), dataUrl, 'data_url');
        if (testUid) {
          await HearingTestsApiService.update(testUid, payload);
        } else {
          await HearingTestsApiService.set({ ...createPayload, ...payload });
        }
        message.success(
          <p className='md:max-w-[400px]'>
            {t(
              testUid
                ? 'hearing_test.add_edit_hearing_test.edit.success'
                : 'hearing_test.add_edit_hearing_test.create.success'
            )}
          </p>,
          8
        );

        navigate(-1);
      } catch (error) {
        setSubmitting(false);
        message.error(
          t(
            testUid
              ? 'hearing_test.add_edit_hearing_test.edit.error'
              : 'hearing_test.add_edit_hearing_test.create.error'
          )
        );
        sentryCaptureException(
          error,
          testUid ? 'Add edit hearing test, editing test' : 'Add edit hearing test, creating test',
          userData
        );
      }
    } catch (error) {
      message.error(t('common.form.complete_all_fields'));
    }
  };

  return (
    <>
      <SharedPageHeader
        title={t(
          testUid ? 'hearing_test.add_edit_hearing_test.edit.title' : 'hearing_test.add_edit_hearing_test.create.title'
        )}
        showBack
        actions={headerActions}
      />
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4 grow pb-4'>
        <SharedCard title={t('hearing_test.add_edit_hearing_test.details.title')}>
          {fetchingData ? (
            <div className='p-4'>
              <SkeletonElement width='40%' height='22px' />
              <SkeletonElement width='100%' height='32px' className='mt-1' />
            </div>
          ) : (
            <div className='p-4'>
              {patientDisplayOnlyFields.map((field) => (
                <FormItemMimic key={field.key} label={t(field.labelKey)}>
                  <p className='text-[15px]'>{field.value}</p>
                </FormItemMimic>
              ))}
              <SharedForm<IAddEditHearingTestFormOutput>
                formInstance={testDetailsForm}
                className=''
                fields={testDetailFormFields}
                submitting={submitting}
                buttonsOverride={[]}
                name='add-edit-user-form'
                existingValue={{
                  testDate: !testUid && dayjs(),
                }}
              />
            </div>
          )}
        </SharedCard>
        <div className='md:col-span-3'>
          <SharedCard title={t('hearing_test.add_edit_hearing_test.audiogram.title')} outerClassName='w-[631px]'>
            <AudiogramDiagram
              onValueChange={onAudiogramValueChange}
              audiogramValues={audiogramValues}
              height='834px'
              id='audiogram-editor'
            />
          </SharedCard>
          <SharedCard innerClassName='grid grid-cols-1 md:grid-cols-2'>
            <EarHealthForm leftEarForm={leftEarHealthForm} rightEarForm={rightEarHealthForm} />
          </SharedCard>
          <SharedCard title={t('hearing_test.add_edit_hearing_test.hearing_loss_causes.title')}>
            <HearingLossCausesForm form={hearingLossCausesForm} />
          </SharedCard>
          <div className='flex justify-end'>{submitButton}</div>
        </div>
      </div>
    </>
  );
};

export default AddEditHearingTest;
