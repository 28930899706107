import { Navigate, Route, Routes } from 'react-router-dom';
import AuditLog from './audit-log';

const AuditLogRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AuditLog />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AuditLogRouting;
