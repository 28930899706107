import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditUploadTypeDialog from './add-edit-upload-type-dialog';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import { IUploadTypeDao } from 'core/api/types';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { UploadTypesApiService } from 'core/api';

const EditUploadSettings = () => {
  const dialog = useDialog();
  const uploadTypes = useSelector(OrganisationSettingsSlice.selectUploadTypes);

  const { t } = useTranslation();

  const tableColumns: ISharedTableColumn[] = [
    {
      labelKey: 'documents_and_images.upload_settings.table.header.upload_type',
      key: 'name',
    },
    { key: 'action', contentTemplateId: 'actions' },
  ];

  const headerActions = [
    {
      element: (
        <SharedButton
          labelKey='documents_and_images.upload_settings.add_upload_type'
          onClick={() => dialog?.openDialog(<AddEditUploadTypeDialog />)}
          appearance='primary'
        />
      ),
      key: 'addUser',
    },
  ];

  const actionTemplate = (uploadType: IUploadTypeDao) => {
    return (
      <div className='w-full flex justify-end space-x-2'>
        <SharedButton
          onClick={() => dialog?.openDialog(<AddEditUploadTypeDialog uploadType={uploadType} />)}
          type='button'
          appearance='link'
          labelKey='common.edit'
          primaryOverride
        />
        <SharedButton
          labelKey='common.delete'
          appearance='link'
          primaryOverride
          onClick={() =>
            dialog?.openDialog(
              <ConfirmActionDialog
                action={() => UploadTypesApiService.permDelete(uploadType.uid)}
                actionButtonProps={{
                  labelKey: 'common.delete',
                  danger: true,
                }}
                title={t('dialog.delete_patient_note.title')}
                textContent={t('dialog.delete_patient_note.content')}
                successMessage={t('dialog.delete_patient_note.success.description')}
                errorMessage={t('dialog.delete_patient_note.error.description')}
              />
            )
          }
        />
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <SharedPageHeader
        title={t('documents_and_images.upload_settings.upload_types')}
        showBack
        actions={headerActions}
      />
      <SharedCard>
        <SharedTable
          loading={uploadTypes?.status !== 'success'}
          rows={
            uploadTypes?.data?.map((uploadType) => ({
              key: uploadType.uid,
              data: uploadType,
            })) ?? []
          }
          contentTemplates={contentTemplates}
          columns={tableColumns}
        />
      </SharedCard>
    </>
  );
};

export default EditUploadSettings;
