import { ArrowLeft, ArrowRight, CornerDownLeft } from 'react-feather';

export enum TransactionType {
  PAYMENT = 'payment',
  REFUND = 'refund',
  CHARGE = 'charge',
}

export const TransactionTypeData = {
  [TransactionType.PAYMENT]: {
    value: TransactionType.PAYMENT,
    translationLabelKey: 'transaction_type.payment',
    icon: ArrowRight,
    color: '#16a34a',
  },
  [TransactionType.REFUND]: {
    value: TransactionType.REFUND,
    translationLabelKey: 'transaction_type.refund',
    icon: CornerDownLeft,
    color: '#2563eb',
  },
  [TransactionType.CHARGE]: {
    value: TransactionType.CHARGE,
    translationLabelKey: 'transaction_type.charge',
    icon: ArrowLeft,
    color: '#dc2626',
  },
};

export const TransactionTypeOptions = [TransactionType.PAYMENT, TransactionType.REFUND, TransactionType.CHARGE];
