export const UserType = {
  HEARLINK_ADMIN: 'hearlinkAdmin',
  ORGANISATION_OWNER: 'organisationOwner',
  MEMBER: 'member',
};

export const UserTypeSelectOptions = [
  {
    labelKey: 'common.user_type.hearlink_admin',
    value: UserType.HEARLINK_ADMIN,
  },
  {
    labelKey: 'common.user_type.organisation_owner',
    value: UserType.ORGANISATION_OWNER,
  },
  {
    labelKey: 'common.user_type.member',
    value: UserType.MEMBER,
  },
];
