import { App } from 'antd';
import clsx from 'clsx';
import { HearingTestsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IAudiogramEarHealthDao, IHearingTestDao } from 'core/api/types/hearing-test.interface';
import { MeatusData } from 'core/constants/meatus';
import { Permission } from 'core/constants/permission';
import { PinnaData } from 'core/constants/pinna';
import { TMData } from 'core/constants/tm';
import { YesNoData } from 'core/constants/yes-no';
import { useResponsiveState } from 'core/providers/responsive-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { orderBy, Unsubscribe, where } from 'firebase/firestore';
import AudiogramDiagram from 'modules/hearing-test/audiogram-diagram';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import InfoRow from 'shared/data-display/info-row';
import LastUpdated from 'shared/data-display/last-updated';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientHearingTestList = (patient: IPatientDao) => {
  const { userData } = useUserState();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [hearingTests, setHearingTests] = useState<IHearingTestDao[]>([]);
  const { message } = App.useApp();
  const audiometerState = useSelector(OrganisationSettingsSlice.selectAudiometers);
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const { isMobile } = useResponsiveState();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = HearingTestsApiService.onCollectionSnapshot(
      (snap) => {
        setHearingTests(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_hearing_tests.table.error.description'));
        sentryCaptureException(error, 'Patient file fetching hearing tests', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const getHearingTestDetails = (test: IHearingTestDao) => {
    const audiometer = audiometerState?.data.find((audiometer) => audiometer.uid === test.audiometer);
    const clinic = clinicsState?.data.find((clinic) => clinic.uid === test.clinic);
    return [
      {
        key: 'testDate',
        label: t('hearing_test.add_edit_hearing_test.form.test_date'),
        value: dayjs(test.testDate.toDate()).format('DD/MM/YYYY'),
      },
      {
        key: 'clinic',
        label: t('hearing_test.add_edit_hearing_test.form.clinic'),
        value: clinic ? clinic.name : undefined,
      },
      {
        key: 'audiologist',
        label: t('hearing_test.add_edit_hearing_test.form.audiologist'),
        value: test.audiologist,
      },
      {
        key: 'durationOfLoss',
        label: t('hearing_test.add_edit_hearing_test.form.duration_of_loss'),
        value: test.durationOfLoss,
      },
      {
        key: 'occupation',
        label: t('hearing_test.add_edit_hearing_test.form.occupation'),
        value: test.occupation,
      },
      {
        key: 'familyDeafness',
        label: t('hearing_test.add_edit_hearing_test.form.family_deafness'),
        value: test.familyDeafness,
      },
      {
        key: 'audiometer',
        label: t('hearing_test.add_edit_hearing_test.form.audiometer'),
        value: audiometer ? `${audiometer.make} ${audiometer.model}` : undefined,
      },
    ];
  };

  const getEarHealth = (earHealth: Partial<IAudiogramEarHealthDao>) => {
    const { pinna, meatus, tm, discharge, earache, tinnitus, conductive } = earHealth;
    return [
      {
        key: 'pinna',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.pinna'),
        value: pinna ? t(PinnaData[pinna].translationLabelKey) : undefined,
      },
      {
        key: 'meatus',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.meatus'),
        value: meatus ? t(MeatusData[meatus].translationLabelKey) : undefined,
      },
      {
        key: 'tm',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.tm'),
        value: tm ? t(TMData[tm].translationLabelKey) : undefined,
      },
      {
        key: 'discharge',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.discharge'),
        value: discharge ? t(YesNoData[discharge].translationLabelKey) : undefined,
      },
      {
        key: 'earache',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.earache'),
        value: earache ? t(YesNoData[earache].translationLabelKey) : undefined,
      },
      {
        key: 'tinnitus',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.tinnitus'),
        value: tinnitus ? t(YesNoData[tinnitus].translationLabelKey) : undefined,
      },
      {
        key: 'conductive',
        label: t('hearing_test.add_edit_hearing_test.ear_health.form.conductive'),
        value: conductive ? t(YesNoData[conductive].translationLabelKey) : undefined,
      },
    ];
  };

  const getHearingLossCauses = (test: IHearingTestDao) => {
    const { noise, unilateral, asymmetrical, suddenOnset, suddenWorsening, fluctuating, vertigo, premature } =
      test.hearingLossCauses;
    return [
      {
        key: 'noise',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.noise'),
        value: noise ? t(YesNoData[noise].translationLabelKey) : undefined,
      },
      {
        key: 'unilateral',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.unilateral'),
        value: unilateral ? t(YesNoData[unilateral].translationLabelKey) : undefined,
      },
      {
        key: 'asymmetrical',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.asymmetrical'),
        value: asymmetrical ? t(YesNoData[asymmetrical].translationLabelKey) : undefined,
      },
      {
        key: 'suddenOnset',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_onset'),
        value: suddenOnset ? t(YesNoData[suddenOnset].translationLabelKey) : undefined,
      },
      {
        key: 'suddenWorsening',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.sudden_worsening'),
        value: suddenWorsening ? t(YesNoData[suddenWorsening].translationLabelKey) : undefined,
      },
      {
        key: 'fluctuating',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.fluctuating'),
        value: fluctuating ? t(YesNoData[fluctuating].translationLabelKey) : undefined,
      },
      {
        key: 'vertigo',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.vertigo'),
        value: vertigo ? t(YesNoData[vertigo].translationLabelKey) : undefined,
      },
      {
        key: 'premature',
        label: t('hearing_test.add_edit_hearing_test.hearing_loss_causes.form.premature'),
        value: premature ? t(YesNoData[premature].translationLabelKey) : undefined,
      },
    ];
  };

  const getHearingTestDataSections = (test: IHearingTestDao) => {
    return [
      {
        key: 'details',
        title: 'hearing_test.add_edit_hearing_test.details.title',
        fields: getHearingTestDetails(test),
        columnWidth: 2,
      },
      {
        key: 'leftEarHealth',
        title: 'hearing_test.add_edit_hearing_test.ear_health.left',
        fields: getEarHealth(test.leftEarHealth),
        columnWidth: 1,
      },
      {
        key: 'rightEarHealth',
        title: 'hearing_test.add_edit_hearing_test.ear_health.right',
        fields: getEarHealth(test.leftEarHealth),
        columnWidth: 1,
      },
      {
        key: 'hearingLossCauses',
        title: 'hearing_test.add_edit_hearing_test.hearing_loss_causes.title',
        fields: getHearingLossCauses(test),
        columnWidth: 2,
      },
    ];
  };

  return (
    <SharedCard
      title={t('patients.patient.patient_hearing_tests.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <Link to={`/hearing-test/create?patient=${patient.uid}`}>
            <SharedButton labelKey='patients.patient.patient_hearing_tests.create' appearance='link' primaryOverride />
          </Link>
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <div className='p-4'>
          <SkeletonElement width='100%' height='60px' />
          <SkeletonElement width='30%' height='20px' className='mt-1' />
        </div>
      ) : (
        <>
          {hearingTests.length === 0 && <p className='text-gray-400 p-4'>{t('common.no_results')}</p>}

          {hearingTests.map((test) => (
            <div key={test.uid} className='border-b last:border-b-0 p-4 py-6'>
              <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='md:pr-4 md:border-r grid grid-cols-1 md:grid-cols-2 gap-6 border-b pb-4 md:border-b-0 md:pb-0'>
                  {getHearingTestDataSections(test).map((section) => {
                    return (
                      <div key={section.key} className={clsx('col-span-2', section.columnWidth < 2 && 'md:col-span-1')}>
                        <p className='border-b pb-1 mb-3'>{t(section.title)}</p>
                        {section.fields.map((field) => (
                          <InfoRow key={field.key} value={field.value} label={field.label} />
                        ))}
                      </div>
                    );
                  })}
                </div>
                <AudiogramDiagram
                  onValueChange={() => {}}
                  audiogramValues={test.audiogram}
                  height={isMobile ? '500px' : '100%'}
                  id={test.uid}
                  viewOnly
                />
              </div>
              <div className='flex mt-4 justify-between items-center'>
                <LastUpdated updated={test.updated} />
                <Link to={`/hearing-test/edit/${test.uid}`}>
                  <SharedButton labelKey='common.edit' />
                </Link>
              </div>
            </div>
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default PatientHearingTestList;
