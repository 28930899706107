import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';

const DocumentsAndImagesSettingsList = () => {
  const { t } = useTranslation();

  const settingsOptions = [
    {
      key: 'upload',
      label: t('documents_and_images.upload_settings.title'),
      route: 'upload-settings',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('documents_and_images.edit')} />
      <SharedCard>
        {settingsOptions.map((option) => (
          <div key={option.label} className='flex justify-between items-center border-b p-3 body-sm'>
            <p>{option.label}</p>
            <Link to={option.route}>
              <SharedButton type='button' appearance='link' labelKey='common.edit' primaryOverride />
            </Link>
          </div>
        ))}
      </SharedCard>
    </>
  );
};

export default DocumentsAndImagesSettingsList;
