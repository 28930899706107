import { Navigate, Route, Routes } from 'react-router-dom';
import LeadTypesList from './lead-types-list';

const LeadTypesRouting = () => {
  return (
    <Routes>
      <Route path='' element={<LeadTypesList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default LeadTypesRouting;
