import { IAppointmentDao } from 'core/api/types/appointment.interface';
import CalendarColumn from './calendar-column';
import { IDomainCalendarResource } from 'modules/organisation-settings/organisation-settings-slice';
import dayjs, { Dayjs } from 'dayjs';
import SharedCalendarHeader from './calendar-header';
import { ReactNode, useEffect, useState } from 'react';
import ProgressBar from 'shared/progress-bar/progress-bar';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';

export interface ISharedCalendarNewAppointment {
  start: string;
  end: string;
}

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  resources: IDomainCalendarResource[];
  highlightedResource?: string;
  currentDate: Dayjs;
  changeDate: (newDate: string) => void;
  minDate?: Dayjs;
  extra?: ReactNode;
  loading?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  startHour: number;
  showAppointmentMenu?: boolean;
  calendarWrapperRef: React.RefObject<HTMLDivElement>;
}

const SharedCalendar = ({
  timeSlots,
  appointments,
  resources,
  highlightedResource,
  currentDate,
  changeDate,
  minDate,
  extra,
  loading,
  newAppointment,
  startHour,
  showAppointmentMenu,
  calendarWrapperRef,
}: ISharedCalendar) => {
  const headerHeight = 56;
  const resourceHeaderHeight = 56;
  const minimumSlotHeight = 60;
  const [calendarContainerHeight, setCalendarContainerHeight] = useState<number>(0);
  const [timeSlotHeight, setTimeSlotHeight] = useState<number>(minimumSlotHeight);
  const calendarBodyHeight = timeSlotHeight * timeSlots.length + resourceHeaderHeight;
  const { t } = useTranslation();

  useEffect(() => {
    if (!calendarWrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCalendarContainerHeight(calendarWrapperRef.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(calendarWrapperRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  });

  useEffect(() => {
    const calendarHeight = calendarContainerHeight - resourceHeaderHeight - headerHeight - (loading ? 4 : 0);
    if (calendarHeight / timeSlots.length < minimumSlotHeight) {
      setTimeSlotHeight(minimumSlotHeight);
    } else {
      setTimeSlotHeight((calendarHeight - (loading ? 4 : 0)) / timeSlots.length);
    }
  }, [calendarContainerHeight, loading, timeSlots.length]);

  const currentTimeLinePosition = () => {
    const minuteHeight = timeSlotHeight / 60;
    const now = dayjs();
    const calculatedMargin =
      resourceHeaderHeight + (now.hour() - startHour) * timeSlotHeight + now.minute() * minuteHeight;
    if (calculatedMargin <= resourceHeaderHeight) {
      return `${resourceHeaderHeight}px`;
    }
    if (calculatedMargin > calendarBodyHeight) {
      return `${calendarBodyHeight - 1}px`;
    }
    return `${calculatedMargin}px`;
  };

  return (
    <div className='flex flex-col grow overflow-hidden'>
      <SharedCalendarHeader
        headerHeight={headerHeight}
        currentDate={currentDate}
        changeDate={changeDate}
        minDate={minDate}
        extra={extra}
      />
      {loading && <ProgressBar />}
      <div className='flex grow overflow-y-auto'>
        <div
          className='basis-[56px] shrink-0 grow-0 pr-[8px] border-r bg-white sticky left-0 z-40'
          style={{ height: calendarBodyHeight }}
        >
          <div style={{ height: `${resourceHeaderHeight}px` }} />
          {timeSlots.map((slot) => (
            <div className='relative' style={{ height: `${timeSlotHeight}px` }} key={slot}>
              <p className='text-xs text-gray-500 -top-[8px] absolute right-0'>{slot}</p>
            </div>
          ))}
        </div>
        <div className='grow flex relative bg-white' style={{ height: calendarBodyHeight }}>
          {dayjs().isSame(currentDate, 'day') && resources.length > 0 && (
            <div
              className='absolute w-full h-px bg-red-600 z-20'
              style={{
                top: currentTimeLinePosition(),
              }}
            />
          )}
          <div className='grow flex'>
            {resources.length === 0 && !loading && (
              <Alert className='self-start m-4 w-full' message={t('calendar.no_resources')} type='info' showIcon />
            )}
            {resources.map((resource) => (
              <CalendarColumn
                key={resource.uid}
                resource={resource}
                existingAppointments={appointments.filter((app) => app.assignee.uid === resource.uid)}
                timeSlots={timeSlots}
                timeSlotHeight={timeSlotHeight}
                highlightedResource={highlightedResource}
                resourceHeaderHeight={resourceHeaderHeight}
                newAppointment={newAppointment}
                showAppointmentMenu={showAppointmentMenu}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedCalendar;
