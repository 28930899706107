import { Menu } from 'react-feather';
import { useTheme } from 'core/providers/theme-provider';

interface IMobileHeader {
  setOpenMobileMenu: (value: boolean) => void;
}

const MobileHeader = ({ setOpenMobileMenu }: IMobileHeader) => {
  const { primary } = useTheme();

  return (
    <div
      className='md:hidden h-[60px] flex items-center justify-between px-4 fixed w-full z-10'
      style={{ backgroundColor: primary.bg }}
    >
      <Menu color={primary.text} onClick={() => setOpenMobileMenu(true)} />
    </div>
  );
};

export default MobileHeader;
