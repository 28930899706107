import { OrganisationSettingsNavbarOptions } from '../organisation-settings-navbar-options';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { useTheme } from 'core/providers/theme-provider';

const OrganisationSettingsMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userPermissions } = usePermissionsState();
  const { primary } = useTheme();

  const isActive = (route: string) => location.pathname.startsWith(`/${route}`);

  const menuItems = OrganisationSettingsNavbarOptions.filter((item) =>
    item.requiredPermissions.some((permissions) =>
      permissions.every((permission) => userPermissions?.includes(permission))
    )
  );

  return (
    <>
      <div className='hidden md:block border-r bg-white p-4 basis-[280px] grow-0 shrink-0 sticky top-0'>
        <p className='header-md mb-4'>{t('navigation.organisation_settings')}</p>
        <div className='relative'>
          <div className='absolute h-full w-[2px] bg-gray-200' />
          {menuItems.map((item) => (
            <div key={item.route} className={'mb-1 py-1 pl-3.5 relative'}>
              {isActive(item.route) && (
                <div
                  className='w-1.5 h-1.5 rounded-full absolute -left-[2px] top-[10px]'
                  style={{ backgroundColor: primary.bg }}
                />
              )}
              <Link to={`/${item.route}`}>
                <p
                  className={clsx(
                    'hover:text-gray body-sm transition ease-in-out',
                    isActive(item.route) ? 'text-gray-900' : 'text-gray-400'
                  )}
                >
                  {t(item.labelKey)}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div className='md:hidden basis-[50px] grow-0 flex-shrink-0 border-b bg-white flex items-center overflow-x-auto space-x-4 px-4'>
        {menuItems.map(({ route, labelKey }) => (
          <Link key={route} to={`/${route}`}>
            <div
              className={clsx(
                'button-xs rounded-md px-3 py-2 transition ease-in-out whitespace-nowrap',
                isActive(route) ? 'text-white' : `hover:bg-gray-100 hover:text-gray-800 text-gray-800`
              )}
              style={{
                backgroundColor: isActive(route) ? primary.bg : '',
              }}
            >
              <p>{t(labelKey)}</p>
            </div>
          </Link>
        ))}
      </div>
    </>
  );
};

export default OrganisationSettingsMenu;
