import { usePermissionsState } from 'core/providers/permissions-provider';
import { ReactNode } from 'react';

interface ISharedPermissionGuard {
  requiredPermissions: string[][];
  children: any;
  replacement?: ReactNode;
}

const SharedElementPermissionGuard = ({ children, requiredPermissions, replacement }: ISharedPermissionGuard) => {
  const { userPermissions } = usePermissionsState();

  if (
    requiredPermissions.some((permissions) => permissions.every((permission) => userPermissions?.includes(permission)))
  ) {
    return <>{children}</>;
  }

  return replacement ? replacement : <></>;
};

export default SharedElementPermissionGuard;
