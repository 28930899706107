import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import { useUserState } from 'core/providers/user-provider';
import { OrganisationsApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { App } from 'antd';
import SharedSpinner from 'shared/spinner/spinner';
import { useTheme } from 'core/providers/theme-provider';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ControlType } from 'core/enums/control-type';
import { useForm, useWatch } from 'antd/es/form/Form';
import SharedForm from 'shared/form/shared-form';
import { useState } from 'react';
import { InputType } from 'core/enums/input-type';
import FormItemMimic from 'shared/form/form-item-mimic';

interface IEditVatSettingsFormOutput {
  vatEnabled: boolean;
  vatStandardRate: number;
  currency: string;
  invoicePrefix: string;
  invoiceDigits: number;
  invoiceNext: number;
}

const EditVatSettings = () => {
  const { userData, organisationData } = useUserState();
  const { message } = App.useApp();
  const { primary } = useTheme();
  const { t } = useTranslation();
  const [form] = useForm();
  const vatEnabled = useWatch('vatEnabled', form);
  const [loading, setLoading] = useState(false);
  const invoicePrefix = useWatch('invoicePrefix', form);
  const invoiceDigits = useWatch('invoiceDigits', form);
  const invoiceNext = useWatch('invoiceNext', form);
  const defaultValues = {
    vatEnabled: organisationData?.finance?.vatEnabled,
    vatStandardRate: organisationData?.finance?.vatStandardRate,
    currency: organisationData?.finance?.currency,
    invoicePrefix: organisationData?.finance?.invoice?.prefix,
    invoiceDigits: organisationData?.finance?.invoice?.digits,
    invoiceNext: organisationData?.finance?.invoice?.next,
  };

  const formFields: ISharedField[] = [
    {
      fieldKey: 'vatEnabled',
      control: ControlType.Switch,
      label: t('finance.vat.vatEnabled'),
      required: true,
    },
    {
      fieldKey: 'vatStandardRate',
      control: ControlType.NumberField,
      label: t('finance.vat.vatStandardRate'),
      min: 0,
      max: 100,
      formatter: (value) => `${value}%`,
      parser: (value) => value?.replace('%', '') as unknown as number,
      hidden: !vatEnabled,
      required: true,
      fullWidth: true,
    },
    {
      fieldKey: 'currency',
      control: ControlType.Select,
      options: [
        {
          value: 'GBP',
          label: t('currency.label.gbp'),
        },
        {
          value: 'USD',
          label: t('currency.label.usd'),
        },
        { value: 'EUR', label: t('currency.label.eur') },
      ],
      label: t('finance.currency'),
      required: true,
    },
    {
      fieldKey: 'invoicePrefix',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('finance.invoice.prefix'),
      required: true,
    },
    {
      fieldKey: 'invoiceDigits',
      control: ControlType.NumberField,
      label: t('finance.invoice.digits'),
      required: true,
      fullWidth: true,
    },
    {
      fieldKey: 'invoiceNext',
      control: ControlType.NumberField,
      label: t('finance.invoice.next'),
      required: true,
      fullWidth: true,
      extra: invoicePrefix && invoiceDigits && invoiceNext && (
        <FormItemMimic label={t('finance.invoice.preview')}>
          <p>{`${invoicePrefix}${String(invoiceNext).padStart(invoiceDigits, '0')}`}</p>
        </FormItemMimic>
      ),
    },
  ];

  const submit = async (data: IEditVatSettingsFormOutput) => {
    setLoading(true);
    try {
      if (organisationData) {
        const { invoiceNext, invoiceDigits, invoicePrefix, ...rest } = data;
        await OrganisationsApiService.update(organisationData?.uid, {
          updated: getActionTimestampFromUser(userData),
          finance: {
            ...rest,
            invoice: {
              prefix: data.invoicePrefix,
              digits: data.invoiceDigits,
              next: data.invoiceNext,
            },
          },
        });
        message.success(t('finance.edit.success'));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(t('finance.edit.error'));
      sentryCaptureException(error, 'Edit finance settings', userData);
    }
  };

  return (
    <>
      <SharedPageHeader title={t('finance.edit')} />

      {!organisationData?.finance && (
        <SharedCard>
          <div className='h-[250px] flex items-center justify-center'>
            <SharedSpinner color={primary.bg} />
          </div>
        </SharedCard>
      )}

      {organisationData?.finance && (
        <>
          <SharedCard>
            <SharedForm
              submitting={loading}
              onFinish={submit}
              formInstance={form}
              name='financeSettings'
              fields={formFields}
              cancelButton={{
                labelKey: 'common.cancel',
                onClick: () =>
                  form.setFieldsValue({
                    ...defaultValues,
                  }),
              }}
              existingValue={{ ...defaultValues }}
            />
          </SharedCard>
        </>
      )}
    </>
  );
};

export default EditVatSettings;
