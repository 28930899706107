import { INavbarOption, NavbarOptions } from 'core/config/navbar-options';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { useUserState } from 'core/providers/user-provider';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import SharedSpinner from 'shared/spinner/spinner';

interface IPermissionGuard {
  requiredPermissions?: string[][];
  navList?: INavbarOption[];
}

const PermissionGuard = ({ requiredPermissions, navList = NavbarOptions }: IPermissionGuard) => {
  const { userPermissions } = usePermissionsState();
  const { userData } = useUserState();

  const location = useLocation();

  if (!userPermissions || !userData) {
    return (
      <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2'>
        <SharedSpinner />
      </div>
    );
  }

  if (
    requiredPermissions &&
    !requiredPermissions.some((permissions) => permissions.every((permission) => userPermissions?.includes(permission)))
  ) {
    const allowedRoute = navList.find((item) =>
      item.requiredPermissions.some((permissions) =>
        permissions.every((permission) => userPermissions?.includes(permission))
      )
    )?.route;

    return <Navigate to={allowedRoute ? `/${allowedRoute}` : 'no-permissions'} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
export default PermissionGuard;
