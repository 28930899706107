import { IGroupDao } from 'core/api/types';
import { GroupApiService } from 'core/api';
import { Unsubscribe } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import SharedPageHeader from 'shared/page-header/page-header';
import GroupDetailPermissions from './group-detail-permissions';
import GroupDetailMembers from './group-detail-members/group-detail-members';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditGroupDialog from '../add-edit-group-dialog';
import SharedSpinner from 'shared/spinner/spinner';
import * as _ from 'lodash';
import { App } from 'antd';

const GroupDetail = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [group, setGroup] = useState<IGroupDao>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const dialog = useDialog();

  const handleError = useCallback(() => {
    message.error(t('groups.group_detail.error.description'));
  }, [message, t]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (!uid) {
      navigate(-1);
    } else {
      unsubscribe = GroupApiService.onDocSnapshot(
        uid,
        (snap) => {
          if (snap.exists()) {
            setGroup(snap.data());
          } else {
            handleError();
          }
        },
        () => handleError()
      );
    }
    return () => {
      unsubscribe();
    };
  }, [handleError, navigate, uid]);

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.GROUPS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='groups.group_detail.header.edit_details'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditGroupDialog groupData={_.cloneDeep(group)} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'editGroupDetails',
    },
  ];

  return !group || !uid ? (
    <div className='py-8 flex justify-center items-center h-[306px]'>
      <SharedSpinner />
    </div>
  ) : (
    <>
      <SharedPageHeader title={group.name} subtitle={group.description} actions={headerActions} showBack />
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
        <GroupDetailPermissions uid={uid} currentPermissions={group.permissions} />
        <GroupDetailMembers uid={uid} currentMembers={group.members} />
      </div>
    </>
  );
};

export default GroupDetail;
