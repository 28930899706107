import clsx from 'clsx';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { Timestamp } from 'firebase/firestore';
import { IDomainCalendarResource } from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useState } from 'react';
import SharedCalendarAppointment from './calendar-appointment';
import { ISharedCalendarNewAppointment } from './calendar';
import { getAppointmentStyle } from 'shared/helpers/appointment-helpers';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import { useDialog } from 'core/providers/dialog-provider';
import { useTheme } from 'core/providers/theme-provider';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';

interface ISharedCalendarColumn {
  resource: IDomainCalendarResource;
  highlightedResource?: string;
  existingAppointments: IAppointmentDao[];
  timeSlots: string[];
  showAppointmentMenu?: boolean;
  timeSlotHeight?: number;
  resourceHeaderHeight?: number;
  newAppointment?: ISharedCalendarNewAppointment;
}

const SharedCalendarColumn = ({
  resource,
  highlightedResource,
  existingAppointments,
  timeSlots,
  timeSlotHeight = 60,
  resourceHeaderHeight = 56,
  newAppointment,
  showAppointmentMenu = true,
}: ISharedCalendarColumn) => {
  const [appGroups, setAppGroups] = useState<IAppointmentDao[][][]>([]);
  const { t } = useTranslation();
  const { organisationData } = useUserState();
  const [searchParams] = useSearchParams();
  const dialog = useDialog();
  const { primary } = useTheme();

  const appointmentUid = searchParams.get('appointment');

  const startHour = dayjs(organisationData?.calendar.startTime.toDate()).hour();
  const endHour = dayjs(organisationData?.calendar.endTime.toDate()).hour();

  const collides = useCallback((a: IAppointmentDao, b: IAppointmentDao) => {
    return a.endDateTime > b.startDateTime && a.startDateTime < b.endDateTime;
  }, []);

  useEffect(() => {
    let g: IAppointmentDao[][][] = [];
    let columns: IAppointmentDao[][] = [];
    let lastAppointmentEnding: Timestamp | undefined;
    existingAppointments
      .sort(
        (a, b) => a.startDateTime.seconds - b.startDateTime.seconds || a.endDateTime.seconds - b.endDateTime.seconds
      )
      .forEach((appointment) => {
        // Check if a new appointment group needs to be started.
        if (lastAppointmentEnding && appointment.startDateTime >= lastAppointmentEnding) {
          // The appointment is later than any of the appointments in the
          // current group. There is no overlap. Output the
          // current appointments group and start a new one.
          g.push(columns);
          columns = [];
          lastAppointmentEnding = undefined;
        }

        // Try to place the appointment inside an existing column.
        let placed = false;
        columns.some((col) => {
          if (!collides(col[col.length - 1], appointment)) {
            col.push(appointment);
            placed = true;
          }
          return placed;
        });

        // It was not possible to place the appointment (it overlaps
        // with apps in each existing column). Add a new column
        // to the current appointment group with the appointment in it.
        if (!placed) columns.push([appointment]);

        // Remember the last appointment end time of the current group.
        if (!lastAppointmentEnding || appointment.endDateTime > lastAppointmentEnding) {
          lastAppointmentEnding = appointment.endDateTime;
        }
      });
    g.push(columns);
    setAppGroups(g);
  }, [collides, existingAppointments]);

  const expand = (a: IAppointmentDao, index: number, cols: IAppointmentDao[][]) => {
    let colSpan = 1;
    cols.slice(index + 1).some((col) => {
      if (col.some((app) => collides(a, app))) return true;
      colSpan += 1;
      return false;
    });
    return colSpan;
  };

  return (
    <div
      className={clsx(
        'w-full min-w-[200px] flex flex-col border-r last:border-r-0',
        resource.uid === highlightedResource && 'bg-blue-50'
      )}
    >
      <Tooltip
        title={showAppointmentMenu ? t('calendar.calendar_column.resource_header.view_todays_route') : undefined}
        color={primary.bg}
        arrow={false}
      >
        <div
          className={clsx(
            'bg-gray-50 p-4 text-center font-semibold shrink-0 grow-0 sticky top-0 z-30 transition ease-in-out',
            showAppointmentMenu && 'cursor-pointer hover:bg-gray-100'
          )}
          style={{ flexBasis: `${resourceHeaderHeight}px` }}
          onClick={() => {
            if (!showAppointmentMenu) {
              return;
            }

            dialog?.openDialog(<TimelinePreviewDialog appointments={existingAppointments} resource={resource} />);
          }}
        >
          {resource.fullName}
        </div>
      </Tooltip>

      <div className='relative'>
        {newAppointment && highlightedResource === resource.uid && (
          <div
            className='absolute w-full rounded-md shadow-sm text-white z-20 p-2 overflow-y-auto bg-opacity-10'
            style={getAppointmentStyle(
              startHour,
              endHour,
              newAppointment.start,
              newAppointment.end,
              false,
              1,
              0,
              timeSlotHeight,
              '#1d4ed8'
            )}
          >
            <p className='font-semibold'>{t('calendar.calendar_column.new_appointment')}</p>
            <p className='body-xs'>
              {newAppointment.start} - {newAppointment.end}
            </p>
          </div>
        )}
        {appGroups.map((cols) =>
          cols.map((col, index) =>
            col
              .filter((appointment) => appointment.uid !== appointmentUid)
              .map((appointment) => (
                <SharedCalendarAppointment
                  key={appointment.uid}
                  appointment={appointment}
                  timeSlotHeight={timeSlotHeight}
                  widthPercent={expand(appointment, index, cols) / cols.length}
                  leftPercent={index / cols.length}
                  showAppointmentMenu={showAppointmentMenu}
                />
              ))
          )
        )}
        {timeSlots.map((slot) => (
          <div className='border-t' style={{ height: `${timeSlotHeight}px` }} key={slot} />
        ))}
      </div>
    </div>
  );
};

export default SharedCalendarColumn;
