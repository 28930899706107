import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import { CheckCircleTwoTone, LockTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { useState } from 'react';
import { OrderFormSettingsApiService } from 'core/api';
import { useUserState } from 'core/providers/user-provider';
import { arrayRemove, arrayUnion } from 'firebase/firestore';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { Alert, App } from 'antd';
import FieldSetting, { IFieldSettingValue } from '../field-setting';
import { orderFormDefaultFields } from 'core/constants/organisation-forms';
import { Permission } from 'core/constants/permission';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

const EditOrderFormSettings = () => {
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);

  const { t } = useTranslation();
  const [fieldsLoading, setFieldsLoading] = useState<string[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const errorState = !orderFormSettings || !orderFormSettings.data || orderFormSettings.status === 'error';

  const setDisabled = async (key: string, value: boolean) => {
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation UID not found');
      }

      setFieldsLoading((prevState) => [...prevState, key]);

      await OrderFormSettingsApiService.update(userData.organisationUid, {
        disabledFields: value ? arrayRemove(key) : arrayUnion(key),
        updated: getActionTimestampFromUser(userData),
      });

      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));

      message.success(t('form_settings.order_form.edit.success'));
    } catch (error) {
      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));
      message.error(t('form_settings.order_form.edit.error'));
      sentryCaptureException(error, 'Edit order form settings', userData);
    }
  };

  const setValue = async (key: string, value: IFieldSettingValue) => {
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation UID not found');
      }
      setFieldsLoading((prevState) => [...prevState, key]);

      await OrderFormSettingsApiService.update(userData.organisationUid, {
        [`defaultFields.${key}`]: value,
        updated: getActionTimestampFromUser(userData),
      });

      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));

      message.success(t('form_settings.order_form.edit.success'));
    } catch (error) {
      setFieldsLoading((prevState) => prevState.filter((item) => item !== key));
      message.error(t('form_settings.order_form.edit.error'));
      sentryCaptureException(error, 'Edit order form settings', userData);
    }
  };

  const getFieldIcon = (isDisabled: boolean, isLocked: boolean) => {
    let icon = <CheckCircleTwoTone twoToneColor='#22c55e' />;

    if (isDisabled) {
      icon = <CloseCircleTwoTone twoToneColor='#ef4444' />;
    } else if (isLocked) {
      icon = <LockTwoTone twoToneColor='#a3a3a3' />;
    }

    return icon;
  };

  const isFieldDisabled = (fieldKey: string) => orderFormSettings?.data?.disabledFields.includes(fieldKey) ?? false;

  return (
    <>
      <SharedPageHeader title={t('form_settings.edit_order_form')} showBack />
      {errorState ? (
        <Alert message={t('form_settings.order_form.error_loading_data')} type='error' showIcon />
      ) : (
        <SharedCard>
          {orderFormDefaultFields.map((field) => {
            const isDisabled = isFieldDisabled(field.key);
            const fieldValue = orderFormSettings?.data?.defaultFields[field.key];

            return (
              <FieldSetting
                key={field.key}
                setValue={setValue}
                setDisabled={setDisabled}
                fieldKey={field.key}
                labelKey={field.labelKey}
                isDisabled={isDisabled}
                fieldValue={fieldValue}
                isLocked={field.locked}
                isLoading={fieldsLoading.includes(field.key)}
                statusIcon={getFieldIcon(isDisabled, field.locked)}
                selectOptions={field.options}
                permissions={[Permission.FORM_SETTINGS_UPDATE]}
              />
            );
          })}
        </SharedCard>
      )}
    </>
  );
};

export default EditOrderFormSettings;
