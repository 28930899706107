import { Outlet } from 'react-router-dom';
import OrganisationSettingsMenu from './organisation-settings-menu';

const OrganisationSettingLayout = () => {
  return (
    <div className='grow flex flex-col md:flex-row'>
      <OrganisationSettingsMenu />
      <div className='grow overflow-y-auto px-4 pb-4 w-full flex flex-col'>
        <Outlet />
      </div>
    </div>
  );
};

export default OrganisationSettingLayout;
