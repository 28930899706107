import { Popover } from 'antd';
import { IAppointmentTypeCommunicationDao } from 'core/api/types/appointment-types.interface';
import { useTranslation } from 'react-i18next';
import { CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const AppointmentTypeCommunicationsDetails = (communication: IAppointmentTypeCommunicationDao) => {
  const { t } = useTranslation();

  const sections = [
    {
      key: 'sms',
      title: t('appointment_types.appointment_type_detail.communication.sms'),
      contentTitle: t('appointment_types.appointment_type_detail.communication.sms_content'),
      content: communication.sms.content,
      enabled: communication.sms.enabled,
    },
    {
      key: 'email',
      title: t('appointment_types.appointment_type_detail.communication.email'),
      contentTitle: t('appointment_types.appointment_type_detail.communication.email_content'),
      content: communication.email.content,
      enabled: communication.email.enabled,
    },
  ];

  return (
    <>
      {sections.map((section) => (
        <Popover
          key={section.key}
          content={section.content ?? t('appointment_types.appointment_type_detail.communication.disabled')}
          title={section.contentTitle}
          className='flex items-center space-x-1'
        >
          {section.enabled ? (
            <CheckCircleTwoTone twoToneColor='#22c55e' />
          ) : (
            <CloseCircleTwoTone twoToneColor='#ef4444' />
          )}
          <p>{section.title}</p>
        </Popover>
      ))}
    </>
  );
};

export default AppointmentTypeCommunicationsDetails;
