import { App } from 'antd';
import { OrderApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IOrderDao } from 'core/api/types/order.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SelectAppointmentDialog from 'shared/dialog/select-appointment-dialog';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import PatientOrder from './patient-order';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOrdersList = (patient: IPatientDao) => {
  const dialog = useDialog();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [orders, setOrders] = useState<IOrderDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = OrderApiService.onCollectionSnapshot(
      (snap) => {
        setOrders(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.orders.error'));
        sentryCaptureException(error, 'Patient file fetching orders', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  return (
    <SharedCard
      title={t('patients.patient.orders.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='patients.patient.order.create'
            appearance='link'
            primaryOverride
            onClick={() => dialog?.openDialog(<SelectAppointmentDialog patientUid={patient.uid} />)}
          />
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <div className='p-4'>
          <SkeletonElement width='90px' height='20px' />
          <SkeletonElement width='100%' height='200px' className='mt-4' />
        </div>
      ) : (
        <>
          {orders.length === 0 && <p className='p-4 text-gray-400'>{t('patients.patient.orders.no_orders')}</p>}
          {orders.map((order) => (
            <PatientOrder key={order.uid} {...order} />
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default PatientOrdersList;
