import { useEffect, useState } from 'react';
import Logo from 'assets/images/hearlink.svg';

const SharedAuthPageWrapper = ({ children }: any) => {
  const [screenHeight, setScreenHeight] = useState(`${window.innerHeight}px`);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));

    return () => {
      window.removeEventListener('resize', () => setScreenHeight(`${window.innerHeight}px`));
    };
  }, []);
  return (
    <div className='bg-gray-100 flex flex-col items-center justify-center p-4' style={{ minHeight: screenHeight }}>
      <img src={Logo} alt='hearlink-logo' className='max-h-[72px] mb-8 lg:mb-10 mx-auto px-16' />
      <div className='bg-white rounded-md p-7 shadow-md w-full md:w-[500px]'>{children}</div>
      <p className='body-sm text-gray-400 mt-6'>© {new Date().getFullYear()} HearLink</p>
    </div>
  );
};

export default SharedAuthPageWrapper;
