import { configureStore } from '@reduxjs/toolkit';
import organisationsReducer, { IDomainOrganisation } from 'modules/admin/organisations/admin-organisations-slice';
import organisationSettingsReducer, {
  IOrganisationSettings,
} from 'modules/organisation-settings/organisation-settings-slice';

export type DataStatus = 'loading' | 'error' | 'success';

export interface IListDataSlice<T> {
  data: T[];
  status: DataStatus;
}

export interface IObjectDataSlice<T> {
  data: T | undefined;
  status: DataStatus;
}

export interface StoreState {
  organisations: IListDataSlice<IDomainOrganisation>;
  organisationSettings: Partial<IOrganisationSettings>;
}

export default configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    organisations: organisationsReducer,
    organisationSettings: organisationSettingsReducer,
  },
});
