export enum TM {
  INTACT = 'intact',
  NOT_INTACT = 'notIntact',
}

export const TMData = {
  [TM.INTACT]: {
    value: TM.INTACT,
    translationLabelKey: 'tm.intact',
  },
  [TM.NOT_INTACT]: {
    value: TM.NOT_INTACT,
    translationLabelKey: 'tm.not_intact',
  },
};

export const TMOptions = [TM.INTACT, TM.NOT_INTACT];
