export enum Library {
  CORE = 'core',
  MAPS = 'maps',
  PLACES = 'places',
  GEOCODING = 'geocoding',
  ROUTES = 'routes',
  MARKER = 'marker',
  GEOMETRY = 'geometry',
  ELEVATION = 'elevation',
  STREETVIEW = 'streetView',
  JOURNEY_SHARING = 'journeySharing',
  DRAWING = 'drawing',
  VISUALIZATION = 'visualization',
}

export const GoogleMapsLibraries: Library[] = [Library.PLACES, Library.DRAWING];
