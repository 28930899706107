import { AppointmentsApiService } from 'core/api';
import { IAppointmentTypeDao, ILeadTypeDao } from 'core/api/types';
import { AppointmentLocation } from 'core/constants/appointment-location';
import { ControlType } from 'core/enums/control-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { where } from 'firebase/firestore';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';
import SharedCard from 'shared/card/card';
import SharedForm from 'shared/form/shared-form';
import { stringToColour } from 'shared/helpers/color-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SkeletonElement from 'shared/skeleton/skeleton-element';

type IFinanceSnapShotDates = [Dayjs | null, Dayjs | null] | null;

interface AppointmentTotalsReportFilterFormOutput {
  dateRange: IFinanceSnapShotDates;
  clinic?: string;
}

interface IAppointmentTotalsReportCounts {
  [key: string]: number;
}

interface IAppointmentTotalsTypeCount<T> {
  count: number;
  type: IDomainOrganisationDataType<T>;
}

const AppointmentTotalsReport = () => {
  const { t } = useTranslation();
  const [values, setValues] = useState<AppointmentTotalsReportFilterFormOutput>({
    dateRange: [dayjs().add(-7, 'day'), dayjs()],
  });
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const { userData } = useUserState();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState<IAppointmentTotalsReportCounts>();
  const [typeCounts, setTypeCounts] = useState<IAppointmentTotalsTypeCount<IAppointmentTypeDao>[]>([]);
  const [leadTypeCounts, setLeadTypeCounts] = useState<IAppointmentTotalsTypeCount<ILeadTypeDao>[]>([]);
  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const leadTypeState = useSelector(OrganisationSettingsSlice.selectLeadTypes);

  const getCounts = useCallback(async () => {
    if (userData) {
      setLoading(true);
      const baseConstraints = [where('organisationUid', '==', userData?.organisationUid)];
      if (values.clinic) {
        baseConstraints.push(where('clinic', '==', values.clinic));
      }

      if (values.dateRange && values.dateRange[0] && values.dateRange[1]) {
        baseConstraints.push(
          where('startDateTime', '>=', values.dateRange[0].startOf('day').toDate()),
          where('startDateTime', '<=', values.dateRange[1].endOf('day').toDate())
        );
      }
      try {
        const basicPromises = [
          AppointmentsApiService.getCollectionCount([...baseConstraints]),
          AppointmentsApiService.getCollectionCount([
            ...baseConstraints,
            where('location', '==', AppointmentLocation.HOME),
          ]),
          AppointmentsApiService.getCollectionCount([
            ...baseConstraints,
            where('location', '==', AppointmentLocation.CLINIC),
          ]),
        ];
        const typeCountPromises =
          appointmentTypeState?.data.map((type) => {
            return new Promise<IAppointmentTotalsTypeCount<IAppointmentTypeDao>>(async (resolve, reject) => {
              try {
                const snap = await AppointmentsApiService.getCollectionCount([
                  ...baseConstraints,
                  where('type', '==', type.uid),
                ]);
                resolve({
                  count: snap.data().count,
                  type,
                });
              } catch (error) {
                reject(error);
              }
            });
          }) ?? [];
        const leadTypeCountPromises =
          leadTypeState?.data.map((type) => {
            return new Promise<IAppointmentTotalsTypeCount<ILeadTypeDao>>(async (resolve, reject) => {
              try {
                const snap = await AppointmentsApiService.getCollectionCount([
                  ...baseConstraints,
                  where('patient.referral', '==', type.uid),
                ]);
                resolve({
                  count: snap.data().count,
                  type,
                });
              } catch (error) {
                reject(error);
              }
            });
          }) ?? [];
        const [[total, home, clinic], typeCountResult, leadTypeCountResult] = await Promise.all([
          Promise.all(basicPromises),
          Promise.all(typeCountPromises),
          Promise.all(leadTypeCountPromises),
        ]);
        setTypeCounts(typeCountResult);
        setLeadTypeCounts(leadTypeCountResult);
        setCounts({ total: total.data().count, home: home.data().count, clinic: clinic.data().count });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  }, [appointmentTypeState?.data, leadTypeState?.data, userData, values.clinic, values.dateRange]);

  useEffect(() => {
    getCounts();
  }, [getCounts]);

  const widgets = [
    {
      key: 'total',
      label: 'Total appointments',
      value: counts?.total,
    },
    {
      key: 'home',
      label: 'At home',
      value: counts?.home,
    },
    {
      key: 'clinic',
      label: 'In clinic',
      value: counts?.clinic,
    },
  ];

  const typePieData = typeCounts.map(({ count, type }) => {
    return {
      name: type.name,
      value: count,
      color: type.colour,
    };
  });

  const leadTypePieData = leadTypeCounts.map(({ count, type }) => {
    return {
      name: type.name,
      value: count,
      color: stringToColour(type.name),
    };
  });

  return (
    <>
      <SharedPageHeader title={t('reporting.hub.appointment_reports.totals')} showBack />
      <SharedCard title={t('filters.title')}>
        <SharedForm<AppointmentTotalsReportFilterFormOutput>
          requiredMark={false}
          className='grid grid-cols-1 md:grid-cols-4 p-4'
          buttonsOverride={[]}
          layout='inline'
          onChange={(_, allValues) => {
            setValues(allValues);
          }}
          name='finance-overview-filters'
          fields={[
            {
              fieldKey: 'dateRange',
              control: ControlType.DateRangePicker,
              label: t('finance.overview.filters.form.date_range'),
              required: false,
              allowClear: false,
              maxDate: dayjs(),
            },
            {
              fieldKey: 'clinic',
              control: ControlType.Select,
              label: t('calendar.add_edit_appointment.form.clinic'),
              options: clinicsState?.data.map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? [],
              allowClear: true,
              required: false,
            },
          ]}
          existingValue={{
            dateRange: values.dateRange,
          }}
        />
      </SharedCard>
      <SharedCard innerClassName='grid md:grid-cols-3'>
        {widgets.map((widget) => (
          <div key={widget.key} className='border-b md:border-b-0 md:border-r p-4 md:last:border-r-0 last:border-b-0'>
            <p className='text-gray-500 mb-1'>{widget.label}</p>
            {loading ? (
              <SkeletonElement height='40px' width='40px' />
            ) : (
              <p className='text-4xl font-extralight'>{widget.value}</p>
            )}
          </div>
        ))}
      </SharedCard>
      <SharedCard
        title={t('reporting.hub.appointment_reports.totals.type_breakdown')}
        innerClassName='grid grid-cols-1 grid-cols-3'
      >
        <div className='col-span-1 md:col-span-2 border-r flex flex-col'>
          {typeCounts.map(({ count, type }) => (
            <div
              key={type.uid}
              className='grow shrink-0 flex items-center justify-between p-4 border-b last:border-0 relative'
            >
              <span
                className='absolute left-0 top-0 bottom-0 w-1'
                style={{
                  backgroundColor: type.colour,
                }}
              ></span>
              <p>{type.name}</p>
              <p>{count}</p>
            </div>
          ))}
        </div>
        <div className='flex items-center'>
          <ResponsiveContainer height={400} width='100%'>
            <PieChart>
              <Pie data={typePieData} dataKey='value' nameKey='name' label>
                {typePieData.map((entry) => (
                  <Cell key={entry.name} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </SharedCard>
      <SharedCard
        title={t('reporting.hub.appointment_reports.totals.lead_type_breakdown')}
        innerClassName='grid grid-cols-1 grid-cols-3'
      >
        <div className='col-span-1 md:col-span-2 border-r flex flex-col'>
          {leadTypeCounts.map(({ count, type }) => (
            <div
              key={type.uid}
              className='grow shrink-0 flex items-center justify-between p-4 border-b last:border-0 relative'
            >
              <span
                className='absolute left-0 top-0 bottom-0 w-1'
                style={{
                  backgroundColor: stringToColour(type.name),
                }}
              ></span>
              <p>{type.name}</p>
              <p>{count}</p>
            </div>
          ))}
        </div>
        <div className='flex items-center'>
          <ResponsiveContainer height={400} width='100%'>
            <PieChart>
              <Pie data={leadTypePieData} dataKey='value' nameKey='name' label>
                {leadTypePieData.map((entry) => (
                  <Cell key={entry.name} fill={entry.color} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </SharedCard>
    </>
  );
};

export default AppointmentTotalsReport;
