import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOrganisationDao } from 'core/api/types';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { App } from 'antd';
import { OrganisationsApiService } from 'core/api';
import { OrganisationStatus } from 'core/constants/organisation-status';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';

interface IAdminDeactivateOrganisationDialog {
  organisation: IOrganisationDao;
}

const AdminDeactivateOrganisationDialog = ({ organisation }: IAdminDeactivateOrganisationDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { userData } = useUserState();

  const deactivate = async () => {
    setLoading(true);
    try {
      const userTimestamp = getActionTimestampFromUser(userData);
      await OrganisationsApiService.update(organisation.uid, {
        status: OrganisationStatus.INACTIVE,
        updated: userTimestamp,
      });
      dialog?.closeDialog();
      message.success(t('dialog.deactivate_organisation.success'));
    } catch (error) {
      message.error(t('dialog.deactivate_organisation.error'));
      setLoading(false);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteUser',
      props: {
        loading,
        onClick: () => deactivate(),
        danger: true,
        labelKey: 'common.deactivate',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.deactivate_organisation.title', { orgName: organisation.name })}
      textContent={t('dialog.deactivate_organisation.content')}
      customButtons={customButtons}
    />
  );
};

export default AdminDeactivateOrganisationDialog;
