import { Navigate, Route, Routes } from 'react-router-dom';
import StockManagementOverview from './stock-management-overview';
import StockManagementHearingAidList from './stock-management-hearing-aid-list';
import StockManagementAccessoriesList from './stock-management-accessories-list';

const StockManagementRouting = () => {
  return (
    <Routes>
      <Route path='' element={<StockManagementOverview />} />
      <Route path='hearing-aids' element={<StockManagementHearingAidList />} />
      <Route path='accessories' element={<StockManagementAccessoriesList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default StockManagementRouting;
