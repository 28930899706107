export enum CommunicationMethod {
  EMAIL = 'email',
  SMS = 'sms',
  PHONE = 'phone',
}

export const CommunicationMethodData = {
  [CommunicationMethod.EMAIL]: {
    translationLabelKey: 'communication_method.email',
    value: CommunicationMethod.EMAIL,
  },
  [CommunicationMethod.SMS]: {
    translationLabelKey: 'communication_method.sms',
    value: CommunicationMethod.SMS,
  },
  [CommunicationMethod.PHONE]: {
    translationLabelKey: 'communication_method.phone',
    value: CommunicationMethod.PHONE,
  },
};
