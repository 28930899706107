export enum YesNo {
  YES = 'yes',
  NO = 'no',
}

export const YesNoData = {
  [YesNo.YES]: {
    value: YesNo.YES,
    translationLabelKey: 'common.yes',
  },
  [YesNo.NO]: {
    value: YesNo.NO,
    translationLabelKey: 'common.no',
  },
};

export const YesNoOptions = [YesNo.YES, YesNo.NO];
