export enum FirebaseFunctionName {
  CREATE_ADMIN = 'createAdmin',
  CREATE_ORGANISATION = 'createOrganisation',
  CREATE_ORGANISATION_OWNER = 'createOrganisationOwner',
  CREATE_MEMBER = 'createMember',
  CREATE_GROUP = 'createGroup',
  SEARCH_USER = 'searchUser',
  DELETE_USER = 'deleteUser',
  ADMIN_DELETE_USER = 'adminDeleteUser',
  SEARCH_PATIENT = 'searchPatient',
  RESET_ORGANISATION_SETTINGS = 'resetOrganisationSettings',
  GET_SIGNED_FILE_URL = 'getSignedFileUrl',
}
