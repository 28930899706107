import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';

const ReportingHub = () => {
  const { t } = useTranslation();
  const reportingModules = [
    {
      key: 'appointments',
      labelKey: 'reporting.hub.appointment_reports',
      reports: [{ key: 'count', path: 'appointments/total', labelKey: 'reporting.hub.appointment_reports.totals' }],
    },
    {
      key: 'sales',
      labelKey: 'reporting.hub.sales_reports',
      reports: [],
    },
    {
      key: 'orders',
      labelKey: 'reporting.hub.orders_reports',
      reports: [],
    },
    {
      key: 'stock',
      labelKey: 'reporting.hub.stock_reports',
      reports: [],
    },
    {
      key: 'marketing',
      labelKey: 'reporting.hub.marketing_reports',
      reports: [],
    },
    {
      key: 'patient',
      labelKey: 'reporting.hub.patient_reports',
      reports: [],
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('reporting.hub')} />
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 h-max'>
        {reportingModules.map((section) => (
          <SharedCard key={section.key} title={t(section.labelKey)} outerClassName='h-fit'>
            {section.reports.map((report) => (
              <div
                key={report.key}
                className='p-4 flex items-center justify-between min-h-[72px] border-b last:border-b-0'
              >
                <p>{t(report.labelKey)}</p>
                <Link to={report.path}>
                  <SharedButton appearance='link' labelKey='common.view' primaryOverride />
                </Link>
              </div>
            ))}
            {section.reports.length === 0 && (
              <Alert showIcon className='m-4' message={t('reporting.hub.no_reports')} type='info' />
            )}
          </SharedCard>
        ))}
      </div>
    </>
  );
};

export default ReportingHub;
