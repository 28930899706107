interface IFormItemMimic {
  label: string;
  children: any;
  className?: string;
}

const FormItemMimic = ({ label, children, className = 'mb-4' }: IFormItemMimic) => {
  return (
    <div className={className}>
      <label className='relative text-[14px] inline-flex max-w-full pb-[2px]' style={{ color: 'rgba(0, 0, 0, 0.88)' }}>
        {label}
      </label>
      <div>{children}</div>
    </div>
  );
};

export default FormItemMimic;
