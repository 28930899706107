import { createContext, useContext, useEffect, useState } from 'react';
import hearLinkLogo from 'assets/images/hearlink.svg';
import hearLinkLogoWhite from 'assets/images/hearlink-white.svg';
import { useUserState } from './user-provider';
import { getDownloadURL, ref } from 'firebase/storage';
import { IOrganisationDao } from 'core/api/types';
import { storage } from 'core/config/firebase';
import { ConfigProvider } from 'antd';
import { Helmet } from 'react-helmet';

interface IThemeColor {
  bg: string;
  text: string;
}

export interface IThemeContext {
  theme: ITheme;
  themeLoading: boolean;
}

export interface ITheme {
  primary: IThemeColor;
  logo: string;
  logoWhite: string;
  logoDark: string;
  title: string;
}

const defaultTheme = {
  primary: {
    bg: '#0AC8C8',
    text: '#ffffff',
  },
  logo: hearLinkLogo,
  logoWhite: hearLinkLogoWhite,
  logoDark: hearLinkLogo,
  title: 'HearLink',
};

export const ThemeContext = createContext<IThemeContext>({
  theme: defaultTheme,
  themeLoading: true,
});

export const useThemeState = () => {
  return useContext(ThemeContext);
};

export const useTheme = () => {
  const state = useContext(ThemeContext);
  return state.theme;
};

export const ThemeProvider = ({ children }: any) => {
  const [theme, setTheme] = useState<ITheme>(defaultTheme);
  const [themeLoading, setThemeLoading] = useState(true);

  const { organisationData } = useUserState();
  useEffect(() => {
    const applyOrgThemeData = async (org: IOrganisationDao) => {
      setThemeLoading(true);
      const whiteLogo = await getDownloadURL(ref(storage, `logos/${org.uid}/logoWhite.png`));
      const darkLogo = await getDownloadURL(ref(storage, `logos/${org.uid}/logoDark.png`));

      setTheme((prevState) => ({
        ...prevState,
        primary: {
          bg: org.themeData.primaryBg,
          text: org.themeData.primaryText,
        },
        title: org.name,
        logoWhite: whiteLogo,
        logoDark: darkLogo,
      }));
      setThemeLoading(false);
    };
    if (organisationData) {
      applyOrgThemeData(organisationData);
    } else {
      setTheme(defaultTheme);
      setThemeLoading(false);
    }
  }, [organisationData]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeLoading,
      }}
    >
      <Helmet>
        <title>{organisationData ? `${organisationData?.name} • HearLink` : 'HearLink'}</title>
        <meta name='theme-color' content={organisationData ? organisationData?.themeData.primaryBg : '#0AC8C8'} />
      </Helmet>
      <ConfigProvider
        theme={{
          components: { Form: { itemMarginBottom: 16, verticalLabelPadding: '0 0 2px' } },
          token: { colorPrimary: theme.primary.bg },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};
