import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import StockManagementOverviewCounts from './stock-management-overview-counts';
import { IStockDao } from 'core/api/types/stock.interface';
import StockManagementStockTable from './stock-management-stock-table';

const StockManagementOverview = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const tableKey = 'stock_management.stock_list.table';

  const itemTemplate = (stock: IStockDao) => {
    let values = [];
    switch (stock.type) {
      case 'hearingAid':
        values = [stock.manufacturer, stock.model, stock.style, stock.power, stock.colour];
        break;
      case 'accessory':
        values = [stock.manufacturer, stock.accessoryName];
        break;
    }

    return <p>{values.filter((value) => Boolean(value)).join(' / ')}</p>;
  };

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='stock_management.overview.header.add_stock'
            onClick={() => dialog?.openDialog(<AddEditStockDialog tableKey={tableKey} />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addStock',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.stock')} actions={headerActions} />
      <StockManagementOverviewCounts />
      <StockManagementStockTable
        title={t('stock_management.overview.table.title')}
        additionalColumns={[
          {
            labelKey: 'stock_management.overview.table.header.item',
            key: 'item',
            contentTemplateId: 'item',
            width: 400,
          },
        ]}
        additionalContentTemplates={[
          {
            id: 'item',
            template: itemTemplate,
          },
        ]}
        tableKey={tableKey}
      />
    </>
  );
};

export default StockManagementOverview;
