import { App } from 'antd';
import { OrderApiService, StockApiService, TransactionApiService } from 'core/api';
import { StockStatus } from 'core/constants/stock-status';
import { useUserState } from 'core/providers/user-provider';
import { Dayjs } from 'dayjs';
import { average, sum, where } from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { formatToCurrency } from 'shared/helpers/currency-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SkeletonElement from 'shared/skeleton/skeleton-element';

interface IFinanceOverviewSnapshots {
  selectedDateRange: [Dayjs, Dayjs];
}
const FinanceOverviewSnapshots = ({ selectedDateRange }: IFinanceOverviewSnapshots) => {
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [totalRefunds, setTotalRefunds] = useState<number>(0);
  const [aov, setAov] = useState<number>(0);
  const [totalStockCost, setTotalStockCost] = useState<number>(0);
  const { message } = App.useApp();
  const { userData, organisationData } = useUserState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const getSnapshotData = useCallback(async () => {
    if (selectedDateRange && selectedDateRange[0] && selectedDateRange[1] && userData) {
      setLoading(true);
      try {
        const [rev, refunds, aov, stock] = await Promise.all([
          TransactionApiService.getCollectionAggregate(
            [
              where('organisationUid', '==', userData?.organisationUid),
              where('transactionType', '==', 'charge'),
              where('date', '>=', selectedDateRange[0].startOf('day').toDate()),
              where('date', '<=', selectedDateRange[1].endOf('day').toDate()),
            ],
            {
              totalAmount: sum('amount'),
            }
          ),
          TransactionApiService.getCollectionAggregate(
            [
              where('organisationUid', '==', userData?.organisationUid),
              where('transactionType', '==', 'refund'),
              where('date', '>=', selectedDateRange[0].startOf('day').toDate()),
              where('date', '<=', selectedDateRange[1].endOf('day').toDate()),
            ],
            {
              totalAmount: sum('amount'),
            }
          ),
          OrderApiService.getCollectionAggregate(
            [
              where('organisationUid', '==', userData?.organisationUid),
              where('orderDate', '>=', selectedDateRange[0].startOf('day').toDate()),
              where('orderDate', '<=', selectedDateRange[1].endOf('day').toDate()),
            ],
            {
              averagePrice: average('finalPrice'),
            }
          ),
          StockApiService.getCollectionAggregate(
            [
              where('organisationUid', '==', userData?.organisationUid),
              where('status', '==', StockStatus.SOLD),
              where('updated.at', '>=', selectedDateRange[0].startOf('day').toDate()),
              where('updated.at', '<=', selectedDateRange[1].endOf('day').toDate()),
            ],
            {
              totalCost: sum('cost'),
            }
          ),
        ]);
        setTotalRevenue(rev.data().totalAmount ?? 0);
        setTotalRefunds(refunds.data().totalAmount ?? 0);
        setTotalStockCost(stock.data().totalCost ?? 0);
        setAov(aov.data().averagePrice ?? 0);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(t('finance.overview.snapshots.error'));
        sentryCaptureException(error, 'Finance snapshot data fetch error', userData);
      }
    }
  }, [message, selectedDateRange, t, userData]);

  useEffect(() => {
    getSnapshotData();
  }, [getSnapshotData]);

  const sections = [
    {
      key: 'totalRevenue',
      labelKey: 'finance.overview.snapshots.total_revenue',
      value: formatToCurrency(totalRevenue, organisationData?.finance.currency),
    },
    {
      key: 'totalRefunds',
      labelKey: 'finance.overview.snapshots.total_refunds',
      value: formatToCurrency(totalRefunds, organisationData?.finance.currency),
    },
    {
      key: 'profit',
      labelKey: 'finance.overview.snapshots.profit',
      value: formatToCurrency(totalRevenue - totalStockCost, organisationData?.finance.currency),
    },
    {
      key: 'averageOrderValue',
      labelKey: 'finance.overview.snapshots.average_order_value',
      value: formatToCurrency(aov, organisationData?.finance.currency),
    },
  ];

  return (
    <SharedCard innerClassName='grid md:grid-cols-4'>
      {sections.map((section) => (
        <div key={section.key} className='border-b md:border-b-0 md:border-r p-4 md:last:border-r-0 last:border-b-0'>
          <p className='text-gray-500 mb-1'>{t(section.labelKey)}</p>
          {loading ? (
            <SkeletonElement height='60px' width='70px' />
          ) : (
            <p className='text-4xl font-extralight'>{section.value}</p>
          )}
        </div>
      ))}
    </SharedCard>
  );
};

export default FinanceOverviewSnapshots;
