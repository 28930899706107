import { Alert } from 'antd';
import { IAppointmentTypeDao } from 'core/api/types';
import { IAppointmentTypeOutcomeDao } from 'core/api/types/appointment-types.interface';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import { ThunderboltOutlined } from '@ant-design/icons';
import { useDialog } from 'core/providers/dialog-provider';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { AppointmentTypesApiService } from 'core/api';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { arrayRemove } from 'firebase/firestore';
import AddEditAppointmentTypeOutcomeDialog from './add-edit-appointment-type-outcome-dialog';

const AppointmentTypeDetailOutcomes = (appointmentType: IAppointmentTypeDao) => {
  const { t } = useTranslation();
  const workflowStatuses = useSelector(OrganisationSettingsSlice.selectPatientWorkflowStatuses);
  const dialog = useDialog();
  const { userData } = useUserState();

  const getOutcomeStatus = (outcome: IAppointmentTypeOutcomeDao) => {
    if (!outcome.statusTrigger) return <></>;

    const status = workflowStatuses?.find((status) => status.key === outcome.statusTrigger);

    return status ? (
      <div className='flex items-center space-x-1 text-blue-600'>
        <ThunderboltOutlined className='text-blue-600' />
        <p>{status.name}</p>
      </div>
    ) : (
      <Alert
        message={t('appointment_types.appointment_type_detail.outcome.status_not_found')}
        className='text-xs'
        type='error'
        showIcon
      />
    );
  };

  return (
    <SharedCard
      title={t('appointment_types.appointment_type_detail.outcomes')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='appointment_types.appointment_type_detail.outcomes.add_outcome'
            onClick={() =>
              dialog?.openDialog(
                <AddEditAppointmentTypeOutcomeDialog
                  appointmentType={appointmentType}
                  excludedStatuses={appointmentType.outcomes.map((oc) => oc.statusTrigger ?? '')}
                />
              )
            }
          />
        </SharedElementPermissionGuard>
      }
    >
      {appointmentType?.outcomes.map((outcome) => (
        <div key={outcome.key} className='flex items-center justify-between p-3 border-b last:border-b-0'>
          <div className='flex flex-col items-start space-y-1 md:space-y-0 md:flex-row md:space-x-4 md:items-center'>
            <p className='px-2 py-1 rounded-md text-white' style={{ backgroundColor: outcome.bannerColour }}>
              {outcome.bannerLabel}
            </p>
            {getOutcomeStatus(outcome)}
          </div>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
          >
            <div className='flex space-x-4'>
              <SharedButton
                appearance='link'
                danger
                labelKey='common.remove'
                onClick={() =>
                  dialog?.openDialog(
                    <ConfirmActionDialog
                      action={() =>
                        AppointmentTypesApiService.update(appointmentType.uid, {
                          updated: getActionTimestampFromUser(userData),
                          outcomes: arrayRemove(outcome),
                        })
                      }
                      actionButtonProps={{
                        labelKey: 'common.remove',
                        danger: true,
                      }}
                      title={t('appointment_types.appointment_type_detail.outcomes.remove_outcome.title', {
                        outcomeName: outcome.bannerLabel,
                      })}
                      textContent={t('appointment_types.appointment_type_detail.outcomes.remove_outcome.content')}
                      successMessage={t('appointment_types.appointment_type_detail.outcomes.remove_outcome.success')}
                      errorMessage={t('appointment_types.appointment_type_detail.outcomes.remove_outcome.error')}
                    />
                  )
                }
              />
              <SharedButton
                appearance='link'
                labelKey='common.edit'
                primaryOverride
                onClick={() =>
                  dialog?.openDialog(
                    <AddEditAppointmentTypeOutcomeDialog appointmentType={appointmentType} outcome={outcome} />
                  )
                }
              />
            </div>
          </SharedElementPermissionGuard>
        </div>
      ))}
      {appointmentType?.outcomes.length === 0 && (
        <p className='text-gray-400 p-4'>{t('appointment_types.appointment_type_detail.outcomes.no_outcomes')}</p>
      )}
    </SharedCard>
  );
};

export default AppointmentTypeDetailOutcomes;
