export enum CommunicationStatus {
  SCHEDULED = 'scheduled',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export const CommunicationStatusData = {
  [CommunicationStatus.SCHEDULED]: {
    translationLabelKey: 'communication_status.scheduled',
    value: CommunicationStatus.SCHEDULED,
    color: 'orange',
  },
  [CommunicationStatus.SUCCESS]: {
    translationLabelKey: 'communication_status.success',
    value: CommunicationStatus.SUCCESS,
    color: 'green',
  },
  [CommunicationStatus.FAILURE]: {
    translationLabelKey: 'communication_status.failure',
    value: CommunicationStatus.FAILURE,
    color: 'red',
  },
};
