import { Navigate, Route, Routes } from 'react-router-dom';
import AdminOrganisationsList from './admin-organisations-list';

const AdminOrganisationsRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AdminOrganisationsList />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AdminOrganisationsRouting;
