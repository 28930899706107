import { App, GetProp, Upload, UploadFile, UploadProps } from 'antd';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { UploadCloud } from 'react-feather';

interface ISharedFileUpload {
  id?: string;
  value?: UploadFile[];
  onChange?: (value?: UploadFile<any>[]) => void;
  disabled?: boolean;
  acceptedFileTypes?: string[];
  maxFileSize?: number;
  maxCount?: number;
  showUploadList?: boolean;
}

export type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const SharedFileUpload = ({
  id,
  value = [],
  onChange,
  disabled,
  acceptedFileTypes,
  maxFileSize,
  maxCount,
  showUploadList,
}: ISharedFileUpload) => {
  const { message } = App.useApp();
  const { t } = useTranslation();

  const uploadProps: UploadProps = {
    onRemove: (file: UploadFile) => {
      const index = value.indexOf(file);
      const newList = [...value];
      newList.splice(index, 1);
      onChange?.(newList);
    },
    beforeUpload: (file: FileType) => {
      if (acceptedFileTypes && !acceptedFileTypes.includes(file.type)) {
        message.info(t('field.file.file_type_not_accepted'));
        return;
      }

      if (maxFileSize && file.size > maxFileSize) {
        message.info(t('field.file.too_large'));
        return;
      }

      if (maxCount && value.length >= maxCount) {
        message.info(t('field.file.max_files_reached'));
        return;
      }

      onChange?.([...value, file]);

      return false;
    },
    showUploadList,
    maxCount,
    fileList: value,
  };

  return (
    <Upload id={id} disabled={disabled} {...uploadProps}>
      <SharedButton icon={<UploadCloud size={16} />} labelKey='field.file.button' />
    </Upload>
  );
};

export default SharedFileUpload;
