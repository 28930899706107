import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { IAudiometerDao } from 'core/api/types';
import { AudiometersApiService } from 'core/api';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useTable } from 'core/providers/table-data-provider';
import { App } from 'antd';
import { Timestamp } from 'firebase/firestore';
import dayjs, { Dayjs } from 'dayjs';

interface IAddAudiometersFormOutput {
  make: string;
  model: string;
  serialNumber: string;
  calibrationDate: Dayjs;
}

interface IAddEditAudiometersDialog {
  existing?: IAudiometerDao;
  tableKey: string;
}
const AddEditAudiometersDialog = ({ existing, tableKey }: IAddEditAudiometersDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;
  const table = useTable(tableKey);

  const AddAudiometersFormFields: ISharedField[] = [
    {
      fieldKey: 'make',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('audiometers.add_edit_audiometers.make'),
      required: true,
    },
    {
      fieldKey: 'model',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('audiometers.add_edit_audiometers.model'),
      required: true,
    },
    {
      fieldKey: 'serialNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('audiometers.add_edit_audiometers.serial_number'),
      required: true,
    },
    {
      fieldKey: 'calibrationDate',
      control: ControlType.DatePicker,
      label: t('audiometers.add_edit_audiometers.calibration_date'),
      required: true,
    },
  ];

  const submit = async (data: IAddAudiometersFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        ...data,
        calibrationDate: Timestamp.fromDate(data.calibrationDate.toDate()),
        updated: userTimestamp,
        organisationUid: userData.organisationUid,
      };
      if (creating) {
        await AudiometersApiService.set({
          ...basePayload,
          uid: uuidv4(),
          created: userTimestamp,
          deleted: false,
        });
        message.success(t('dialog.add_edit_audiometers.create.success.description'));
      } else {
        await AudiometersApiService.update(existing.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_edit_audiometers.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.refreshTable();
    } catch (err) {
      message.error(
        t(
          existing
            ? 'dialog.add_edit_audiometers.edit.error.description'
            : 'dialog.add_edit_audiometers.create.error.description'
        )
      );
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddAudiometersFormOutput>
        onFinish={submit}
        fields={AddAudiometersFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-audiometers'
        existingValue={existing && { ...existing, calibrationDate: dayjs(existing?.calibrationDate.toDate()) }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_audiometers.create.title' : 'dialog.add_edit_audiometers.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditAudiometersDialog;
