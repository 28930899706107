import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { OrderFormSettingsApiService, PatientFormSettingsApiService } from 'core/api';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { arrayUnion } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { App } from 'antd';
import { ICustomFormField } from 'core/api/types';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../organisation-settings-slice';
import { CollectionID } from 'core/constants/collection-id';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IFormSettingsAddEditCustomField {
  collectionID: string;
  customField?: ICustomFormField;
}

interface IFormSettingsAddEditCustomFieldFormOutput {
  label: string;
}

const FormSettingsAddEditCustomField = ({ collectionID, customField }: IFormSettingsAddEditCustomField) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { t } = useTranslation();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const creating = !customField;

  const selectorMap = {
    [CollectionID.PATIENT_FORM_SETTINGS]: OrganisationSettingsSlice.selectPatientFormSettings,
    [CollectionID.ORDER_FORM_SETTINGS]: OrganisationSettingsSlice.selectOrderFormSettings,
  };

  type SelectorMapKeys = keyof typeof selectorMap;

  const formSettings = useSelector(selectorMap[collectionID as SelectorMapKeys]);

  const fields: ISharedField[] = [
    {
      fieldKey: 'label',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('form_settings.field_label'),
      required: true,
    },
  ];

  const create = async (data: IFormSettingsAddEditCustomFieldFormOutput) => {
    setLoading(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation now provided');
      }

      if (collectionID === CollectionID.PATIENT_FORM_SETTINGS) {
        await PatientFormSettingsApiService.update(userData.organisationUid, {
          customFields: arrayUnion({ label: data.label, key: uuidv4(), type: 'text', deleted: false }),
          updated: getActionTimestampFromUser(userData),
        });
      }

      if (collectionID === CollectionID.ORDER_FORM_SETTINGS) {
        await OrderFormSettingsApiService.update(userData.organisationUid, {
          customFields: arrayUnion({ label: data.label, key: uuidv4(), type: 'text', deleted: false, disabled: false }),
          updated: getActionTimestampFromUser(userData),
        });
      }

      dialog?.closeDialog();
      message.success(t('form_settings.add_custom_field.success'));
    } catch (error) {
      message.error(t('form_settings.add_custom_field.error'));
      setLoading(false);
      sentryCaptureException(error, 'Add form custom field', userData);
    }
  };

  const update = async (data: IFormSettingsAddEditCustomFieldFormOutput) => {
    setLoading(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error('Organisation now provided');
      }

      if (collectionID === CollectionID.PATIENT_FORM_SETTINGS) {
        await PatientFormSettingsApiService.update(userData.organisationUid, {
          customFields: formSettings?.data?.customFields
            .filter((field) => !field.deleted)
            .map((field) => {
              if (field.key === customField?.key) {
                return { ...field, label: data.label };
              } else {
                return field;
              }
            }),
          updated: getActionTimestampFromUser(userData),
        });
      }

      if (collectionID === CollectionID.ORDER_FORM_SETTINGS) {
        await PatientFormSettingsApiService.update(userData.organisationUid, {
          customFields: formSettings?.data?.customFields
            .filter((field) => !field.deleted)
            .map((field) => {
              if (field.key === customField?.key) {
                return { ...field, label: data.label };
              } else {
                return field;
              }
            }),
          updated: getActionTimestampFromUser(userData),
        });
      }
      dialog?.closeDialog();
      message.success(t('form_settings.edit_custom_field.success'));
    } catch (error) {
      message.error(t('form_settings.edit_custom_field.error'));
      setLoading(false);
      sentryCaptureException(error, 'Edit form custom field', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IFormSettingsAddEditCustomFieldFormOutput>
        onFinish={creating ? create : update}
        fields={fields}
        submitting={loading}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-user-form'
        existingValue={customField}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'form_settings.add_custom_field' : 'form_settings.edit_custom_field')}
      showButtons={false}
      customContentTemplate={customContent()}
    />
  );
};

export default FormSettingsAddEditCustomField;
