export enum MaritalStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  DIVORCED = 'divorced',
  WIDOWED = 'widowed',
  DOMESTIC_PARTNERSHIP = 'domesticPartnership',
  CIVIL_UNION = 'civilUnion',
  SEPARATED = 'separated',
  PREFER_NOT_TO_SAY = 'preferNotToSay',
}

export const MaritalStatusData = {
  [MaritalStatus.SINGLE]: {
    value: MaritalStatus.SINGLE,
    translationLabelKey: 'marital_status.single',
  },
  [MaritalStatus.MARRIED]: {
    value: MaritalStatus.MARRIED,
    translationLabelKey: 'marital_status.married',
  },
  [MaritalStatus.DIVORCED]: {
    value: MaritalStatus.DIVORCED,
    translationLabelKey: 'marital_status.divorced',
  },
  [MaritalStatus.WIDOWED]: {
    value: MaritalStatus.WIDOWED,
    translationLabelKey: 'marital_status.widowed',
  },
  [MaritalStatus.DOMESTIC_PARTNERSHIP]: {
    value: MaritalStatus.DOMESTIC_PARTNERSHIP,
    translationLabelKey: 'marital_status.domestic_partnership',
  },
  [MaritalStatus.CIVIL_UNION]: {
    value: MaritalStatus.CIVIL_UNION,
    translationLabelKey: 'marital_status.civil_union',
  },
  [MaritalStatus.SEPARATED]: {
    value: MaritalStatus.SEPARATED,
    translationLabelKey: 'marital_status.separated',
  },
  [MaritalStatus.PREFER_NOT_TO_SAY]: {
    value: MaritalStatus.PREFER_NOT_TO_SAY,
    translationLabelKey: 'marital_status.prefer_not_to_say',
  },
};

export const MaritalStatusOptions = [
  MaritalStatus.SINGLE,
  MaritalStatus.MARRIED,
  MaritalStatus.DIVORCED,
  MaritalStatus.WIDOWED,
  MaritalStatus.DOMESTIC_PARTNERSHIP,
  MaritalStatus.CIVIL_UNION,
  MaritalStatus.SEPARATED,
  MaritalStatus.PREFER_NOT_TO_SAY,
];
