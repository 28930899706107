export enum AppointmentLocation {
  CLINIC = 'clinic',
  HOME = 'home',
}

export const AppointmentLocationData = {
  [AppointmentLocation.CLINIC]: {
    value: AppointmentLocation.CLINIC,
    translationLabelKey: 'calendar.add_edit_appointment.form.location.clinic',
  },
  [AppointmentLocation.HOME]: {
    value: AppointmentLocation.HOME,
    translationLabelKey: 'calendar.add_edit_appointment.form.location.home',
  },
};

export const AppointmentLocationOptions = [AppointmentLocation.CLINIC, AppointmentLocation.HOME];
