import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import { PlusCircleOutlined } from '@ant-design/icons';
import AddEditWorkflowStatusDialog, { IAddEditStatusFormOutput } from './add-edit-workflow-status-dialog';
import { useDialog } from 'core/providers/dialog-provider';
import SharedCard from 'shared/card/card';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from '../../organisation-settings-slice';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IWorkflowStatusDao } from 'core/api/types/workflow.interface';
import { DndContext, DragEndEvent, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import WorkflowStatus from './workflow-status';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import _ from 'lodash';
import { WorkflowApiService } from 'core/api';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { MouseSensor, TouchSensor } from 'shared/helpers/dnd-kit-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

const WorkflowEditor = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = App.useApp();
  const dialog = useDialog();
  const currentWorkflow = useSelector(OrganisationSettingsSlice.selectPatientWorkflow);
  const [workflowStatuses, setWorkflowStatuses] = useState<IWorkflowStatusDao[]>(currentWorkflow?.data?.statuses ?? []);
  const { setNodeRef } = useDroppable({ id: 'workflow-statuses' });
  const changesMade = !_.isEqual(workflowStatuses, currentWorkflow?.data?.statuses);
  const [isSaving, setIsSaving] = useState(false);
  const { userData } = useUserState();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  useEffect(() => {
    if (!currentWorkflow || !currentWorkflow.data || currentWorkflow.status !== 'success') {
      navigate('/organisation-settings/workflow');
    }
  }, [currentWorkflow, message, navigate, t]);

  const saveChanges = async () => {
    try {
      setIsSaving(true);
      const userTimestamp = getActionTimestampFromUser(userData);
      await WorkflowApiService.update(currentWorkflow!.data!.uid, {
        statuses: workflowStatuses.map((status, index) => ({ ...status, ordinal: index + 1 })),
        updated: userTimestamp,
      });
      navigate('/organisation-settings/workflow');
      message.success(t('workflow.workflow_editor.update.success'));
    } catch (error) {
      setIsSaving(false);
      message.error(t('workflow.workflow_editor.update.error'));
      sentryCaptureException(error, 'Update workflow', userData);
    }
  };

  const headerActions = [
    {
      element: (
        <SharedButton
          labelKey='common.save'
          appearance='primary'
          onClick={saveChanges}
          disabled={!changesMade}
          loading={isSaving}
        />
      ),
      key: 'editWorkflow',
    },
  ];

  const saveStatus = (data: IAddEditStatusFormOutput) => {
    setWorkflowStatuses((prevState) => [
      ...prevState,
      { key: uuidv4(), name: data.name, canTransitionTo: [], ordinal: workflowStatuses.length + 1 },
    ]);
  };

  const updateStatus = (status: IWorkflowStatusDao) => {
    setWorkflowStatuses((prevState) => prevState.map((s) => (s.key === status.key ? status : s)));
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }
    setWorkflowStatuses((prevState) => {
      const activeIndex = prevState.findIndex((status) => status.key === active.id);
      const overIndex = prevState.findIndex((status) => status.key === over.id);
      return arrayMove(prevState, activeIndex, overIndex);
    });
  };

  return (
    <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} sensors={sensors}>
      <SharedPageHeader title={t('workflow.workflow_editor')} showBack actions={headerActions} />
      <SharedCard>
        <div className='p-4 border-b'>
          <SharedButton
            labelKey='workflow.workflow_editor.add_status'
            icon={<PlusCircleOutlined />}
            onClick={() => dialog?.openDialog(<AddEditWorkflowStatusDialog onFinish={saveStatus} />)}
          />
        </div>
        <div className='p-4' ref={setNodeRef}>
          {workflowStatuses.length === 0 && <p className='text-gray-400'>{t('workflow.no_statuses')}</p>}
          <SortableContext items={workflowStatuses.map((status) => status.key)}>
            {workflowStatuses.map((status) => (
              <WorkflowStatus
                key={status.key}
                currentState={status}
                allStatuses={workflowStatuses}
                updateStatus={updateStatus}
              />
            ))}
          </SortableContext>
        </div>
      </SharedCard>
    </DndContext>
  );
};

export default WorkflowEditor;
