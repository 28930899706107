import { App } from 'antd';
import { AuditLogService } from 'core/api';
import { IAuditLogDao, IPatientDao } from 'core/api/types';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { and, or, orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedAuditLogActionTemplate from 'shared/audit-log/audit-log-action-template';
import SharedAuditLogResource from 'shared/audit-log/audit-log-resource';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'audit_log.table.header.user',
    key: 'user',
    contentTemplateId: 'user',
  },
  {
    labelKey: 'audit_log.table.header.resource',
    key: 'resource',
    contentTemplateId: 'resource',
  },
  {
    labelKey: 'audit_log.table.header.action',
    key: 'action',
    contentTemplateId: 'action',
  },
  {
    labelKey: 'audit_log.table.header.associated_resources',
    key: 'associations',
    contentTemplateId: 'associations',
  },
  {
    labelKey: 'audit_log.table.header.timestamp',
    key: 'timestamp',
    contentTemplateId: 'timestamp',
  },
];

const PatientAuditLog = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const [loading, setLoading] = useState<boolean>(false);
  const [auditLogs, setAuditLogs] = useState<IAuditLogDao[]>([]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = AuditLogService.onCollectionSnapshot(
      (snap) => {
        setAuditLogs(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_audit_log.error'));
        sentryCaptureException(error, 'Patient file fetching audit log', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(where('patientUid', '==', patient.uid), where('resource.uid', '==', patient.uid))
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const userTemplate = (log: IAuditLogDao) => log.created.by.fullName;

  const resourceTemplate = (log: IAuditLogDao) => <SharedAuditLogResource {...log.resource} />;

  const associationsTemplate = (log: IAuditLogDao) => (
    <div>
      {!log.associations || log.associations.length === 0 ? (
        <p className='text-gray-300'>{t('common.na')}</p>
      ) : (
        log.associations.map((association) => <SharedAuditLogResource key={association.uid} {...association} />)
      )}
    </div>
  );

  const timestampTemplate = (log: IAuditLogDao) => dayjs(log.updated.at.toDate()).format('DD/MM/YYYY, HH:mm');

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: userTemplate,
      id: 'user',
    },
    {
      template: resourceTemplate,
      id: 'resource',
    },
    {
      template: (log: IAuditLogDao) => <SharedAuditLogActionTemplate {...log} />,
      id: 'action',
    },
    {
      template: associationsTemplate,
      id: 'associations',
    },
    {
      template: timestampTemplate,
      id: 'timestamp',
    },
  ];
  return (
    <SharedCard>
      <SharedTable
        loading={loading}
        rows={auditLogs.map((log) => ({ key: log.uid, data: log }))}
        columns={tableColumns}
        contentTemplates={contentTemplates}
      />
    </SharedCard>
  );
};

export default PatientAuditLog;
