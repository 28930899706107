import clsx from 'clsx';
import { IStockDao } from 'core/api/types/stock.interface';
import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { StockStatus } from 'core/constants/stock-status';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { QueryConstraint, where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditStockDialog from './add-edit-stock/add-edit-stock-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { StockApiService } from 'core/api';
import { useState } from 'react';
import { Checkbox, message } from 'antd';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedStockStatusTableTemplate from 'shared/stock-management/stock-status-table-template';

interface IStockManagementStockTable {
  additionalColumns?: ISharedTableColumn[];
  additionalContentTemplates?: ISharedTableCustomTemplate[];
  tableKey: string;
  additionalConstraints?: QueryConstraint[];
  title?: string;
}

const StockManagementStockTable = ({
  additionalColumns = [],
  additionalContentTemplates = [],
  tableKey,
  additionalConstraints = [],
  title,
}: IStockManagementStockTable) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const dialog = useDialog();
  const [selectedStock, setSelectedStock] = useState<string[]>([]);
  const [allocating, setAllocating] = useState(false);

  const selectTemplate = (stock: IStockDao) => {
    return (
      <Checkbox
        disabled={allocating}
        checked={selectedStock.includes(stock.uid)}
        onChange={() => {
          if (selectedStock.includes(stock.uid)) {
            setSelectedStock(selectedStock.filter((uid) => uid !== stock.uid));
          } else {
            setSelectedStock([...selectedStock, stock.uid]);
          }
        }}
      />
    );
  };

  const serialTemplate = (stock: IStockDao) => {
    return (
      <p className={clsx('text-sm', !stock.serialNumber && 'text-gray-400')}>
        {stock.serialNumber ?? t('common.not_provided')}
      </p>
    );
  };

  const statusTemplate = (stock: IStockDao) => {
    return <SharedStockStatusTableTemplate stock={stock} tableKey={tableKey} />;
  };

  const actionTemplate = (stock: IStockDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(<AddEditStockDialog selectedType={stock.type} stock={stock} tableKey={tableKey} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.STOCK_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => StockApiService.permDelete(stock.uid)}
                  tableRefreshKeys={[tableKey, 'stockManagementCounts']}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('stock_management.overview.delete_stock_dialog.title')}
                  textContent={t('stock_management.overview.delete_stock_dialog.content')}
                  successMessage={t('stock_management.overview.delete_stock_dialog.success')}
                  errorMessage={t('stock_management.overview.delete_stock_dialog.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const handleStockAllocation = async (patientUid: string, status: StockStatus) => {
    try {
      dialog?.closeDialog();
      setAllocating(true);
      const promises = selectedStock.map((stockUid) =>
        StockApiService.update(stockUid, {
          allocatedTo: patientUid,
          status,
          updated: getActionTimestampFromUser(userData),
        })
      );
      await Promise.all(promises);
      setSelectedStock([]);
      message.success(t('stock_management.overview.table.allocated.success'));
      setAllocating(false);
    } catch (error) {
      setAllocating(false);
      message.error(t('stock_management.overview.table.allocated.error'));
    }
  };

  return (
    <SharedCard
      title={title}
      extra={
        selectedStock.length > 0 && (
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.STOCK_UPDATE]]}
          >
            <div className={clsx('space-x-2', !title && 'flex justify-end w-full')}>
              <SharedButton
                labelKey='stock_management.overview.table.on_trial'
                onClick={() =>
                  dialog?.openDialog(
                    <PatientSearchDialog
                      onSelect={(result) => handleStockAllocation(result.objectID, StockStatus.ON_TRIAL)}
                    />
                  )
                }
                loading={allocating}
              />
              <SharedButton
                labelKey='stock_management.overview.table.sold'
                onClick={() =>
                  dialog?.openDialog(
                    <PatientSearchDialog
                      onSelect={(result) => handleStockAllocation(result.objectID, StockStatus.SOLD)}
                    />
                  )
                }
                loading={allocating}
              />
            </div>
          </SharedElementPermissionGuard>
        )
      }
    >
      {userData && (
        <SharedPaginatedTable
          collectionId={CollectionID.STOCK}
          queryConstraints={[
            where('organisationUid', '==', userData?.organisationUid),
            where('status', '!=', StockStatus.SOLD),
            ...additionalConstraints,
          ]}
          queryOrder={['updated.at', 'desc']}
          tableConfig={{
            headerBackgroundColor: title ? '#f8fafc' : 'white',
            columns: [
              {
                key: 'select',
                contentTemplateId: 'select',
                width: 28,
              },
              ...additionalColumns,
              {
                labelKey: 'stock_management.add_edit_stock.form.serial_number',
                key: 'serialNumber',
                contentTemplateId: 'serialNumber',
              },
              {
                labelKey: 'stock_management.add_edit_stock.form.status',
                key: 'status',
                contentTemplateId: 'status',
              },
              {
                labelKey: 'common.updated',
                key: 'lastUpdated',
                contentTemplateId: 'lastUpdated',
              },
              { key: 'action', contentTemplateId: 'actions', width: 96 },
            ],
            contentTemplates: [
              {
                template: selectTemplate,
                id: 'select',
              },
              ...additionalContentTemplates,
              {
                template: serialTemplate,
                id: 'serialNumber',
              },
              {
                template: statusTemplate,
                id: 'status',
              },
              {
                template: actionTemplate,
                id: 'actions',
              },
            ],
          }}
          errorMessageKey='stock_management.overview.table.error'
          tableKey={tableKey}
        />
      )}
    </SharedCard>
  );
};

export default StockManagementStockTable;
