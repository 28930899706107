import { Navigate, Route, Routes } from 'react-router-dom';
import AppointmentsCalendar from './appointments-calendar';
import AddEditAppointment from './add-edit-appointment';

const AppointmentsCalendarRouting = () => {
  return (
    <Routes>
      <Route path='' element={<AppointmentsCalendar />} />
      <Route path='create' element={<AddEditAppointment />} />
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default AppointmentsCalendarRouting;
