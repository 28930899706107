import { IAppointmentTypeDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'core/providers/dialog-provider';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { AppointmentTypesApiService } from 'core/api';
import { arrayRemove } from 'firebase/firestore';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import AddAppointmentTypeAssignableUserDialog from './add-appointment-type-assignable-user-dialog';

const AppointmentTypeAssignableUsers = (appointmentType: IAppointmentTypeDao) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { userData } = useUserState();

  return (
    <SharedCard
      title={t('appointment_types.appointment_type_detail.assignable_users')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='appointment_types.appointment_type_detail.assignable_users.add_user'
            onClick={() => dialog?.openDialog(<AddAppointmentTypeAssignableUserDialog {...appointmentType} />)}
          />
        </SharedElementPermissionGuard>
      }
    >
      {appointmentType.assignableUsers.map((u) => (
        <div key={u.uid} className='px-4 py-3 border-b last:border-0 flex items-center justify-between'>
          <div>
            <p className='body-sm'>{u.fullName}</p>
            <p className='body-xs text-gray-400'>{u.emailAddress}</p>
          </div>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.APPOINTMENT_TYPES_UPDATE], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              onClick={() =>
                dialog?.openDialog(
                  <ConfirmActionDialog
                    action={() =>
                      AppointmentTypesApiService.update(appointmentType.uid, {
                        assignableUsers: arrayRemove(u),
                        updated: getActionTimestampFromUser(userData),
                      })
                    }
                    actionButtonProps={{
                      labelKey: 'common.remove',
                      danger: true,
                    }}
                    title={t('appointment_types.appointment_type_detail.assignable_users.remove_user.title', {
                      name: u.fullName,
                    })}
                    textContent={t('appointment_types.appointment_type_detail.assignable_users.remove_user.content')}
                    successMessage={t('appointment_types.appointment_type_detail.assignable_users.remove_user.success')}
                    errorMessage={t('appointment_types.appointment_type_detail.assignable_users.error')}
                  />
                )
              }
              appearance='link'
              labelKey={'common.remove'}
              danger
            />
          </SharedElementPermissionGuard>
        </div>
      ))}
      {appointmentType?.assignableUsers.length === 0 && (
        <p className='text-gray-400 p-4'>{t('appointment_types.appointment_type_detail.assignable_users.no_users')}</p>
      )}
    </SharedCard>
  );
};

export default AppointmentTypeAssignableUsers;
