import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import SharedButton from 'shared/button/button';
import { IUserDao } from 'core/api/types';
import { App, Input } from 'antd';
import { Unsubscribe } from 'firebase/auth';
import { UsersApiService } from 'core/api';
import SharedSpinner from 'shared/spinner/spinner';
import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';

interface IGeoJSON {
  type: string;
  features: {
    type: string;
    id: string;
    geometry: {
      type: string;
      coordinates: number[][][][];
    };
    properties: {
      name: string;
      color: string;
      fillColor: string;
      kind: string;
      isHidden: string;
      weight: string;
      opacity: string;
      dashArray: string;
      fillOpacity: string;
    };
  }[];
}

const UsersWorkArea = () => {
  const { userData } = useUserState();
  const { uid } = useParams();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const navigate = useNavigate();

  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState<IUserDao>();

  const [userGeofences, setUserGeofences] = useState<google.maps.LatLngLiteral[][]>([]);
  const [newGeofences, setNewGeofences] = useState<google.maps.LatLngLiteral[][]>([]);
  const geofenceStringSeparator = '##';

  const [geoJsonString, setGeoJsonString] = useState<string>('');
  const [showGeoJsonInput, setShowGeoJsonInput] = useState(false);

  const handleError = useCallback(() => {
    message.error(t('appointment_types.appointment_type_detail.error'));
  }, [message, t]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (!uid) {
      navigate('/users');
    } else {
      unsubscribe = UsersApiService.onDocSnapshot(
        uid,
        (snap) => {
          if (snap.exists()) {
            setUser(snap.data());
          } else {
            handleError();
          }
        },
        () => handleError()
      );
    }
    return () => {
      unsubscribe();
    };
  }, [handleError, navigate, uid]);

  useEffect(() => {
    const areas = user?.workingAreas
      ? user.workingAreas.map((areaString) =>
          areaString.split(geofenceStringSeparator).map((point) => JSON.parse(point) as google.maps.LatLngLiteral)
        )
      : [];
    setUserGeofences(areas ?? []);
  }, [navigate, user]);

  useEffect(() => {
    if (!editing && newGeofences.length) {
      setNewGeofences([]);
      setShowGeoJsonInput(false);
    }
  }, [editing, newGeofences]);

  useEffect(() => {
    if (!showGeoJsonInput) {
      setGeoJsonString('');
    }
  }, [showGeoJsonInput]);

  const handlePolygonCompletion = (polygon: google.maps.Polygon) => {
    const points: google.maps.LatLngLiteral[] = [];
    const path = polygon.getPath();
    path.forEach((point) => {
      points.push({
        lat: point.lat(),
        lng: point.lng(),
      });
    });
    setNewGeofences((prevState) => [...prevState, points]);
    polygon.setMap(null);
  };

  const saveChanges = async (user: IUserDao) => {
    try {
      const workingAreas = newGeofences.map((points) =>
        points.map((p) => JSON.stringify(p)).join(geofenceStringSeparator)
      );
      await UsersApiService.update(user.uid, { workingAreas, updated: getActionTimestampFromUser(userData) });
      setEditing(false);
      message.success(t('users.manage_work_area.success'));
    } catch (error) {
      message.error(t('users.manage_work_area.error'));
    }
  };

  const saveGeoJson = () => {
    try {
      const geoJSON: IGeoJSON = JSON.parse(geoJsonString);
      const multiPoly = geoJSON.features[0].geometry.coordinates;
      const points = multiPoly
        .flatMap((p) => p)
        .reduce((accumulator: number[][], current) => {
          return accumulator.length >= current.length ? accumulator : current;
        }, [])
        .map((c) => ({
          lat: c[1],
          lng: c[0],
        }));
      setNewGeofences((prevState) => [...prevState, points]);
      setShowGeoJsonInput(false);
    } catch (error) {}
  };

  const headerActions = [
    {
      element: (
        <SharedButton
          labelKey={editing ? 'users.manage_work_area.save' : 'users.manage_work_area.edit'}
          onClick={() => {
            editing && user ? saveChanges(user) : setEditing(true);
          }}
          appearance='primary'
        />
      ),
      key: 'addUserWorkArea',
    },
  ];

  return (
    <>
      {!user ? (
        <div className='py-8 flex justify-center items-center h-[306px]'>
          <SharedSpinner />
        </div>
      ) : (
        <>
          <SharedPageHeader title={t('users.manage_work_area')} actions={headerActions} showBack />
          <SharedCard outerClassName='grow flex flex-col' innerClassName='grow'>
            {editing && (
              <div className='p-4 my-4'>
                {showGeoJsonInput ? (
                  <>
                    <Input.TextArea
                      rows={4}
                      value={geoJsonString}
                      onChange={(e) => setGeoJsonString(e.target.value)}
                      disabled={!showGeoJsonInput}
                    />
                    <div className='mt-2 space-x-2'>
                      <SharedButton
                        onClick={() => {
                          setShowGeoJsonInput(false);
                        }}
                        type='button'
                        appearance='default'
                        labelKey='users.manage_work_area.cancel'
                      />
                      <SharedButton
                        onClick={saveGeoJson}
                        type='button'
                        appearance='primary'
                        labelKey='users.manage_work_area.add'
                      />
                    </div>
                  </>
                ) : (
                  <SharedButton
                    onClick={() => {
                      setShowGeoJsonInput(true);
                    }}
                    type='button'
                    appearance='default'
                    labelKey='users.manage_work_area.add_geojson'
                  />
                )}
              </div>
            )}
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
              }}
              zoom={8}
              center={{
                lat: 52.7406951,
                lng: -0.8073038,
              }}
            >
              {editing && (
                <DrawingManager
                  onPolygonComplete={handlePolygonCompletion}
                  drawingMode={google.maps.drawing.OverlayType.POLYGON}
                  options={{ drawingControl: false }}
                />
              )}
              {(!editing ? userGeofences : newGeofences).map((fence, index) => (
                <Polygon key={`poly-${index}`} draggable={false} editable={false} path={fence} />
              ))}
            </GoogleMap>
          </SharedCard>
        </>
      )}
    </>
  );
};

export default UsersWorkArea;
